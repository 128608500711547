import ButtonGroupDynamic from "../common/ButtonGroupDynamic";
// import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import { getSurveyStatusActionButtons } from "./actionButtons";
// import { getStatusActionButtons } from "../../bannerActionButtons";
import { getDateWithFormat } from "../../utils/commons";

const headerStyle = {
  textAlign: "left",
  padding: "8px 8px",
  background: "rgba(251, 252, 254, 1)",
  boxShadow: "0px -2px 0px 0px rgba(215, 229, 248, 1) inset",
  marginBottom: "8px",
  fontSize: "10px",
};

const bodyStyle = {
  boxShadow: "0px 0px 24px 0px rgba(0, 92, 188, 0.08)",
  background: "rgba(251, 252, 254, 1)",
};

const cellStyle = {
  background: "rgba(251, 252, 254, 1)",
  boxShadow: "0px -2px 0px 0px rgba(215, 229, 248, 1) inset",
  padding: "8px 8px",
  fontSize: "12px",
};

const columnStyles = {
  status: { width: "10%" },
  cardType: { width: "10%" },
  position: { width: "5%" },
  title: { width: "25%" },
  shortTitleValue: { width: "6%" },
  activity: { width: "10%" },
  startDate: { width: "16%" },
  endDate: { width: "16%" },
  actions: { width: "10%" },
};

const SurveyListView = ({
  surveyList,
  handlActionButtons,
  currentProgramTimezone,
  handleOpenConfirmationModal,
}) => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const [rowsPerPage, setRowsPerPage] = useState(12);
  // const [numPages, setNumPages] = useState(0);

  // useEffect(() => {
  //   setNumPages(Math.ceil(surveyList?.length / rowsPerPage));
  // }, [surveyList?.length, rowsPerPage]);

  // const handlePageChange = (newPageIndex) => {
  //   if (newPageIndex > 0 && newPageIndex <= numPages) {
  //     setCurrentPage(newPageIndex);
  //   }
  // };

  // const currentData = surveyList.slice(
  //   (currentPage - 1) * rowsPerPage,
  //   Math.min(currentPage * rowsPerPage, surveyList.length)
  // );

  return (
    <div style={{ width: "100%" }}>
      <table
        style={{
          width: "100%",
          borderSpacing: "0",
        }}
      >
        <thead>
          <tr>
            <th style={{ ...headerStyle, ...columnStyles.status }}>STATUS</th>
            <th style={{ ...headerStyle, ...columnStyles.title }}>NAME</th>
            <th style={{ ...headerStyle, ...columnStyles.shortTitleValue }}>
              SEGMENT
            </th>
            <th style={{ ...headerStyle, ...columnStyles.position }}>POSITION</th>
            <th style={{ ...headerStyle, ...columnStyles.cardType }}>TYPE</th>
            <th style={{ ...headerStyle, ...columnStyles.startDate }}>START DATE</th>
            <th style={{ ...headerStyle, ...columnStyles.endDate }}>END DATE</th>
            <th style={{ ...headerStyle, ...columnStyles.actions }}>Actions</th>
          </tr>
        </thead>
        <tbody style={bodyStyle}>
          {surveyList &&
            surveyList?.map((survey, index) => {
              const hasValidSegments = survey?.segments?.filter(Boolean).length > 0;
              return (
                <tr key={index}>
                  <td style={{ ...cellStyle, ...columnStyles.status }}>
                    <div
                      className={`custom_chip ${survey?.status?.toLowerCase()}`}
                      style={{
                        padding: "4px 8px",
                        color: "white",
                        borderRadius: "16px",
                        textAlign: "center",
                        maxWidth: "100px",
                        fontSize: "12px",
                      }}
                    >
                      {survey?.status.charAt(0).toUpperCase() +
                        survey?.status.slice(1).toLowerCase()}
                    </div>
                  </td>
                  <td style={{ ...cellStyle, ...columnStyles.title }}>
                    {survey.title}
                  </td>
                  <td style={{ ...cellStyle, ...columnStyles.shortTitleValue }}>
                    {hasValidSegments ? "Yes" : "No"}
                  </td>
                  <td style={{ ...cellStyle, ...columnStyles.position }}>
                    {survey.contentData?.position}
                  </td>
                  <td style={{ ...cellStyle, ...columnStyles.cardType }}>
                    {survey.contentData?.type}
                  </td>
                  <td style={{ ...cellStyle, ...columnStyles.startDate }}>
                    {getDateWithFormat(
                      survey?.activeStartDate,
                      currentProgramTimezone
                    )}
                  </td>
                  <td style={{ ...cellStyle, ...columnStyles.endDate }}>
                    {getDateWithFormat(
                      survey?.activeEndDate,
                      currentProgramTimezone
                    )}
                  </td>
                  <td style={{ ...cellStyle, ...columnStyles.actions }}>
                    <ButtonGroupDynamic
                      buttons={getSurveyStatusActionButtons(
                        survey,
                        handlActionButtons,
                        handleOpenConfirmationModal
                      )}
                      spacing="0px"
                      size="small"
                      variant="control"
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {/* <div
        style={{
          padding: "16px 0px 16px 0px",
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <Pagination
          currentPage={currentPage}
          onUpdate={handlePageChange}
          pageCount={numPages}
          size="medium"
          variant="number"
        />
      </div> */}

      {/* If we want to add rows per page */}
      {/* <div>
        <label>
          Rows per page:
          <select value={rowsPerPage} onChange={handleChangeRowsPerPage}>
            {[5, 10, 15, 20].map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </label>
      </div> */}
    </div>
  );
};

export default SurveyListView;
