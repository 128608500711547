import { composePaths, createDefaultValue } from "@jsonforms/core";
import {
  JsonFormsDispatch,
  withJsonFormsArrayControlProps,
} from "@jsonforms/react";
import { ButtonGroup } from "pepsico-ds";
import { useCallback } from "react";
import "./array-of-fields.scss";

const ArrayOfFields = (props) => {
  const {
    data,
    addItem,
    removeItems,
    path,
    schema,
    uischema,
    renderers,
    label,
  } = props;
  const childUiSchema = uischema.options?.detail;
  const addItemCallback = useCallback(
    (path, value) => addItem(path, value),
    [addItem]
  );
  const removeItemsCallback = useCallback(
    (path, toDelete) => removeItems(path, toDelete),
    [removeItems]
  );

  return (
    <div className="array-of-fields-wrapper">
      {data?.map((_, index) => {
        const childPath = composePaths(path, `${index}`);
        return (
          <div key={childPath} className="array-of-fields-item">
            <JsonFormsDispatch
              path={childPath}
              renderers={renderers}
              schema={schema}
              uischema={childUiSchema}
            />
            {index > 0 && (
              <ButtonGroup
                className="delete-item-button"
                size="small"
                items={[
                  {
                    icon: "delete",
                    onClick: removeItemsCallback(path, [index]),
                  },
                ]}
              />
            )}
          </div>
        );
      })}
      <div className="array-of-fields-item add-section">
        <ButtonGroup
          size="small"
          items={[
            {
              icon: "add",
              onClick: addItemCallback(path, createDefaultValue(schema)),
            },
          ]}
        />
        <span className="add-item-label">{label}</span>
      </div>
    </div>
  );
};

export default withJsonFormsArrayControlProps(ArrayOfFields);
