import { ButtonGroup, InputItem } from "pepsico-ds";
import { useEffect, useState } from "react";
import PartnerCard from "./PartnerCard/PartnerCard";
import PartnerListTable from "./PartnerListViewTable/PartnerListTable";
import "./partners.scss";

// Need to delete this data and replace with actual data
const partnerData = [
  {
    id: "20eb798c-2869-49ed-a11c-c67923564982",
    name: "Partner",
    title: "Partner History",
    description: "Partner History 2",
    isActive: false,
    createdAt: "2024-06-26T12:35:01.054415534Z",
  },
  {
    id: "20eb798c-2869-49ed-a11c-c67923564982",
    name: "Partner",
    title: "Member History",
    description: "Member History 2",
    isActive: true,
    createdAt: "2024-06-26T12:35:01.054415534Z",
  },
  {
    id: "20eb798c-2869-49ed-a11c-c67923564982",
    name: "Partner",
    title: "Partner History",
    description: "Partner History 2",
    isActive: false,
    createdAt: "2024-06-26T12:35:01.054415534Z",
  },
  {
    id: "20eb798c-2869-49ed-a11c-c67923564982",
    name: "Partner",
    title: "Member History",
    description: "Member History 2",
    isActive: true,
    createdAt: "2024-06-26T12:35:01.054415534Z",
  },

  {
    id: "20eb798c-2869-49ed-a11c-c67923564982",
    name: "Partner",
    title: "Partner History",
    description: "Partner History 2",
    isActive: false,
    createdAt: "2024-06-26T12:35:01.054415534Z",
  },
  {
    id: "20eb798c-2869-49ed-a11c-c67923564982",
    name: "Partner",
    title: "Member History",
    description: "Member History 2",
    isActive: true,
    createdAt: "2024-06-26T12:35:01.054415534Z",
  },
];

const PartnersTab = () => {
  const [viewType, setViewType] = useState("grid");
  const [mainContentY, setMainContentY] = useState(0);

  const handleResize = () => {
    setMainContentY(
      Math.ceil(
        document.getElementsByClassName("main-content")[0].getBoundingClientRect()
          .top
      )
    );
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="page-content">
      <div className="view-filter">
        <div className="search-filter-container">
          <InputItem
            placeholder="Search by name"
            trailingIcon="search"
            size="medium"
            className="input-search"
          />
          {/* <Button
            variant="secondary"
            iconTrailingVariant="outlined"
            iconTrailing="tune"
            size="medium"
            text="Filter"
          /> */}
        </div>
        <ButtonGroup
          items={[
            {
              icon: "view_module",
              onClick: () => {
                setViewType("grid");
              },
            },
            {
              icon: "view_list",
              onClick: () => {
                setViewType("list");
              },
            },
          ]}
          variant="toggle"
          selectedIndex={viewType === "grid" ? 0 : 1}
        />
      </div>
      <div
        className="main-content"
        style={{
          flex: 20,
          overflowY: "auto",
          maxHeight: `calc(100vh - ${mainContentY}px)`,
        }}
      >
        {viewType === "grid" ? (
          <div className="grid-container" style={{ flex: 20, overflowY: "auto" }}>
            {partnerData.map((partner, index) => (
              <div
                className="grid-item grid-item-3 grid-item-4"
                // style={{ flex: 1 }}
                key={index}
              >
                <PartnerCard key={index} {...partner} />
              </div>
            ))}
          </div>
        ) : (
          <PartnerListTable partnerData={partnerData} />
        )}
      </div>
    </div>
  );
};

export default PartnersTab;
