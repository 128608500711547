import { gql } from "@apollo/client";

export const listRewardGroups = gql`
  query ListRewardGroups($rewardGroupId: ID) {
    listRewardGroups(
        filter: { rewardGroupId: $rewardGroupId }
    ) {
        id
        campaignId
        type
        name
        image
        numberOfCodeScansRequiredToWin
        position
        description
        maxParticipationPerInterval
        intervalPositionCode
        amount
        numberOfWinnersLimit
        completed
        rewardGroupRewards {
            id
            rewardId
            rewardGroupId
            position
            quantity
            chanceToWinPercentage
            walletWithdrawalDate
            totalUserWinLimit
            startDate
            endDate
            isParent
            parentRewardGroupRewardId
        }
    }
}

`;
