import { Dropdown } from "pepsico-ds";
import "./styles.scss";

export default function SelectField(props) {
  const { value, updateValue, label, schema, uiSchema, required } = props;

  return (
    <>
      <Dropdown
        isRequired={required}
        childList={schema.options}
        dropdownHeader={schema.header}
        style={{ minWidth: "0px" }}
        className="zIndexToDropDown"
        label={label}
        selection={uiSchema.selection || "single"}
        selectedValue={value}
        setSelectedValue={updateValue}
        size={uiSchema.size || "medium"}
        placeholder={uiSchema.placeholder || ""}
      />
    </>
  );
}
