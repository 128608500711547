import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import AddRewards from "./AddRewards";

const listRewardsSchema = {
  jsonSchema: {
    type: "object",
    properties: {
      name: {
        type: "string",
      },
      position: {
        type: "integer",
      },
      maxParticipationPerInterval: {
        type: "integer",
      },
      description: {
        type: "string",
      },
    },
    required: [
      "name",
      "maxParticipationPerInterval",
      "numberOfCodeScansRequiredToWin",
      "position",
      "description",
    ],
  },
  uiSchema: {
    type: "VerticalLayout",
    elements: [
      {
        type: "HorizontalLayout",
        elements: [
          {
            type: "Control",
            scope: "#/properties/name",
            component: "textField",
            label: "Reward Group Name",
          },
          {
            type: "Control",
            scope: "#/properties/position",
            component: "textField",
            label: "Position on gift selection page",
          },
          {
            type: "Control",
            scope: "#/properties/maxParticipationPerInterval",
            component: "textField",
            label: "Max participation",
          },
        ],
      },
      {
        type: "Control",
        scope: "#/properties/description",
        component: "textarea",
        label: "Reward group description",
      },
    ],
  },
};

const chanceRewardsSchema = {
  jsonSchema: {
    type: "object",
    properties: {
      image: {
        type: "string",
      },
      name: {
        type: "string",
      },
      position: {
        type: "integer",
      },
      numberOfWinnersLimit: {
        type: "integer",
      },
      description: {
        type: "string",
      },
    },
    required: ["name", "numberOfWinnersLimit", "description", "position", "image"],
  },
  uiSchema: {
    type: "HorizontalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/image",
        component: "singleImageUpload",
        label: "Reward group image",
        accept: ["image/png", "image/jpg", "image/jpeg"],
      },
      {
        type: "VerticalLayout",
        elements: [
          {
            type: "HorizontalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/name",
                component: "textField",
                label: "Reward Group Name",
              },
              {
                type: "Control",
                scope: "#/properties/position",
                component: "textField",
                label: "Position on gift selection page",
              },
              {
                type: "Control",
                scope: "#/properties/numberOfWinnersLimit",
                component: "textField",
                label: "Max winners",
              },
            ],
          },
          {
            type: "Control",
            scope: "#/properties/description",
            component: "textarea",
            label: "Reward group description",
          },
        ],
      },
    ],
  },
};

export const RewardGroupCampaignSetup = ({ value, updateValue, otherData }) => {
  return (
    <>
      <div className="mt-3 w-100">
        <JsonFormsWrapper
          jsonschema={
            otherData?.type?.slug === "list-rewards"
              ? listRewardsSchema.jsonSchema
              : chanceRewardsSchema.jsonSchema
          }
          uischema={
            otherData?.type?.slug === "list-rewards"
              ? listRewardsSchema.uiSchema
              : chanceRewardsSchema.uiSchema
          }
          data={value}
          onChange={updateValue}
        />
      </div>
      <AddRewards
        value={value?.rewardGroupRewards || []}
        updateValue={(val) => {
          updateValue({ ...value, rewardGroupRewards: val });
        }}
        type={otherData?.type?.slug}
        edit={otherData.edit}
      />
    </>
  );
};
