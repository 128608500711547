import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { useMemo, useState } from "react";
// import { listKeywordGroups } from "../../../graphql/queries/listKeywordGroups";
import { gql, useQuery } from "@apollo/client";

const MultipleIdSelctor = (props) => {
  const { handleChange, data, label, path, required, visible, enabled, schema } =
    props;

  const { data: listOptions, loading } = useQuery(gql`
    query ListRewardCategories {
      listRewardCategories {
        id
        name
        description
      }
    }
  `);

  const formattedOptions = useMemo(() => {
    if (!loading && schema.queryObject && listOptions[schema.queryObject]) {
      return listOptions[schema.queryObject].map((child) => ({
        id: child?.id,
        displayText: child.name || "",
        description: child.description || "",
      }));
    }
    return [];
  }, [loading, listOptions]);

  const [selectedValues, setSelectedValues] = useState(
    data?.length
      ? data.filter((selectedValueId) =>
          formattedOptions.find((option) => selectedValueId === option?.id)
        )
      : []
  );

  const formatData = (listOptions) =>
    listOptions ? listOptions.map((item) => item.id) : [];

  const handleChangeValues = (newValues) => {
    setSelectedValues(newValues);
    handleChange(path, formatData(newValues));
  };

  return visible ? (
    <div className="multiple-select-wrap">
      <Dropdown
        label={label}
        childList={formattedOptions}
        selection="multiple"
        isRequired={required}
        disabled={!enabled}
        selectedValue={selectedValues}
        setSelectedValue={handleChangeValues}
      />
    </div>
  ) : null;
};

export default withJsonFormsMultiEnumProps(MultipleIdSelctor);
