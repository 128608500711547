import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
} from "@mui/material";
import { Button } from "pepsico-ds";
import PropTypes from "prop-types";
import { useState } from "react";
import JsonFormsWrapper from "../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import "./createPartnerCodeModal.scss";

const uiSchema = {
  type: "HorizontalLayout",
  elements: [
    {
      component: "multiDocUpload",
      options: {
        variant: "edit",
      },
      scope: "#/properties/importcode",
      type: "Control",
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "HorizontalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/activeDates/properties/startDate",
              component: "dateTimePicker",
            },
            {
              type: "Control",
              scope: "#/properties/activeDates/properties/endDate",
              component: "dateTimePicker",
            },
          ],
        },
        {
          type: "Control",
          scope: "#/properties/name",
          component: "text",
        },
        {
          type: "Control",
          scope: "#/properties/description",
          component: "textarea",
        },
      ],
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/partner",
          component: "muiSelect",
        },
        {
          type: "Control",
          scope: "#/properties/codeWarningLimit",
          component: "slider",
        },
      ],
    },
  ],
};
const jsonSchema = {
  type: "object",
  properties: {
    importCode: {
      type: "array",
      items: {
        type: "string",
        format: "uri",
      },
      title: "Import Code",
    },
    activeDates: {
      type: "object",
      properties: {
        startDate: {
          type: "string",
          format: "date",
          title: "Start Date",
        },
        endDate: {
          type: "string",
          format: "date",
          title: "End Date",
        },
      },
    },
    name: {
      type: "string",
      title: "Name",
    },
    description: {
      type: "string",
      title: "Description",
    },
    partner: {
      type: "string",
      label: "Partner",
      enum: ["Partner 1", "Partner 2", "Partner 3"],
    },
    codeWarningLimit: {
      type: "number",
      title: "Code Warning Limit",
    },
  },
  required: ["importCode", "activeDates", "name", "description", "partner"],
};
function CreatePartnerCodeModal({
  createPartnerCodeModalOpen,
  toggleCreatePartnerCodeModal,
}) {
  const [formData, setFormData] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleChange = (data) => {
    setFormData(data);
  };
  const handleCreate = () => {
    // Add validation logic if needed
    const isValid = true; // Replace with actual validation logic
    if (!isValid) {
      setSnackbarMessage("Please fill in all required fields.");
      setSnackbarOpen(true);
      return;
    }
    // Handle form submission logic
    // ...
    toggleCreatePartnerCodeModal();
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return (
    <>
      <Dialog
        className="partner-code-group-modal"
        open={createPartnerCodeModalOpen}
        onClose={toggleCreatePartnerCodeModal}
        scroll="paper"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" direction="row">
            <Grid item xs={10}>
              <h2 style={{ marginLeft: "18%", textAlign: "center" }}>
                Create Partner Code Group
              </h2>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent="flex-end">
              <CloseIcon onClick={toggleCreatePartnerCodeModal} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ overflowX: "hidden" }}>
          <div className="code-group-mod">
            <JsonFormsWrapper
              jsonschema={jsonSchema}
              uischema={uiSchema}
              data={formData}
              onChange={handleChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreate}>Create</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

CreatePartnerCodeModal.propTypes = {
  createPartnerCodeModalOpen: PropTypes.bool.isRequired,
  toggleCreatePartnerCodeModal: PropTypes.func.isRequired,
};

export default CreatePartnerCodeModal;
