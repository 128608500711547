import JsonFormsWrapper from "../jsonFormsWrapper/JsonFormsWrapper";

export default function MultiplePropertiesField(props) {
  const { value, updateValue, schema, uiSchema } = props;

  return Object.keys(schema?.properties).map((key) => (
    <>
      <JsonFormsWrapper
        jsonschema={{
          type: "object",
          properties: { [key]: schema.properties[key] },
          required: schema?.required?.indexOf(key) > -1 ? [key] : [],
        }}
        uischema={{
          ...uiSchema,
          scope: `#/properties/${key}`,
          type: "Control",
          component: schema.properties[key]?.properties ? "object" : "",
          isChild: schema.properties[key]?.properties ? true : false,
        }}
        data={value}
        onChange={updateValue}
      />
    </>
  ));
}
