import PropTypes from 'prop-types';
import { createContext, useState } from 'react';

// ==============================|| LOADER CONTEXT & PROVIDER ||============================== //

const LoaderContext = createContext({
    isLoading: false,
    refetchNotifications: false,
    setIsLoading: () => null,
    setRefetchNotifications: () => null
});

export const LoaderProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [refetchNotifications, setRefetchNotifications] = useState(false);

    return (
        <LoaderContext.Provider value={{ isLoading, setIsLoading, refetchNotifications, setRefetchNotifications }}>
            {children}
        </LoaderContext.Provider>
    );
};

LoaderProvider.propTypes = {
    children: PropTypes.node
};

export default LoaderContext;
