import { gql } from "@apollo/client";

export const getCodeGroups = gql`
  query ListCodeGroups {
    listCodeGroups {
      codeGroupId
      codesGeneratedCount
      administrationStatus
      basicSetup {
        codeActiveStartDate
        codeActiveEndDate
        codeGroupName
      }
      producerPackaging {
        codesPrintedCount
      }
    }
  }
`;
