import { Card } from "pepsico-ds";
import PropTypes from "prop-types";
import React from "react";
import { getDateWithFormat } from "../../utils/commons";
import ButtonGroupDynamic from "../common/ButtonGroupDynamic";
import { codeGroupActionButton } from "./codeGroupActionButton";
import "./codeGroupsCard.scss";

const CodeGroupsCard = ({ group, currentProgramTimezone }) => {
  console.log("view 2", group);
  if (!group) {
    return "No Data Found"; // Or render a placeholder if needed
  }

  // Destructure with optional chaining and default values
  const basicSetup = group.basicSetup || {};
  const producerPackaging = group.producerPackaging || {};
  const codesGeneratedCount =
    group.codesGeneratedCount !== undefined && group.codesGeneratedCount !== null
      ? group.codesGeneratedCount
      : "-";
  const administrationStatus = group.administrationStatus || "-";
  const lifecycleStatus = group.lifecycleStatus || "-";

  // Extract and default values
  const codeGroupName = basicSetup.codeGroupName || "-";
  const codeActiveStartDate = basicSetup.codeActiveStartDate || null;
  const codeActiveEndDate = basicSetup.codeActiveEndDate || null;
  const producerCompany = producerPackaging.producerCompany || "-";
  const codesPrintedCount =
    producerPackaging.codesPrintedCount !== undefined &&
    producerPackaging.codesPrintedCount !== null
      ? producerPackaging.codesPrintedCount
      : "-";

  return (
    <Card
      icon=""
      ellipsis=""
      style={{
        padding: "0px",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
      }}
    >
      <div className="code-group-card-container">
        <div className="code-group-details">
          {/* Status Labels */}
          <div className="code-group-status-type">
            <span className={`status ${administrationStatus.toLowerCase()}`}>
              {administrationStatus}
            </span>
            <span
              className={`code-upload-status ${lifecycleStatus
                .toLowerCase()
                .replace(/\s/g, "-")}`}
            >
              {lifecycleStatus}
            </span>
          </div>
          {/* Code Group Name */}
          <h3 className="code-group-name">{codeGroupName}</h3>
          {/* Additional Fields */}
          <div className="additional-fields-container">
            <div className="field-pair">
              <span className="field-label">Code Count</span>
              <span className="field-value">{codesGeneratedCount}</span>
            </div>
            <div className="field-pair">
              <span className="field-label">Producer Company</span>
              <span className="field-value">{producerCompany}</span>
            </div>
            <div className="field-pair">
              <span className="field-label">Used Code Count</span>
              <span className="field-value">{codesPrintedCount}</span>
            </div>
          </div>
          {/* Dates */}
          <div className="code-group-dates">
            <div className="date-item">
              <span className="date-label">Active Date</span>
              <span>
                {codeActiveStartDate
                  ? getDateWithFormat(codeActiveStartDate, currentProgramTimezone)
                  : "-"}
              </span>
            </div>
            <div className="date-item">
              <span className="date-label">Inactive Date</span>
              <span>
                {codeActiveEndDate
                  ? getDateWithFormat(codeActiveEndDate, currentProgramTimezone)
                  : "-"}
              </span>
            </div>
          </div>
          {/* Action Buttons */}
          <div className="button-group-container">
            <ButtonGroupDynamic
              buttons={codeGroupActionButton(administrationStatus)}
              spacing="1px"
              size="medium"
              variant="control"
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

CodeGroupsCard.propTypes = {
  group: PropTypes.shape({
    basicSetup: PropTypes.shape({
      codeGroupName: PropTypes.string,
      codeActiveStartDate: PropTypes.string,
      codeActiveEndDate: PropTypes.string,
    }),
    producerPackaging: PropTypes.shape({
      producerCompany: PropTypes.string,
      codesPrintedCount: PropTypes.number,
    }),
    codesGeneratedCount: PropTypes.number,
    administrationStatus: PropTypes.string,
    lifecycleStatus: PropTypes.string,
  }),
  currentProgramTimezone: PropTypes.string,
};

export default CodeGroupsCard;
