import { Card, Tooltip } from "pepsico-ds";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import { partnersActionButtons } from "../partnersActionButton";
import "./PartnerCard.scss";

const PartnerCard = ({ name, isActive, description, title }) => {
  return (
    <Card
      className="partner-card-container"
      icon=""
      ellipsis=""
      style={{
        padding: "0px",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        backgroundColor: "#fff",
      }}
    >
      <div className="partner-card-container">
        <div className="partner-details">
          <div className="partner-status-type">
            <span className={`status ${isActive ? "active" : "inactive"}`}>
              {isActive ? "Active" : "Inactive"}
            </span>
          </div>
          {title.length > 60 ? (
            <Tooltip direction="right" text={title}>
              <h3 className="partner-name">{title.substring(0, 60)}...</h3>
            </Tooltip>
          ) : (
            <h3 className="partner-name">{title}</h3>
          )}
          <div className="partner-meta">
            {name && (
              <div className="meta-item">
                <span className="meta-label">Name</span>
                <span>{name}</span>
              </div>
            )}
          </div>
          <div className="partner-meta">
            {description && (
              <div className="meta-item">
                <span className="meta-label">Description</span>
                <span>{description}</span>
              </div>
            )}
          </div>

          <div className="button-group-container">
            <ButtonGroupDynamic
              buttons={partnersActionButtons(isActive)}
              spacing="1px"
              size="small"
              variant="control"
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default PartnerCard;
