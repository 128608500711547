import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Icon, Tooltip } from "pepsico-ds";
import "./radio-input.scss";

const RadioInputField = (props) => {
  const { path, updateValue, label, schema, uiSchema, required, disabled } = props;

  // Access label mappings from uiSchema
  const labelMapping = uiSchema.options.labelMapping || [];

  const formLabelStyles = {
    display: "flex",
    fontSize: "0.75rem",
    fontFamily: "Inter",
    color: "var(--textSecondary)",
    "&.Mui-focused": {
      color: "var(--textSecondary)",
    },
  };

  // @todo: Implement errors handling
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend" sx={formLabelStyles}>
        {label}
        {required ? "*" : ""}
        {uiSchema.helpText && (
          <div className="radio-help-text">
            <Tooltip text={uiSchema.helpText} className="help-tooltip">
              <Icon icon="info" size="small" />
            </Tooltip>
          </div>
        )}
      </FormLabel>
      <RadioGroup className="$spacing-ml04" row>
        {schema?.enum?.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option}
            name={path}
            control={<Radio size="small" />}
            checked={option === props.value}
            disabled={disabled}
            required={required}
            onChange={(event) =>
              updateValue(event.target.value, labelMapping[option])
            }
            label={
              <span className="$font-small-regular $color-text-secondary $spacing-mr03">
                {labelMapping[option] || option}
              </span>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioInputField;
