import { withJsonFormsControlProps } from "@jsonforms/react";
import { InputItem } from "pepsico-ds";

const SearchInput = (props) => {
  const { handleChange, uischema, path, data } = props;
  const { placeholder, size } = uischema;
  console.log(data);
  return (
    <InputItem
      placeholder={placeholder || ""}
      trailingIcon="search"
      inputSize={size || "medium"}
      value={data || ""}
      className="input-search"
      onChange={(e) => handleChange(path, e.target.value)}
    />
  );
};

export default withJsonFormsControlProps(SearchInput);
