import { withJsonFormsControlProps } from "@jsonforms/react";
import DaysPicker from "./DaysPicker";

const DaysPickerControl = (props) => {
  const { handleChange, data, path, uischema, required } = props;

  return (
    <DaysPicker
      value={data}
      setValue={(newValue) => handleChange(path, newValue)}
      readOnly={uischema.readOnly}
      required={required}
    />
  );
};

export default withJsonFormsControlProps(DaysPickerControl);
