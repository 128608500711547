import { Breadcrumb, Button, LoadingSpinner } from "pepsico-ds";
import "./content-details.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ListingComponentContext } from "../../../components/common/listingComponents/ListingComponentContext";
import { convertDateToLocaleTz } from "../../../utils/commons";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { useQuery } from "@apollo/client";
import { getContentList } from "../../../graphql/queries/contentQueries";

const DetailsSection = ({ title, children }) => {
  return (
    <div className="details-section">
      <div className="details-section-title">{title}</div>
      <div className="details-section-content">{children}</div>
    </div>
  );
};

const ContentDetailsPage = () => {
  const { contentId } = useParams("contentId");
  const { data, loading } = useContext(ListingComponentContext);
  const { programTimezone } = useContext(GlobalConfigContext);
  const [content, setContent] = useState();
  const [quiz, setQuiz] = useState();
  const navigate = useNavigate();
  const { refetch } = useQuery(getContentList, {
    variables: {
      filter: {
        contentDefinitionSlug: "survey-quiz-content",
        contentData: { type: "QUIZ" },
      },
    },
    fetchPolicy: "cache-first",
    skip: true,
  });

  useEffect(() => {
    if (!data?.listContent) return;
    const content = data.listContent.find(
      (content) => content.id === contentId
    );
    if (!content) {
      navigate("../");
      return;
    }
    setContent(content);
    if (!content.contentData?.quiz) return;
    refetch().then(({ data }) => {
      const quiz = data?.listContent.find(
        (quiz) => quiz.id === content.contentData.quiz
      );
      setQuiz(quiz.title);
    });
  }, [data?.listContent, content, navigate, contentId, refetch]);

  const isLoading =
    loading ||
    !content ||
    !programTimezone ||
    (content.contentData?.quiz && !quiz);
  const contentType = content?.contentData?.content_type;
  const activeDateRange = `${
    content?.activeStartDate
      ? convertDateToLocaleTz(content.activeStartDate, programTimezone, false)
      : ""
  } - ${
    content?.activeEndDate
      ? convertDateToLocaleTz(content.activeEndDate, programTimezone, false)
      : ""
  }`;
  const linkTypeMap = {
    "web-url": "Weblink",
    "deep-link": "Deep Link",
    "quiz-url": "Quiz",
    "no-redirection": "Don't Redirect",
  };
  const editableStatuses = ["published", "paused", "inactive", "active"];
  const isEditable =
    content && editableStatuses.includes(content.status.toLowerCase());

  return (
    <div className="content-details">
      {!isLoading ? (
        <>
          <div className="content-details-header">
            <Breadcrumb
              breadcrumbs={[
                {
                  label: "Content",
                  href: "../",
                },
                { label: `${content?.title || ""} Details` },
              ]}
              activeIndex={1}
            />
          </div>
          <div className="content-details-body">
            <DetailsSection title="Basic Setup">
              <div className="details-section-row">
                {content.contentData?.cover_image && (
                  <div className="details-image-wrapper">
                    <span className="details-section-label">Image Cover</span>
                    <img
                      src={content.contentData?.cover_image}
                      alt="Cover"
                      className="details-image"
                    ></img>
                  </div>
                )}
                <div className="details-section-column">
                  <div className="details-section-row">
                    <div className="details-section-value">
                      <span className="details-section-label">Type</span>
                      <span>
                        {contentType?.charAt(0).toUpperCase() +
                          contentType?.slice(1) || ""}
                      </span>
                    </div>
                    <div className="details-section-value">
                      <span className="details-section-label">
                        Content Name
                      </span>
                      <span>{content.title}</span>
                    </div>
                  </div>
                  <div className="details-section-row">
                    <div className="details-section-value">
                      <span className="details-section-label">
                        Content Description
                      </span>
                      <span>{content.contentData?.description || ""}</span>
                    </div>
                  </div>
                  <div className="details-section-row">
                    <div className="details-section-value">
                      <span className="details-section-label">Active Date</span>
                      <span>{activeDateRange}</span>
                    </div>
                  </div>
                  {content.contentData?.video_url && (
                    <div className="details-section-row">
                      <div className="details-section-value">
                        <span className="details-section-label">Video URL</span>
                        <span>{content.contentData?.video_url}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </DetailsSection>
            <DetailsSection title="Page Setup">
              <div className="details-section-row">
                <div className="details-section-value">
                  <span className="details-section-label">Title</span>
                  <span>{content.contentData?.content_page_title}</span>
                </div>
                <div className="details-section-value">
                  <span className="details-section-label">Subtitle</span>
                  <span>{content.contentData?.content_page_subtitle}</span>
                </div>
              </div>
            </DetailsSection>
            <DetailsSection title="Specifications">
              <div className="details-section-row">
                <div className="details-section-value">
                  <span className="details-section-label">Link Type</span>
                  <span>
                    {linkTypeMap[content.contentData?.redirection_type] || ""}
                  </span>
                </div>
                {content?.contentData?.content_url && (
                  <div className="details-section-value">
                    <span className="details-section-label">URL</span>
                    <span>{content.contentData?.content_url}</span>
                  </div>
                )}
                {content?.contentData?.quiz && (
                  <div className="details-section-value">
                    <span className="details-section-label">Quiz</span>
                    <span>{quiz}</span>
                  </div>
                )}
                <div className="details-section-value">
                  <span className="details-section-label">Tags</span>
                  <span>{content.contentData?.tags.join(", ")}</span>
                </div>
                <div className="details-section-value">
                  <span className="details-section-label">
                    Position in Blog Page
                  </span>
                  <span>{content?.contentData?.position}</span>
                </div>
                <div className="details-section-value">
                  <span className="details-section-label">
                    Show in KZ World page
                  </span>
                  <span>
                    {content.contentData?.show_in_kz_world ? "On" : "Off"}
                  </span>
                </div>
                <div className="details-section-value">
                  <span className="details-section-label">Shareable</span>
                  <span>{content.contentData?.shareable ? "On" : "Off"}</span>
                </div>
                <div className="details-section-value">
                  <span className="details-section-label">Exclusive</span>
                  <span>{content.contentData?.exclusive ? "On" : "Off"}</span>
                </div>
              </div>
            </DetailsSection>
          </div>
        </>
      ) : (
        <div className="loading-container">
          <LoadingSpinner
            size={{
              height: "2rem",
              width: "2rem",
            }}
          />
        </div>
      )}
      <div className="content-details-footer">
        <div className="content-actions">
          <Button
            size="medium"
            variant={isEditable ? "secondary" : "primary"}
            text={isEditable ? "Discard" : "Close"}
            onClick={() => navigate("../")}
          />
          {isEditable && (
            <Button
              size="medium"
              text="Edit"
              onClick={() => navigate(`../edit/${contentId}`)}
              iconTrailing="arrow_forward"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentDetailsPage;
