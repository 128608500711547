import { TextInput } from "pepsico-ds";

const TextInputFieldArray = ({ value, updateValue, label }) => {
  const handleInputChange = (index, event) => {
    const newValue = value?.length ? [...value] : [];
    const values = [...newValue];
    values[index] = event.target.value;
    updateValue(values);
  };

  return (
    <div>
      <label htmlFor="codes">{label}</label>
      {value.map((field, index) => (
        <div key={index}>
          <TextInput
            type="text"
            value={field}
            onChange={(event) => handleInputChange(index, event)}
          />
        </div>
      ))}
    </div>
  );
};

export default TextInputFieldArray;
