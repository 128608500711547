import { useOktaAuth } from "@okta/okta-react";
import { Outlet } from "react-router-dom";
import LoaderNew from "../components/common/LoderNew";

const SecuredRoutes = () => {
  const { authState } = useOktaAuth();

  if (!authState || !authState.isAuthenticated) {
    return (
      <div className="flex w-screen items-center justify-center p-10">
        <LoaderNew />
      </div>
    );
  }
  return <Outlet />;
};

export default SecuredRoutes;
