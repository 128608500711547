import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Button, Checkbox } from "pepsico-ds";
import { useEffect, useState } from "react";
import { CustomTimePicker } from "../../common";
import "./daysPicker.scss";

const DaysPicker = ({ value, setValue, readOnly }) => {
  const [timeInterval, setTimeInterval] = useState({
    startHour: "",
    endHour: "",
    isAllweek: false,
  });
  const [selectedDays, setSelectedDays] = useState({
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
  });

  const onSelectDay = (day) => {
    const updatedSelectedDays = { ...selectedDays };
    if (updatedSelectedDays[day]) {
      updatedSelectedDays[day] = false;
    } else {
      updatedSelectedDays[day] = true;
      const updatedTimeInterval = { ...timeInterval };
      if (!timeInterval.startHour) {
        updatedTimeInterval.startHour = "00";
      }
      if (!timeInterval.endHour) {
        updatedTimeInterval.endHour = "24";
      }
      setTimeInterval(updatedTimeInterval);
    }
    setSelectedDays(updatedSelectedDays);
  };

  const mapValueToState = () => {
    if (value && Object.values(value).length > 0) {
      let updatedSelectedDays = { ...value };
      let updatedStartHour = timeInterval.startHour;
      let updatedEndHour = timeInterval.endHour;
      let updatedAllWeek = true;
      Object.keys(value).forEach((day) => {
        if (value[day].length > 0) {
          updatedSelectedDays[day] = true;
          updatedStartHour =
            value[day][0] < 10 ? `0${value[day][0]}` : value[day][0];
          const addedEndHour = value[day][value[day].length - 1] + 1;
          updatedEndHour = addedEndHour < 10 ? `0${addedEndHour}` : addedEndHour;
        } else {
          updatedSelectedDays[day] = false;
          updatedAllWeek = false;
        }
      });
      setSelectedDays(updatedSelectedDays);
      setTimeInterval({
        startHour: updatedStartHour,
        endHour: updatedEndHour,
        isAllweek: updatedAllWeek,
      });
    }
  };

  const mapStateToValue = () => {
    let updatedValue = {};
    Object.keys(selectedDays).forEach((day) => {
      if (selectedDays[day]) {
        updatedValue[day] = [];
        for (
          let i = parseInt(timeInterval.startHour);
          i < parseInt(timeInterval.endHour);
          i++
        ) {
          updatedValue[day].push(i);
        }
      } else {
        updatedValue[day] = [];
      }
    });
    setValue(updatedValue);
  };

  useEffect(() => {
    mapStateToValue();
  }, [selectedDays, timeInterval]);

  useEffect(() => {
    mapValueToState();
  }, []);

  const updateAllweekToggle = () => {
    return () => {
      const updatedAllWeek = !timeInterval.isAllweek;
      const updatedTimeInterval = { ...timeInterval };
      if (!updatedAllWeek) {
        updatedTimeInterval.startHour = "";
        updatedTimeInterval.endHour = "";
      } else if (
        updatedAllWeek &&
        !timeInterval.startHour &&
        !timeInterval.endHour
      ) {
        updatedTimeInterval.startHour = "00";
        updatedTimeInterval.endHour = "24";
      }
      setTimeInterval({
        ...updatedTimeInterval,
        isAllweek: updatedAllWeek,
      });
      const updatedSelectedDays = { ...selectedDays };
      Object.keys(selectedDays).forEach((day) => {
        updatedSelectedDays[day] = updatedAllWeek;
      });
      setSelectedDays(updatedSelectedDays);
    };
  };

  return (
    <div
      className="display-flex align-items-start gap-3 justify-content-start"
      style={{ width: "100%" }}
    >
      <div className="display-flex flex-direction-column gap-4">
        <div className="display-flex align-items-center gap-4">
          <div className="display-flex">
            {Object.keys(selectedDays).map((day, index) => (
              <Button
                disabled={readOnly}
                onClick={() => onSelectDay(day)}
                style={{
                  fontSize: "14px",
                  padding: "0.25rem 0.75rem",
                  color: selectedDays?.[day] ? "white" : "#6D6E70",
                  fontWeight: "normal",
                  background: selectedDays?.[day] ? "#005CBC" : "",
                  borderTop: selectedDays?.[day] ? "1px solid #6D6E70" : "",
                  borderBottom: selectedDays?.[day] ? "1px solid #6D6E70" : "",
                }}
                className="no-border"
                variant={selectedDays?.[day] ? "primary" : "secondary"}
                key={index}
              >
                {day}
              </Button>
            ))}
          </div>
          <Checkbox
            disabled={readOnly}
            onChange={updateAllweekToggle()}
            text="All Week"
            checked={timeInterval.isAllweek}
          />
        </div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="display-flex gap-4" style={{ padding: "0px" }}>
            <CustomTimePicker
              label="Start Hour"
              placeholder="HH"
              timeInterval={timeInterval}
              setTimeInterval={setTimeInterval}
              value={timeInterval.startHour}
              maxValue={parseInt(timeInterval.endHour) - 1}
              setValue={(value) =>
                setTimeInterval({
                  ...timeInterval,
                  startHour: value,
                })
              }
              disabled={Object.values(selectedDays).every((day) => !day) || readOnly}
            />
            <CustomTimePicker
              placeholder="HH"
              label="End Hour"
              value={timeInterval.endHour}
              minValue={parseInt(timeInterval.startHour) + 1}
              setValue={(value) =>
                setTimeInterval({
                  ...timeInterval,
                  endHour: value,
                })
              }
              disabled={Object.values(selectedDays).every((day) => !day) || readOnly}
            />
          </div>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default DaysPicker;
