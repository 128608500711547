import { withJsonFormsLayoutProps } from "@jsonforms/react";
import CustomLayoutRenderer from "../CustomLayoutRenderer/CustomLayoutRenderer";

const VerticalLayout = ({ schema, uischema, path, renderers, visible, label }) => {
  const layoutProps = {
    elements: uischema.elements,
    schema: schema,
    path: path,
    direction: "column",
    visible: visible,
    gap: uischema.gap,
    uischema: uischema,
    renderers: renderers,
    label: label,
  };
  return (
    <>
      <CustomLayoutRenderer {...layoutProps} />
    </>
  );
};

export default withJsonFormsLayoutProps(VerticalLayout);
