const DayPartingViewComponent = ({ data }) => {
  // console.log("🚀 ~ DayPartingViewComponent ~ data:", data);
  const dayPartingData = data.day_parts || {};
  const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  const dayLabels = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  // console.log(dayPartingData);
  // Group the days with the same hours together
  const groupedDays = {};
  days.forEach((day, index) => {
    const hours = dayPartingData[day];
    // console.log(hours);
    if (hours?.length > 0) {
      const key = `${hours[0]}-${hours[hours.length - 1]}`;
      if (!groupedDays[key]) {
        groupedDays[key] = {
          days: [dayLabels[index]],
          hours: hours,
        };
      } else {
        groupedDays[key].days.push(dayLabels[index]);
      }
    }
  });
  // console.log(groupedDays);
  return (
    <div style={{ fontSize: "12px", display: "flex" }}>
      <strong style={{ minWidth: "100px" }}>Time Interval : </strong>
      {Object.values(groupedDays).map((group, index) => (
        <div key={index}>
          Every {group.days.join(", ")} from {group.hours[0]} hrs to{" "}
          {group.hours[group.hours.length - 1] + 1} hrs
        </div>
      ))}
    </div>
  );
};

export default DayPartingViewComponent;
