import { createAjv } from "@jsonforms/core";
import { Button, Icon, TextInput } from "pepsico-ds";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

const SingleInput = ({
  index,
  value,
  handleInputChange,
  required,
  keys,
  onDelete,
  field,
  uiSchema,
  schema,
}) => {
  const [errMsg, setErrMsg] = useState("");
  const getErrMsg = () => {
    const input = value[index];
    if (required && !input) {
      return "is a required property";
    }
    if (schema.format === "uri") {
      const ajvSchema = {
        type: "object",
        properties: {
          url: {
            type: "string",
            format: "uri",
          },
        },
        required: ["url"],
      };

      const ajv = createAjv();
      const result = ajv.validate(ajvSchema, { url: input });
      if (!result) {
        return "must be a valid url";
      }
      return "";
    }
    return "";
  };

  return (
    <div
      key={index}
      className="mt-12 mb-12 display-flex align-items-center custom-width"
    >
      <TextInput
        type="text"
        value={value[index]}
        onChange={(event) => {
          // setErrMsg(getErrMsg(event));
          handleInputChange(index, event);
        }}
        style={{ width: "95%" }}
        placeholderText={uiSchema.placeholder ?? ""}
        onBlur={() => {
          // if (!isFocused[index]) {
          //   const updated = [...isFocused];
          //   updated[index] = true;
          //   setIsFocused(updated);
          // }

          setErrMsg(getErrMsg());
        }}
        error={errMsg}
      />
      {keys?.length > 1 && (
        <Icon
          alt="delete"
          className="$color-icon-grey cursor-pointer"
          icon="delete"
          size="medium"
          variant="filled"
          onClick={() => onDelete(field, index)}
        />
      )}
    </div>
  );
};

const SingleInputFieldArray = ({
  value,
  updateValue,
  label,
  uiSchema,
  required,
  schema,
}) => {
  const [keys, setKeys] = useState([Date.now().toString()]);
  const [isFocused, setIsFocused] = useState([false]);

  useEffect(() => {
    if (value?.length > 1 && keys?.length < value?.length) {
      setKeys(value.map(() => (Date.now() + 1000).toString()));
    }
  }, [value?.length]);

  const handleInputChange = (index, event) => {
    const input = event.target.value;
    const updated = [...value];
    if (value.length === 1 && !input) {
      updateValue([]);
    } else {
      updated[index] = input;
      updateValue(updated);
    }
  };

  const handleAddField = () => {
    setKeys([...keys, Date.now().toString()]);
    setIsFocused([...isFocused, false]);
  };

  const onDelete = (field, index) => {
    //setKeys(keys.filter((k) => k !== field));
    const updatedKeys = keys.splice(index, 1);
    setKeys(updatedKeys);
    setIsFocused(isFocused.filter((k, i) => i !== index));
    updateValue(value.filter((v, i) => i !== index));
  };

  return (
    <div>
      <div className="display-flex justify-content-space-between">
        <label htmlFor="singleInput" className="mb-12 font-xs">
          {label}
        </label>
        <label className="required-text $font-xxsmall-bold">
          {required && "*Required"}
        </label>
      </div>
      {keys.map((field, index) => (
        <SingleInput
          key={index}
          field={field}
          handleInputChange={handleInputChange}
          index={index}
          isFocused={isFocused}
          keys={keys}
          onDelete={onDelete}
          required={required}
          setIsFocused={setIsFocused}
          value={value}
          uiSchema={uiSchema}
          schema={schema}
        />
      ))}
      <Button
        onClick={handleAddField}
        disabled={!value[keys.length - 1] || !value[keys.length - 1].trim()}
      >
        Add
      </Button>
    </div>
  );
};

SingleInputFieldArray.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  uiSchema: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  required: PropTypes.bool,
  schema: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.string),
};

SingleInputFieldArray.defaultProps = {
  uiSchema: {},
  required: false,
  schema: {},
  errors: [],
};

export default SingleInputFieldArray;
