import { Modal } from "pepsico-ds";
import * as React from "react";
import "./toggleStyles.scss";

export default function SwitchFieldWithModal(props) {
  const { value, updateValue, label, uiSchema } = props;

  const [showModal, setShowModal] = React.useState(false);

  const onUpdate = () => {
    setShowModal(true);
  };

  return (
    <>
      <div>
        <span className="$font-xsmall-regular label-text $color-text-secondary mr-2">
          {label}
        </span>
        <label className="switchWithConfirmModal">
          <input
            type="checkbox"
            checked={!!value}
            value={value}
            onChange={onUpdate}
          />
          <span className="slider round"></span>
        </label>
      </div>
      <Modal
        className="toggleModalStyles"
        onCloseModal={() => setShowModal(false)}
        primaryButtonProps={{
          onClick: () => {
            updateValue(!value);
            setShowModal(false);
          },
          size: "medium",
          text: "Got it",
          variant: "primary",
        }}
        showLink={false}
        showSecondaryButton={false}
        showSlot={false}
        showTertiaryButton={false}
        title={
          value
            ? uiSchema.disablePopupMessage || "Are you sure!"
            : uiSchema.enablePopupMessage || "Are you sure!"
        }
        type="fluid"
        showModal={showModal}
      >
        <></>
      </Modal>
    </>
  );
}
