import { useGetUserClaimsOkta } from "./useGetUserClaimsOkta";
import { useCallback } from "react";

export const useHasAccessPermission = () => {
  const userInfo = useGetUserClaimsOkta();
  const roles = userInfo?.pepappwsproles ?? null;

  const hasAccess = useCallback(
    (requiredAccess) => {
      if (requiredAccess.includes("all") || window.REACT_APP_PROGRAM === "kaz")
        return true;

      if (roles === null || !Array.isArray(roles)) return false;

      return requiredAccess.some((access) => roles.includes(access));
    },
    [roles]
  );

  return hasAccess;
};
