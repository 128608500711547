import { gql } from "@apollo/client";

export const listCodeGroups = gql`
  query ListCodeGroups($filter: CodeGroupFilter) {
    listCodeGroups(filter: $filter) {
      codeGroupId
      codesGeneratedCount
      administrationStatus
      lifecycleStatus
      exportUrl
      basicSetup {
        codeGroupName
        codeActiveStartDate
        codeActiveEndDate
      }
      producerPackaging {
        producerCompany
        codesPrintedCount
      }
    }
  }
`;
