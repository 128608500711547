import { Checkbox } from "pepsico-ds";

export default function CheckboxField(props) {
  const { value, updateValue, label, uiSchema, disabled } = props;
  return (
    <>
      <Checkbox
        helperText={uiSchema.description}
        onUpdate={updateValue}
        text={label}
        checked={value}
        disabled={disabled}
      />
    </>
  );
}
