import { Modal } from "pepsico-ds";
import { useState } from "react";
import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";

const uiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      label: "Name",
      scope: "#/properties/name",
    },
    {
      type: "Control",
      label: "Description",
      scope: "#/properties/description",
      component: "textarea",
    },
  ],
};

const jsonSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      minLength: 3,
      description: "Please enter name",
    },
    description: {
      type: "string",
      minLength: 3,
      description: "Please enter description",
    },
    active: {
      type: "boolean",
    },
  },
  required: ["name", "description"],
};

function CreatePartnerModel({
  createPartnerModalOpen,
  toggleCreatePartnerModal,
}) {
  const [partnerData, setPartnerData] = useState({});
  const handleContent = (data) => {
    setPartnerData(data);
    console.log("🚀 ~ handleContent ~ event:", data);
  };

  return (
    <Modal
      showModal={createPartnerModalOpen}
      onCloseModal={toggleCreatePartnerModal}
      showSecondaryButton={false}
      title={"Create Partner "}
      primaryButtonProps={{
        size: "medium",
        text: "Create Partner",
        variant: "primary",
        onClick: () => console.log("Create Partner"),
      }}
      secondaryButtonProps={{
        size: "medium",
        text: "Cancel",
        variant: "secondary",
      }}
      showTertiaryButton={false}
      showLink={false}
    >
      <JsonFormsWrapper
        jsonschema={jsonSchema}
        uischema={uiSchema}
        data={{ partnerData }}
        onChange={() => handleContent}
      />
    </Modal>
  );
}

export default CreatePartnerModel;
