import { Grid } from "@mui/material";
import { SingleBorderCard } from "../../../common/SingleBorderCard";

const KeywordsGridView = ({
  keywordGroupsListData,
  selectedKeywordGroupList,
  loading,
  onSelectKeywordGroup,
}) => {
  return (
    <Grid container spacing={2} columns={15}>
      {!loading &&
        keywordGroupsListData?.length > 0 &&
        keywordGroupsListData?.map((keywordGroup) => (
          <Grid
            item
            xs={6}
            md={3}
            lg={3}
            xl={3}
            className="cursor-pointer"
            key={keywordGroup.id}
            sx={{ width: "100%" }}
            onClick={() => onSelectKeywordGroup(keywordGroup)}
          >
            <SingleBorderCard
              width="100%"
              border={
                selectedKeywordGroupList?.find((item) => {
                  return item.id === keywordGroup.id;
                })
                  ? "2px solid green"
                  : "none"
              }
              boxShadow="0px 0px 24px 0px #005CBC14"
            >
              <img
                src={keywordGroup.imageUrl || "/assets/images/placeholder.jpg"}
                height={200}
                style={{
                  borderRadius: "6px",
                  objectFit: "cover",
                  width: "100%",
                  background: `${keywordGroup.imageUrl} || /assets/images/placeholder.jpg`,
                  backgroundSize: "contain",
                  maxHeight: "150px",
                }}
              />
              <h4
                className="font-sm text-wrap text-ellipsis-2"
                style={{ minHeight: "35px" }}
                title={keywordGroup.name}
              >
                {keywordGroup.name}
              </h4>
              {!!selectedKeywordGroupList?.find((item) => {
                return item.id === keywordGroup.id;
              }) && (
                <img
                  src={"/assets/images/checkmark.png"}
                  height={24}
                  width={24}
                  style={{ position: "absolute", right: 10, top: 10 }}
                />
              )}
            </SingleBorderCard>
          </Grid>
        ))}
    </Grid>
  );
};

export default KeywordsGridView;
