import { useMutation } from "@apollo/client";
import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { ButtonGroup, Dropdown, InputItem, Modal } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import saveTag from "../../../graphql/mutations/saveTag";
import Button from "../../common/button/Button";
import ToastNotification from "../../common/toastNotification/ToastNotification";
import "./_campaign-tag-select.scss";

const CampaignTagSelect = (props) => {
  const { handleChange, label, path, required, visible, enabled, uischema, data } =
    props;

  const formatOptions = (options) =>
    options.map((option) => ({
      id: option,
      displayText: option,
    }));
  const formatData = (data) => (data ? data.map((item) => item.id) : []);

  const [selected, setSelected] = useState(data?.length ? formatOptions(data) : []);
  const [openModal, setOpenModal] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [saveTagMutation, { loading }] = useMutation(saveTag);
  const [toast, setToast] = useState({
    open: false,
    type: "",
    title: "",
    description: "",
  });

  const { tagsData, refetchProgramConfig } = useContext(GlobalConfigContext);
  const [tagsOptions, setTagsOptions] = useState(
    tagsData?.[uischema.tagType?.toLowerCase() || "campaign"] || []
  );

  const createTagButtonProps = {
    label: "Create New Tag",
    onClick: () => handleToggleModal(),
  };

  const handleSelectValue = (val) => {
    setSelected(val);
    handleChange(path, formatData(val));
  };

  const handleToggleModal = () => setOpenModal(!openModal);

  const handleTypeTag = (e) => setNewTag(e.target.value.trimStart());

  const handleSaveTag = async () => {
    const params = {
      variables: {
        tag: {
          type: uischema.tagType || "CAMPAIGN",
          value: newTag,
        },
      },
    };
    try {
      const updatedTags = await saveTagMutation(params);
      if (updatedTags?.data?.saveTag) {
        setTagsOptions(updatedTags?.data?.saveTag);
        refetchProgramConfig();
        handleToggleModal();
        openSuccessToast(newTag);
        setNewTag("");
      }
    } catch {
      handleToggleModal();
      openErrorToast();
      setNewTag("");
    }
  };

  const openSuccessToast = (createdTag) => {
    setToast({
      open: true,
      type: "success",
      title: `'${createdTag}' Created!`,
      description: "You have just created a new tag!",
    });
  };

  const openErrorToast = () => {
    setToast({
      open: true,
      type: "error",
      title: "Something went wrong while saving the tag.",
      description: "Try again later.",
    });
  };

  useEffect(() => {
    if (data?.length > 0 && selected?.length === 0) {
      setSelected(data?.length ? formatOptions(data) : []);
    }
  }, [data]);

  return visible ? (
    <div className="tag-select">
      <Dropdown
        label={label}
        childList={formatOptions(tagsOptions)}
        selection="multiple"
        isRequired={required}
        disabled={!enabled}
        selectedValue={selected}
        setSelectedValue={(val) => handleSelectValue(val)}
      />
      <ButtonGroup items={[createTagButtonProps]} />
      <Modal
        className="create-tag-modal"
        showModal={openModal}
        title="Create a New Tag"
        text=""
        showTertiaryButton={false}
        showLink={false}
        showSecondaryButton={false}
        primaryButtonProps={{}}
        onCloseModal={handleToggleModal}
      >
        <InputItem
          placeholder="Type here"
          maxLength={uischema.maxLength}
          value={newTag}
          onChange={(e) => handleTypeTag(e)}
        />
        <div className="action-buttons">
          <Button
            size="medium"
            isLoading={loading}
            onClick={handleSaveTag}
            disabled={!newTag.length}
          >
            Create
          </Button>
        </div>
      </Modal>
      <ToastNotification
        {...toast}
        handleClose={() => setToast({ ...toast, open: false })}
      />
    </div>
  ) : null;
};

export default withJsonFormsMultiEnumProps(CampaignTagSelect);
