import { withJsonFormsControlProps } from "@jsonforms/react";
import { TextInput } from "pepsico-ds";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { validateDomains } from "../../../utils/commons";

const EmailDomainsComponent = (props) => {
  const { handleChange, label, data, path, uischema, required, enabled, visible } =
    props;
  const [errMsg, setErrMsg] = useState("");
  const [value, setValue] = useState(Array.isArray(data) ? data.join(",") : data);
  useEffect(() => {
    if (!enabled && value) {
      setValue("");
      handleChange(path, []);
    }
  }, [enabled]);

  const handleUpdate = (val) => {
    // Convert comma-separated string to array
    const updatedValue = val.split(",").map((item) => item.trim());
    setValue(val);
    handleChange(path, updatedValue);
    const isValid = validateDomains(updatedValue);
    if (isValid || val === "") {
      setErrMsg("");
      handleChange(path, updatedValue);
    } else setErrMsg("Invalid domain format");
  };

  return visible ? (
    <>
      <TextInput
        required={required}
        disabled={!enabled}
        error={errMsg ? errMsg : undefined}
        placeholder={uischema.placeholder || ""}
        type="text"
        info={uischema?.info || ""}
        label={label}
        onUpdate={handleUpdate}
        value={value}
        className="mb-2"
      />
    </>
  ) : null;
};

EmailDomainsComponent.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  path: PropTypes.string,
  uischema: PropTypes.object,
  required: PropTypes.bool,
  enabled: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
};

EmailDomainsComponent.defaultProps = {
  data: [],
  required: false,
  enabled: false,
};

export default withJsonFormsControlProps(EmailDomainsComponent);
