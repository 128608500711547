import { gql } from '@apollo/client';

const saveBanner = gql`
  mutation saveMyBanner($content: ContentInput!) {
    saveContent(content: $content) {
      id
      title
      slug
      segments
      description
      status
      paused
      activeStartDate
      activeEndDate
      contentDefinition {
        id
      }
      contentData
      tags
      category
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export default saveBanner;
  