import { FormControl, MenuItem, Select } from "@mui/material";
import { capitalizeFirstLetter } from "../../../utils/commons";

export default function MUISelectField(props) {
  const { value, updateValue, label, schema, uiSchema, required, disabled } = props;

  return (
    <FormControl fullWidth required={required} /* error={!!errors} */ size="small">
      <p style={{ fontSize: "12px" }}>
        {required ? schema.label || uiSchema.label + "*" : schema.label}
      </p>
      <Select
        style={{ fontSize: "13px", marginTop: "7px", maxHeight: "32px" }}
        value={value || ""}
        label={label}
        onChange={(event) => updateValue(event.target.value)}
        disabled={disabled || uiSchema.readOnly}
        displayEmpty
      >
        <MenuItem value="" disabled>
          {schema.header}
        </MenuItem>
        {schema.enum.map((option, index) => (
          <MenuItem key={index} value={option}>
            {capitalizeFirstLetter(option?.replace(/_/g, " "))}
          </MenuItem>
        ))}
      </Select>
      {/* {errors && <FormHelperText>{errors}</FormHelperText>} */}
    </FormControl>
  );
}
