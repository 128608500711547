import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

const useFakeProgressValue = (
  initialValue = 0,
  maxValue = 100,
  internalTime = 600
) => {
  const [progressValue, setProgressValue] = useState(() => initialValue);
  const ref = useRef(false);

  const initProgress = useCallback(() => {
    ref.current = true;
  }, []);

  const finishProgress = useCallback(() => {
    ref.current = false;
    setProgressValue(maxValue);
  }, [maxValue]);

  const resetProgress = useCallback(() => {
    ref.current = false;
    setProgressValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (ref.current === true) {
        const freezeValue = maxValue - maxValue / 10;
        const addValue = _.random(1, 5);
        setProgressValue((oldValue) => {
          const newValue = oldValue + addValue;
          if (newValue > freezeValue) {
            return oldValue;
          }

          return newValue;
        });
      }
    }, internalTime);

    return () => clearInterval(interval);
  }, [ref, maxValue, internalTime]);

  return {
    progressValue,
    initProgress,
    finishProgress,
    resetProgress,
  };
};

export default useFakeProgressValue;
