import { Checkbox, LoadingSpinner } from "pepsico-ds";
import "./keywordsTable.scss";

const KeywordsTable = ({
  keywordGroupsListData,
  selectedKeywordGroupList,
  loading,
  filterBy,
  onSelectKeywordGroup,
}) => {
  return (
    <div className="table-list-checkbox p-0">
      {loading ? (
        <LoadingSpinner
          size={{
            height: "30px",
            width: "30px",
          }}
          style={{ margin: "auto" }}
        />
      ) : (
        <table>
          <thead>
            <tr>
              <th className="column-checkbox"></th>
              <th className="column-brands">
                {filterBy === "BRAND"
                  ? "BRANDS"
                  : filterBy === "PRODUCT"
                    ? "SKU/PRODUCTS"
                    : "RETAILERS"}
              </th>
              <th className="column-keywords">KEYWORDS</th>
            </tr>
          </thead>
          <tbody>
            {keywordGroupsListData?.length ? (
              keywordGroupsListData?.map((keywordGroup) => (
                <tr key={keywordGroup.id}>
                  <td className="td-checkbox">
                    <Checkbox
                      id={`checkbox-${keywordGroup.id}`}
                      onUpdate={() => onSelectKeywordGroup(keywordGroup)}
                      checked={selectedKeywordGroupList?.some(
                        (row) => row.id === keywordGroup.id
                      )}
                    />
                  </td>
                  <td className="td-brands">{keywordGroup.name}</td>
                  <td className="td-keywords">
                    {keywordGroup.keywords.length > 0
                      ? keywordGroup.keywords.join(", ")
                      : "None"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="no-data">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default KeywordsTable;
