import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { CampaignSetupContext } from "../../../context/CampaignSetupContext";

const CategorySelect = (props) => {
  const { handleChange, label, path, required, visible, enabled, data } = props;
  const { currentCampaignTypeConfig } = useContext(CampaignSetupContext);

  const [selected, setSelected] = useState(undefined);
  // FIXME: setCategories is never used
  const [categories] = useState(currentCampaignTypeConfig?.categories || []);

  const formatOptions = (options) =>
    options.map((option, index) => ({
      id: index,
      displayText: option,
    }));

  useEffect(() => {
    if (data) {
      const selectedCategory = formatOptions(categories).find(
        (category) => category.displayText === data
      );
      if (selectedCategory) {
        setSelected([selectedCategory]);
      }
    }
  }, [categories, data]);

  const handleSelectValue = (val) => {
    if (val) {
      setSelected(val[0].index);
      handleChange(path, val[0].displayText);
    }
  };

  return visible ? (
    <div className="multiple-select-wrap">
      <label style={{ fontSize: 12, color: "#3a3a3a" }}>
        {label} {required ? "*" : ""}
      </label>
      <Dropdown
        childList={formatOptions(categories)}
        selection="single"
        disabled={!enabled}
        selectedValue={selected}
        setSelectedValue={(val) => handleSelectValue(val)}
      />
    </div>
  ) : null;
};

export default withJsonFormsMultiEnumProps(CategorySelect);
