// src/hooks/useSegments.js
import { useQuery } from "@apollo/client";
import { listSegments } from "../graphql/queries/listSegments";

export function useSegments(filter) {
  console.log(filter, "DAVID BORRIES");
  const { loading, error, data, refetch } = useQuery(listSegments, {
    variables: { filter },
  });

  return {
    loading,
    error,
    segments: data ? data.listSegments : [],
    refetch,
  };
}
