import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckIcon from "@mui/icons-material/Check";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useState } from "react";
import "./listDropdownFilter.scss";

const ListDropdownFilter = ({ label, options, selectedValue, setSelectedValue }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText className="filter-type-setup-label" primary={label} />
        {open ? (
          <ArrowDropDownIcon className="toggleIcon" />
        ) : (
          <ArrowDropUpIcon className="toggleIcon" />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {options.map((option, index) => (
            <ListItemButton
              key={index}
              sx={{
                p: "0.5rem 1rem",
                display: "flex",
                justifyContent: "space-between",
                color: selectedValue === option ? "#005CBC" : "",
                backgroundColor: selectedValue === option ? "#F3F7FD" : "",
              }}
              onClick={() => setSelectedValue(option)}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span className="font-xs">{option}</span>
              </div>
              {selectedValue === option && <CheckIcon fontSize="small" />}
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default ListDropdownFilter;
