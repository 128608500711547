import { useMutation } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { CircularProgress } from "@mui/material";
import { Button } from "pepsico-ds";
import PropTypes from "prop-types";
import { useState } from "react";
import createAsset from "../../../graphql/mutations/createAsset";
import FileUploadField from "./FileUploadField";
import "./imageUploader.scss";

const SingleImageUploadControl = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
    visible,
    enabled = true,
  } = props;

  const [file, setFile] = useState({ base64: "", name: data, thumb: data });
  const [loader, setLoader] = useState(false);
  const [createAssetMutation] = useMutation(createAsset);
  const [error, setError] = useState({ msg: "", tryAgainButton: false });

  const acceptedExtensions = uischema.accept?.toString() || ".jpeg,.jpg,.png";
  const maxWidth = schema?.maxWidth ?? uischema?.maxWidth ?? 2000;
  const maxHeight = schema?.maxHeight ?? uischema?.maxHeight ?? 2000;
  const maxSize = uischema?.maxSize || 1000;

  const handleFileUpload = async (newFile, newFileName) => {
    if (typeof newFile === "string") {
      handleChange(path, newFile);
      setFile({ thumb: newFile, name: newFileName, base64: undefined });
      return;
    }
    //setIsLoading(true);
    //covert the newFile into base64
    const reader = new FileReader();
    const fileExtension = newFile.name.split(".").pop();
    const hasIncludeExt = acceptedExtensions.includes(fileExtension);
    if (!hasIncludeExt) {
      setError({
        msg: `Invalid file type! Allowed files types are ${acceptedExtensions.toString()}`,
        tryAgainButton: false,
      });
      return;
    } else if (newFile.size && Math.ceil(newFile.size / 1000) > maxSize) {
      setError({
        msg: `Image is too large! Maximum size allowed is ${Math.round(maxSize / 1000)}MB.`,
        tryAgainButton: false,
      });
      return;
    } else {
      setError({ msg: "", tryAgainButton: false });
    }
    reader.readAsDataURL(newFile);
    reader.onload = () => {
      setFile({ base64: reader.result, name: newFile.name });
    };
  };

  const callCreateAsset = async () => {
    if (!file?.base64) {
      return;
    }

    try {
      const params = {
        variables: {
          Asset: { data: file?.base64?.split(",")[1] },
        },
      };
      setLoader(true);
      const imageUpload = await createAssetMutation(params);
      setLoader(false);
      if (imageUpload?.data?.createAsset?.url) {
        handleChange(path, imageUpload?.data?.createAsset?.url);
      } else {
        setError({ msg: "Something went wrong", tryAgainButton: true });
      }
    } catch (error) {
      console.error(error);
      setLoader(false);
      setError({ msg: "Something went wrong", tryAgainButton: true });
    }
  };

  const cleanImage = () => {
    setError({ msg: "", tryAgainButton: false });
    setFile({ base64: "", name: "" });
    handleChange(path, "");
  };

  const imgLoadHandler = (e) => {
    setError({ msg: "", tryAgainButton: false });
    const naturalWidth = e.target?.naturalWidth;
    const naturalHeight = e.target?.naturalHeight;
    if (maxWidth && naturalWidth > maxWidth) {
      setError({
        msg: "Maximum " + maxWidth + "px image width allowed!",
        tryAgainButton: false,
      });
    } else if (maxHeight && naturalHeight > maxHeight) {
      setError({
        msg: "Maximum " + maxHeight + "px image height allowed!",
        tryAgainButton: false,
      });
    } else {
      callCreateAsset();
    }
  };

  return (
    visible && (
      <>
        <div className="fileUploadHeader">
          <span className="label-text $font-xsmall-regular">
            <label>{label}</label>
          </span>
          {required && (
            <span className="required-text $font-xxsmall-bold">*Required</span>
          )}
        </div>
        {file.name && (
          <div className="image-container">
            <img
              src={file?.base64 ? file.base64 || data : file?.thumb}
              alt={file?.name}
              onLoad={imgLoadHandler}
              style={{
                width: "100%",
                height: "10rem",
                cursor: "pointer",
                objectFit: "contain",
              }}
            />
            <div className={loader ? "imageOverlay loader" : "imageOverlay"}>
              {loader ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={cleanImage}
                  style={{ backgroundColor: "#117D9F" }}
                  size="medium"
                  iconTrailing="autorenew"
                  disabled={!enabled}
                >
                  Replace Image
                </Button>
              )}
            </div>
          </div>
        )}
        <>
          {error.msg && (
            <div className="display-flex gap-2 align-items-center mt-2">
              <span style={{ color: "red", fontSize: "14px" }}>{error.msg}</span>
              {error.tryAgainButton && (
                <Button variant="borderless" onClick={callCreateAsset}>
                  try again
                </Button>
              )}
            </div>
          )}
        </>
        {!file.name && (
          <FileUploadField
            className="fileUploader"
            value={data || [file.name]}
            updateValue={(newFile, newFileName) =>
              handleFileUpload(newFile, newFileName)
            }
            errors={errors}
            schema={schema}
            uiSchema={uischema}
            acceptedExtensions={acceptedExtensions}
            required={required}
            disabled={!enabled}
          />
        )}
      </>
    )
  );
};

SingleImageUploadControl.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  data: PropTypes.string,
  path: PropTypes.string.isRequired,
  errors: PropTypes.array,
  schema: PropTypes.object,
  uischema: PropTypes.shape({
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    maxWidth: PropTypes.number,
    maxHeight: PropTypes.number,
    maxSize: PropTypes.number,
  }),
  required: PropTypes.bool,
};

export default withJsonFormsControlProps(SingleImageUploadControl);
