import { useQuery } from "@apollo/client";
import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { listSweepstakes } from "../graphql/queries/listSweepstakes";

const ListSweepstakesContext = createContext();

const ListSweepstakesProvider = ({ children }) => {
  const [sweepstakesList, setSweepstakesList] = useState();
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();

  const { data, loading, refetch } = useQuery(listSweepstakes, {
    onCompleted: () => {
      setLoading(false);
    },
    onError: (err) => {
      console.error(err);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (data && data?.listSweepstakes) {
      setSweepstakesList(data.listSweepstakes);
    }
    setLoading(false);
  }, [data]);

  useEffect(() => {
    if (loading === false && isLoading === true) {
      setLoading(false);
    }
  }, [loading, isLoading]);

  useEffect(() => {
    const refreshSweepstakes = async () => {
      if (
        listSweepstakes &&
        location.pathname === "/sweepstakes/winners-management"
      ) {
        await refetch();
      }
    };
    refreshSweepstakes();
  }, [location.pathname, refetch]);

  const contextValue = {
    loading,
    sweepstakesList,
    refetch,
  };

  return (
    <ListSweepstakesContext.Provider value={contextValue}>
      {children}
    </ListSweepstakesContext.Provider>
  );
};

export { ListSweepstakesContext, ListSweepstakesProvider };
