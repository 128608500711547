export const partnersActionButtons = (status) => {
  const actionButtons = [
    {
      icon: "visibility",
      onClick: () => console.log("View Details"),
    },
    {
      icon: "content_copy",
      onClick: () => console.log("Copy"),
    },
  ];
  const editableStatuses = ["draft", "scheduled", "active"];
  const deletableStatuses = ["inactive"];

  editableStatuses.includes(status) &&
    actionButtons.push({
      icon: "edit",
      onClick: () => console.log("Edit"),
    });
  deletableStatuses.includes(status) &&
    actionButtons.push({
      icon: "delete",
      onClick: () => console.log("Delete"),
    });
  return actionButtons;
};
