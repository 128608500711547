import { Chip } from "@mui/material";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import "./SystemSettingTable.scss";

const getCampaignActionButtons = ({ item, onClickEdit, onClickView }) => {
  return [
    {
      icon: "edit",
      onClick: () => onClickEdit(item),
    },
    {
      icon: "visibility",
      onClick: () => onClickView(item),
    },
  ];
};
const SystemSettingTable = ({ rewardData, onClickEdit, onClickView, chip }) => {
  if (!rewardData || rewardData.length === 0) {
    return <p>No data available</p>;
  }
  const columns = Object.keys(rewardData[0]);
  return (
    <div className="list-container">
      <table className="list-table">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>
                {column.charAt(0).toUpperCase() + column.slice(1)}
              </th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rewardData.map((item, index) => {
            const actionButtons = getCampaignActionButtons({
              item,
              onClickEdit,
              onClickView,
            });
            return (
              <tr key={index}>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>
                    {item[column] || "-"}
                    {chip && (
                      <span style={{ paddingLeft: "10px" }}>
                        <Chip
                          sx={{ backgroundColor: "#AAD2E2" }}
                          size="small"
                          label="Required to accept"
                        />
                      </span>
                    )}
                  </td>
                ))}
                <td>
                  <div className="button-group-wrapper">
                    <ButtonGroupDynamic
                      buttons={actionButtons}
                      spacing="0px"
                      size="medium"
                      variant="control"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SystemSettingTable;
