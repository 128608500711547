import { ButtonGroup, InputItem } from "pepsico-ds";
import { useEffect, useState } from "react";
import PartnerCodeGroupCard from "./PartnerCodeGroupCard/PartnerCodeGroupCard";
import PartnerCodeGroupTable from "./PartnerCodeGroupTable/PartnerCodeGroupTable";
import "./partners.scss";

// Need to delete this data and replace with actual data
const partnerCodeGroupData = [
  {
    id: "1",
    title: "Code Group 1",
    description: "Description for Code Group 1",
    totalCodes: 1000,
    exhaustedCodes: 500,
    activeDate: "2024-01-01T00:00:00Z",
    inactiveDate: "2024-12-31T23:59:59Z",
    partnerCodeGroupStatus: "Active",
    codeUploadStatus: "Code Imported",
  },
  {
    id: "2",
    title: "Code Group 2",
    description: "Description for Code Group 2",
    totalCodes: 2000,
    exhaustedCodes: 1500,
    activeDate: "2023-01-01T00:00:00Z",
    inactiveDate: "2023-12-31T23:59:59Z",
    partnerCodeGroupStatus: "Inactive",
    codeUploadStatus: "Pending",
  },
  {
    id: "2",
    title: "Code Group 2",
    description: "Description for Code Group 2",
    totalCodes: 2000,
    exhaustedCodes: 1500,
    activeDate: "2023-01-01T00:00:00Z",
    inactiveDate: "2023-12-31T23:59:59Z",
    partnerCodeGroupStatus: "Inactive",
    codeUploadStatus: "Pending",
  },
  {
    id: "2",
    title: "Code Group 2",
    description: "Description for Code Group 2",
    totalCodes: 2000,
    exhaustedCodes: 1500,
    activeDate: "2023-01-01T00:00:00Z",
    inactiveDate: "2023-12-31T23:59:59Z",
    partnerCodeGroupStatus: "Inactive",
    codeUploadStatus: "Pending",
  },
  {
    id: "2",
    title: "Code Group 2",
    description: "Description for Code Group 2",
    totalCodes: 2000,
    exhaustedCodes: 1500,
    activeDate: "2023-01-01T00:00:00Z",
    inactiveDate: "2023-12-31T23:59:59Z",
    partnerCodeGroupStatus: "Inactive",
    codeUploadStatus: "Code Imported",
  },
  {
    id: "2",
    title: "Code Group 2",
    description: "Description for Code Group 2",
    totalCodes: 2000,
    exhaustedCodes: 1500,
    activeDate: "2023-01-01T00:00:00Z",
    inactiveDate: "2023-12-31T23:59:59Z",
    partnerCodeGroupStatus: "Inactive",
    codeUploadStatus: "Code Imported",
  },
  // Add more data as needed
];

const PartnerCodeGroupTab = () => {
  const [viewType, setViewType] = useState("grid");
  const [mainContentY, setMainContentY] = useState(0);

  const handleResize = () => {
    setMainContentY(
      Math.ceil(
        document.getElementsByClassName("main-content")[0].getBoundingClientRect()
          .top
      )
    );
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="page-content">
      <div className="view-filter">
        <div className="search-filter-container">
          <InputItem
            placeholder="Search by title"
            trailingIcon="search"
            size="medium"
            className="input-search"
          />
        </div>
        <ButtonGroup
          items={[
            {
              icon: "view_module",
              onClick: () => {
                setViewType("grid");
              },
            },
            {
              icon: "view_list",
              onClick: () => {
                setViewType("list");
              },
            },
          ]}
          variant="toggle"
          selectedIndex={viewType === "grid" ? 0 : 1}
        />
      </div>
      <div
        className="main-content"
        style={{
          flex: 20,
          overflowY: "auto",
          maxHeight: `calc(100vh - ${mainContentY}px)`,
        }}
      >
        {viewType === "grid" ? (
          <div className="grid-container" style={{ flex: 20, overflowY: "auto" }}>
            {partnerCodeGroupData.map((group, index) => (
              <div className="grid-item grid-item-3 grid-item-4" key={index}>
                <PartnerCodeGroupCard key={index} {...group} />
              </div>
            ))}
          </div>
        ) : (
          <PartnerCodeGroupTable partnerCodeGroupData={partnerCodeGroupData} />
        )}
      </div>
    </div>
  );
};

export default PartnerCodeGroupTab;
