import { Checkbox } from "pepsico-ds";

const TableView = ({
  promoProductList,
  selectedPromoProducts,
  onSelectPromoProduct,
}) => {
  return (
    <div className="promoProducts-table p-0">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>PRODUCT NAME</th>
            <th>CATEGORY</th>
          </tr>
        </thead>
        <tbody>
          {promoProductList?.length ? (
            promoProductList?.map((promoProduct) => (
              <tr key={promoProduct.id}>
                <td className="td-checkbox">
                  <Checkbox
                    id={`checkbox-${promoProduct.id}`}
                    onUpdate={() => onSelectPromoProduct(promoProduct)}
                    checked={selectedPromoProducts?.some(
                      (row) => row.id === promoProduct.id
                    )}
                  />
                </td>
                <td>{promoProduct.title}</td>
                <td>{promoProduct.contentData?.category || ""}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="no-data">
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableView;
