import { Slider } from "pepsico-ds";

export default function SliderField(props) {
  const {
    value,
    updateValue,
    label,
    min,
    max,
    isShowMax,
    isShowMin,
    disabled,
    error,
  } = props;

  return (
    <>
      <Slider
        label={label}
        value={value}
        onUpdate={updateValue}
        min={min}
        max={max}
        showMax={isShowMax}
        showMin={isShowMin}
        step={1}
        disabled={disabled}
        error={error}
      />
    </>
  );
}
