import PropTypes from "prop-types";
import React from "react";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import { partnersActionButtons } from "../partnersActionButton";
import "./PartnerCodeGroupTable.scss";

const PartnerCodeGroupTable = ({ partnerCodeGroupData }) => {
  return (
    <div className="widget-list-container">
      <table className="widget-list-table">
        <thead>
          <tr>
            <th className="column-status">Status</th>
            <th className="column-title">Title</th>
            <th className="column-description">Description</th>
            <th className="column-total-codes">Total Codes</th>
            <th className="column-exhausted-codes">Exhausted Codes</th>
            <th className="column-active-date">Active Date</th>
            <th className="column-inactive-date">Inactive Date</th>
            <th className="column-code-upload-status">Code Upload Status</th>
            <th className="column-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {partnerCodeGroupData.map((item, index) => (
            <tr key={index}>
              <td className="widget-status-type">
                <span
                  className={
                    item.partnerCodeGroupStatus.toLowerCase() === "active"
                      ? "status active"
                      : "status inactive"
                  }
                >
                  {item.partnerCodeGroupStatus}
                </span>
              </td>
              <td className={`${/\s/.test(item.title) ? "" : "no-space-title"}`}>
                {item.title || "-"}
              </td>
              <td>{item.description}</td>
              <td>{item.totalCodes}</td>
              <td>{item.exhaustedCodes}</td>
              <td>{new Date(item.activeDate).toLocaleDateString()}</td>
              <td>{new Date(item.inactiveDate).toLocaleDateString()}</td>
              <td>{item.codeUploadStatus}</td>
              <td>
                <div className="button-group-wrapper">
                  <ButtonGroupDynamic
                    buttons={partnersActionButtons(
                      item.partnerCodeGroupStatus === "Active"
                    )}
                    spacing="0px"
                    size="medium"
                    variant="control"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

PartnerCodeGroupTable.propTypes = {
  partnerCodeGroupData: PropTypes.arrayOf(
    PropTypes.shape({
      partnerCodeGroupStatus: PropTypes.string.isRequired,
      title: PropTypes.string,
      description: PropTypes.string,
      totalCodes: PropTypes.number.isRequired,
      exhaustedCodes: PropTypes.number.isRequired,
      activeDate: PropTypes.string.isRequired,
      inactiveDate: PropTypes.string.isRequired,
      codeUploadStatus: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default PartnerCodeGroupTable;
