import { File } from "pepsico-ds";
import { FeatureToggle, isFeatureEnabled } from "../../../utils/featureToggle";
import ImagePicker from "../../imagePicker/ImagePicker";

export default function FileUploadField({
  value,
  updateValue,
  label,
  uiSchema,
  className,
  acceptedExtensions,
  disabled = false,
  maxSize,
}) {
  return (
    <>
      {isFeatureEnabled(FeatureToggle.newFileUploader) ? (
        <ImagePicker
          accept={acceptedExtensions}
          description={uiSchema.description}
          files={value || []}
          onUpdate={updateValue}
          label={label}
          multiple
          classNameProp={className}
          maxSize={maxSize}
          disabled={disabled}
        />
      ) : (
        <File
          className={className}
          style={{ width: "100%" }}
          accept={acceptedExtensions}
          description={uiSchema.description}
          label={label}
          variant="default"
          files={value || []}
          onUpdate={updateValue}
          multiple
          disabled={disabled}
        />
      )}
    </>
  );
}
