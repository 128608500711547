import { Outlet } from "react-router-dom";
import { ListingComponentProvider } from "../../components/common/listingComponents/ListingComponentContext";
import { getContentList } from "../../graphql/queries/contentQueries";

const ContentOutlet = () => {
  return (
    <ListingComponentProvider
      query={getContentList}
      initialFilterData={{ contentDefinitionSlug: "page-content" }}
      queryKey="listContent"
    >
      <Outlet />
    </ListingComponentProvider>
  );
};

export default ContentOutlet;
