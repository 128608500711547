import { useMutation } from "@apollo/client";
import { Chip } from "@mui/material";
import { Breadcrumb, Button } from "pepsico-ds";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TextAreaField from "../../components/jsonForms/textarea/TextAreaField";
import { paths } from "../../routes/paths";
import saveBanner from "../banners/graphQl/mutations/saveBanner";
import "./../../styles/common.scss";
import "./ManagementPage.scss";

export default function TermAndConditionEditPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const dataReceived = location.state;
  const [error, setError] = useState(null);
  const [saveBannerMutation] = useMutation(saveBanner);

  const [state, setState] = useState({
    content: {
      id: dataReceived?.id,
      title: dataReceived?.title,
      description: dataReceived?.description,
      activeStartDate: dataReceived?.activeStartDate,
      segments: "",
      tags: [],
      category: "",
      paused: false,
      contentDefinitionSlug: "terms-and-conditions-content",
      contentData: dataReceived?.contentData,
    },
  });

  const onChange = (value) => {
    const trimmedValue = value.trim();
    if (trimmedValue?.length > 0) {
      setError(null);
    } else {
      setError("Please enter Terms and Conditions");
    }
    setState((prevState) => ({
      ...prevState,
      content: {
        ...prevState.content,
        contentData: {
          ...prevState.content.contentData,
          text: value,
        },
      },
    }));
  };

  const onCLickUpdate = async () => {
    try {
      const params = {
        variables: state,
      };
      const save = await saveBannerMutation(params);
      if (save?.data) {
        navigate(`/${paths.system_setting}/${paths.term_and_condition}`);
      }
    } catch (err) {
      alert(err);
    }
  };

  const onCLickClose = () => {
    navigate(`/${paths.system_setting}/${paths.term_and_condition}`);
  };
  return (
    <div className="container">
      <div className="wrap">
        <Breadcrumb
          breadcrumbs={[
            {
              label: "System Setting",
              href: `../../${paths.system_setting}`,
            },
            {
              label: "Terms and Conditions",
              href: `../../${paths.system_setting}/${paths.term_and_condition}`,
            },
            { label: `Edit ${dataReceived?.contentData?.title}` },
          ]}
          activeIndex={2}
        />
        {dataReceived?.contentData?.is_required && (
          <Chip
            sx={{ backgroundColor: "#AAD2E2", width: "150px" }}
            size="small"
            label="Required to accept"
          />
        )}
        <h2>{dataReceived?.contentData?.title}</h2>
        <p>
          {error && <span style={{ color: "red" }}>{error}</span>}
          <TextAreaField
            value={state?.content?.contentData?.text ?? ""}
            updateValue={(newVal) => onChange(newVal)}
            placeholder="Type here"
            required={true}
          />
        </p>
        <hr className="custom-hr" />
        <div className="button-container">
          <Button
            size="medium"
            text="Discard"
            variant="secondary"
            style={{ marginRight: "10px" }}
            onClick={onCLickClose}
          />
          <Button
            size="medium"
            text="Update"
            variant="primary"
            disabled={error}
            onClick={onCLickUpdate}
          />
        </div>
      </div>
    </div>
  );
}
