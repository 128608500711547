import { Card } from "pepsico-ds";
import PropTypes from "prop-types";
import { getDateWithFormat } from "../../../utils/commons";
import { codeGroupActionButton } from "../../codeGroups/codeGroupActionButton";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import "./partnerCodeGroupCard.scss";

const PartnerCodeGroupCard = ({
  title,
  totalCodes,
  exhaustedCodes,
  activeDate,
  inactiveDate,
  partnerCodeGroupStatus,
  codeUploadStatus,
  currentProgramTimezone,
}) => {
  return (
    <Card
      icon=""
      ellipsis=""
      style={{
        padding: "0px",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
      }}
    >
      <div className="partner-code-group-card-container">
        <div className="partner-code-group-details">
          <div className="partner-code-group-status-type">
            <span className={`status ${partnerCodeGroupStatus?.toLowerCase()}`}>
              {partnerCodeGroupStatus ? partnerCodeGroupStatus : ""}
            </span>
            <span
              className={`code-upload-status ${codeUploadStatus?.toLowerCase().replace(/\s/g, "-")}`}
            >
              {codeUploadStatus ? codeUploadStatus : ""}
            </span>
          </div>
          <h3 className="partner-code-group-name">{title}</h3>
          <div className="additional-fields-container">
            <div className="field-pair">
              <span className="field-label">Total Codes</span>
              <span className="field-value">{totalCodes}</span>
            </div>
            <div className="field-pair">
              <span className="field-label">Exhausted Codes</span>
              <span className="field-value">{exhaustedCodes}</span>
            </div>
          </div>

          <div className="partner-code-group-dates">
            <div className="date-item">
              <span className="date-label">Active Date</span>
              <span>{getDateWithFormat(activeDate, currentProgramTimezone)}</span>
            </div>
            <div className="date-item">
              <span className="date-label">Inactive Date</span>
              <span>{getDateWithFormat(inactiveDate, currentProgramTimezone)}</span>
            </div>
          </div>
          <div className="button-group-container">
            <ButtonGroupDynamic
              buttons={codeGroupActionButton(partnerCodeGroupStatus.toLowerCase())}
              spacing="1px"
              size="medium"
              variant="control"
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

PartnerCodeGroupCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  totalCodes: PropTypes.number,
  exhaustedCodes: PropTypes.number,
  activeDate: PropTypes.string,
  inactiveDate: PropTypes.string,
  partnerCodeGroupStatus: PropTypes.string,
  codeUploadStatus: PropTypes.string,
  currentProgramTimezone: PropTypes.string,
};

export default PartnerCodeGroupCard;
