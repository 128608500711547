import { useMutation } from "@apollo/client";
import { withJsonFormsControlProps } from "@jsonforms/react";
import { CircularProgress } from "@mui/material";
import { Button, File } from "pepsico-ds";
import PropTypes from "prop-types"; // Import PropTypes library
import { useEffect, useState } from "react";
import createAsset from "../../../graphql/mutations/createAsset";

const MultipleExcelUploadControl = (props) => {
  const { handleChange, label, path, uischema, required } = props;

  const [files, setFiles] = useState([]);
  const [loader, setLoader] = useState(false);
  const [createAssetMutation] = useMutation(createAsset);
  const [error, setError] = useState(null);

  const acceptedExtensions = uischema.accept || ".jpeg,.jpg,.png";
  const variant = uischema.options?.variant || "edit";

  const handleFileUpload = (newFiles) => {
    const validFiles = validateFiles(newFiles);
    if (validFiles.length > 0) {
      readFiles(validFiles);
    }
  };

  const validateFiles = (newFiles) => {
    const filesArray = Array.isArray(newFiles) ? newFiles : [newFiles];
    const validFiles = [];

    for (const file of filesArray) {
      const fileExtension = file.name.split(".").pop();
      const hasIncludeExt = acceptedExtensions.includes(`.${fileExtension}`);
      if (!hasIncludeExt) {
        setError("Invalid file type");
        return [];
      }
      setError(null);
      validFiles.push(file);
    }

    return validFiles;
  };

  const readFiles = (validFiles) => {
    validFiles.forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = handleFileRead(reader, file);
    });
  };

  const handleFileRead = (reader, file) => () => {
    setFiles((prevFiles) => [
      ...prevFiles,
      { base64: reader.result, name: file.name },
    ]);
  };

  const callCreateAsset = async () => {
    setLoader(true);

    try {
      const results = await Promise.all(files.map(uploadFile));
      handleUploadResults(results);
    } catch (error) {
      console.error(error);
      setError("Something went wrong");
    } finally {
      setLoader(false);
    }
  };

  const uploadFile = (file) => {
    const params = {
      variables: {
        Asset: { data: file?.base64?.split(",")[1] },
      },
    };
    return createAssetMutation(params);
  };

  const handleUploadResults = (results) => {
    setLoader(false);
    const urls = results.map((result) => result?.data?.createAsset?.url);
    if (urls.every((url) => url)) {
      handleChange(path, urls);
    } else {
      setError("Failed to upload some files");
    }
  };

  useEffect(() => {
    if (files.length > 0) {
      callCreateAsset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <>
      <div className="fileUploadHeader">
        <span className="label-text $font-xsmall-regular">
          <label>{label}</label>
        </span>
        {required && (
          <span className="required-text $font-xxsmall-bold">*Required</span>
        )}
      </div>
      {loader && <CircularProgress />}
      {error && (
        <div className="display-flex gap-2 align-items-center mt-2">
          <span style={{ color: "red", fontSize: "14px" }}>{error}</span>
          <Button variant="borderless" onClick={callCreateAsset}>
            Try again
          </Button>
        </div>
      )}
      <File
        className="fileUploader"
        accept={acceptedExtensions}
        label={label}
        variant={variant}
        multiple
        onUpdate={handleFileUpload}
      />
    </>
  );
};

MultipleExcelUploadControl.propTypes = {
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  //data: PropTypes.object,
  path: PropTypes.string,
  //errors: PropTypes.array,
  //schema: PropTypes.object,
  uischema: PropTypes.object,
  required: PropTypes.bool,
};

export default withJsonFormsControlProps(MultipleExcelUploadControl);
