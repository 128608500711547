import { Modal, Dropdown, Toggle } from "pepsico-ds";
import "./rewards-modal.scss";
import EmptyImage from "../../../assets/widget/create_new_widget_empty.svg";
import { useState } from "react";
import JsonFormsWrapper from "../../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import { defaultRewardJsonSchema, rewardSchemas } from "./schemas";
import { createAjv } from "@jsonforms/core";
import { categoryOptions, subtypesOptions, typesOptions } from "./constants";

const CreateRewardsModal = ({
  createRewardModalOpen,
  toggleModalNewReward,
}) => {
  const [rewardType, setRewardType] = useState("");
  const [rewardSubtype, setRewardSubtype] = useState();
  const [rewardCategory, setRewardCategory] = useState([]);
  const [subtypeOptions, setSubtypeOptions] = useState([]);
  const [basicSetupData, setBasicSetupData] = useState({});

  const ajv = createAjv();
  ajv.validate(defaultRewardJsonSchema, {});
  const initialErrors = ajv.errors;
  const [basicSetupErrors, setBasicSetupErrors] = useState(initialErrors);

  const isSetupCompleted =
    !basicSetupErrors.length &&
    rewardType?.length &&
    rewardSubtype?.[0].id.length &&
    rewardCategory?.length;

  const updateRewardType = (val) => {
    setRewardType(val);
    setRewardSubtype([{ id: "", displayText: "" }]);
    val?.[0].id === "zynga" &&
      setBasicSetupData((data) => ({ ...data, rewardParameters: [{}] }));
    if (val) {
      setSubtypeOptions(subtypesOptions[val[0].id]);
      subtypesOptions[val[0].id].length === 1 &&
        setRewardSubtype(subtypesOptions[val[0].id]);
      return;
    }
    setSubtypeOptions([]);
  };

  const updateRewardCategory = (val) => {
    setRewardCategory(val ? [...val] : val);
  };

  const handleBasicSetupChange = (data, errors) => {
    setBasicSetupData(data);
    setBasicSetupErrors(errors);
  };

  return (
    <Modal
      className="reward-modal"
      showModal={createRewardModalOpen}
      onCloseModal={toggleModalNewReward}
      title={"Create Reward"}
      primaryButtonProps={{
        size: "medium",
        text: "Create",
        variant: "primary",
        disabled: !isSetupCompleted,
        onClick: toggleModalNewReward,
      }}
      showSecondaryButton={false}
      showTertiaryButton={false}
      showLink={false}
    >
      <h4 className="required-fields-label text-right">*Required fields</h4>
      <div className="reward">
        <div className="reward-wrap">
          <div className="reward-type-setup">
            <span className="reward-type-setup-label font-bold">
              Reward Type
            </span>
            <div className="reward-type-setup-fields">
              <div className="reward-type-setup-field">
                <Dropdown
                  childList={typesOptions}
                  label="Reward Type*"
                  selection="single"
                  setSelectedValue={updateRewardType}
                  size="medium"
                />
              </div>
              <div className="reward-type-setup-field">
                <Dropdown
                  childList={subtypeOptions}
                  selectedValue={rewardSubtype}
                  label="Reward Subtype*"
                  setSelectedValue={setRewardSubtype}
                  selection="single"
                  size="medium"
                />
              </div>
              <div className="reward-type-setup-field">
                <Dropdown
                  childList={categoryOptions}
                  selectedValue={rewardCategory}
                  label="Reward Category*"
                  setSelectedValue={updateRewardCategory}
                  selection="multiple"
                  size="medium"
                />
              </div>
            </div>
          </div>
          {rewardType?.length ? (
            <div className="reward-basic-setup">
              <JsonFormsWrapper
                jsonschema={rewardSchemas[rewardType[0].id].jsonSchema}
                uischema={rewardSchemas[rewardType[0].id].uiSchema}
                data={basicSetupData}
                onChange={handleBasicSetupChange}
              />
            </div>
          ) : (
            <div className="no-reward-type">
              <img src={EmptyImage} alt="No Completed Sweepstakes" />
              <h5>Select your type of reward to start</h5>
            </div>
          )}
        </div>
      </div>
      <Toggle
        label="Active"
        onUpdate={() => {}}
        size="medium"
        checked={true}
        disabled={!isSetupCompleted}
      />
    </Modal>
  );
};

export default CreateRewardsModal;
