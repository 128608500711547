import { useQuery } from "@apollo/client";
import { Grid } from "@mui/material";
import { Card, InputItem, LoadingSpinner } from "pepsico-ds";
import { listRewards } from "../../graphql/queries/listRewards";
import { CustomFilter } from "../common/customFilter/CustomFilter";
import { SingleBorderCard } from "../common/SingleBorderCard";
import SelectedRewards from "./SelectedRewards";

// const rewardsList = [
//   { img: "/assets/images/reward1.png", title: "Reward 1", id: 0 },
//   { img: "/assets/images/reward2.png", title: "Reward 2", id: 1 },
//   { img: "/assets/images/reward3.png", title: "Reward 3", id: 2 },
//   { img: "/assets/images/reward4.png", title: "Reward 4", id: 3 },
//   { img: "/assets/images/reward5.png", title: "Reward 5", id: 4 },
//   { img: "/assets/images/reward6.png", title: "Reward 6", id: 5 },
// ];

export default function AddRewards({ value = [], updateValue, type }) {
  const { data, loading } = useQuery(listRewards);

  const onRewardSelect = (reward) => {
    const isSelected = value.find((item) => item.rewardId === reward.id);
    if (isSelected) {
      updateValue(value.filter((item) => item.rewardId !== reward.id));
    } else {
      updateValue([...value, { rewardId: reward.id, name: reward.name }]);
    }
  };

  const onRemoveSelectedReward = (reward) => {
    updateValue(value.filter((item) => item.rewardId !== reward.rewardId));
  };

  const updateRewardData = (reward) => {
    updateValue(
      value?.map((item) => {
        if (item.rewardId === reward.rewardId) {
          return { ...reward };
        }
        return item;
      })
    );
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const newValue = Array.from(value);
    const [draggedItem] = newValue.splice(result.source.index, 1);
    newValue.splice(result.destination.index, 0, draggedItem);
    updateValue(newValue);
  }

  return (
    <div className="mt-2">
      <Grid container columnSpacing={2}>
        <Grid item xs={3}>
          <Card
            icon=""
            ellipsis=""
            style={{
              // padding: "0px",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              width: "100%",
              height: "100%",
            }}
          >
            <h4 className="font-md">Find Reward</h4>
            <InputItem
              placeholder="Search by keyword"
              trailingIcon="search"
              size="medium"
              style={{
                borderRadius: 0,
                border: "none",
                borderBottom: "1px solid #c0c0c0",
              }}
            />
            <CustomFilter
              options={[
                { id: "Zynga", label: "Zynga" },
                { id: "4play", label: "4play" },
                { id: "Sodexo", label: "Sodexo" },
                { id: "Hopi Coins", label: "Hopi Coins" },
              ]}
              label="Reward type"
            />
            <CustomFilter
              options={[
                { id: "Zynga", label: "Zynga" },
                { id: "4play", label: "4play" },
                { id: "Sodexo", label: "Sodexo" },
                { id: "Hopi Coins", label: "Hopi Coins" },
                { id: "Hopi Coins", label: "Hopi Coins" },
                { id: "Hopi Coins", label: "Hopi Coins" },
                { id: "Hopi Coins", label: "Hopi Coins" },
              ]}
              label="Reward sub-type"
            />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={1}>
            {loading && (
              <Grid item xs={12}>
                <LoadingSpinner
                  size={{
                    height: "40px",
                    width: "40px",
                  }}
                />
              </Grid>
            )}

            {!loading && data?.listRewards?.length === 0 && (
              <Grid item xs={12}>
                <h4 className="font-md">No rewards found</h4>
              </Grid>
            )}

            {!loading &&
              data?.listRewards?.length > 0 &&
              data?.listRewards?.map((reward) => (
                <Grid
                  item
                  xs={6}
                  md={4}
                  // lg={3}
                  xl={3}
                  className="cursor-pointer"
                  key={reward.id}
                  onClick={() => onRewardSelect(reward)}
                >
                  <SingleBorderCard>
                    <img
                      src={reward.imageUrl}
                      height={200}
                      style={{
                        borderRadius: "6px",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                    <h4
                      className="font-sm text-wrap text-ellipsis-2"
                      style={{ minHeight: "35px" }}
                      title={reward.name}
                    >
                      {reward.name}
                    </h4>
                    {!!value.find((item) => {
                      return item.rewardId === reward.id;
                    }) && (
                      <img
                        src={"/assets/images/checkmark.png"}
                        height={24}
                        width={24}
                        style={{ position: "absolute", right: 10, top: 10 }}
                      />
                    )}
                  </SingleBorderCard>
                </Grid>
              ))}
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <SelectedRewards
            value={value}
            onRewardSelect={onRemoveSelectedReward}
            updateRewardData={updateRewardData}
            type={type}
            listRewards={data?.listRewards || []}
            handleOnDragEnd={handleOnDragEnd}
          />
        </Grid>
      </Grid>
    </div>
  );
}
