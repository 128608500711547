import { JsonFormsDispatch, withJsonFormsLayoutProps } from "@jsonforms/react";
import "./styles.scss";

const CustomLayoutRenderer = ({
  schema,
  uischema,
  path,
  renderers,
  cells,
  enabled,
  direction = "column",
  gap = "normal",
  visible,
  label = "",
  labelStyles = {} /* Customise label styles using uiSchema */,
  layoutStyles = {} /* Customise layout styles using uiSchema */,
}) => {
  return (
    <>
      {label ? (
        <div
          className={`font-xs label-title-render ${uischema.label_classname ?? ""}`}
          style={{ color: "rgb(97,97,97)", ...labelStyles }}
        >
          {label}
        </div>
      ) : (
        <></>
      )}
      <div
        className={`${direction === "column" ? "vertical-layout" : "horizontal-layout"} ${gap === "normal" ? "gap-normal" : "gap-simple"} ${uischema.className ?? ""} ${uischema.class_name ?? ""}`}
        style={{ ...layoutStyles }}
      >
        {visible &&
          uischema.elements.map((child, index) => (
            <div
              className={`${direction === "column" ? "vertical-layoutItem-item" : "horizontal-layoutItem-item"} horizontal-layoutItem-${index}`}
              style={{ ...child.layoutItemStyles }}
              key={`${path}-${index}`}
            >
              <JsonFormsDispatch
                schema={schema}
                uischema={child}
                path={path}
                enabled={enabled}
                renderers={renderers}
                cells={cells}
                key={index}
              />
            </div>
          ))}
      </div>
    </>
  );
};
export default withJsonFormsLayoutProps(CustomLayoutRenderer);
