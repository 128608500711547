const languageMappings = {
  "es-mx": "Spanish",
  "en-us": "English",
  "fr-ca": "French",
};

const LocalizationList = ({ data }) => {
  return (
    <div>
      {Object.entries(data.l10n).map(([lang, content]) => {
        const language = languageMappings[lang] || lang; // Fallback to lang if mapping not found

        return (
          <div key={lang} className="additional-fields-container">
            <div className="field-pair">
              <span className="field-label">{language} title:</span>
            </div>
            <div className="field-pair-two-column">
              <span className="field-value">{content.title}</span>
            </div>
            <div className="field-pair">
              <span className="field-label">{language} description:</span>
            </div>
            <div className="field-pair-two-column">
              <span className="field-value">{content.description}</span>
            </div>
            {content.link && (
              <>
                <div className="field-pair">
                  <span className="field-label">{language} link:</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">{content.link}</span>
                </div>
              </>
            )}
            {content.redirect_link && (
              <>
                <div className="field-pair">
                  <span className="field-label">{language} redirect link:</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">{content.redirect_link}</span>
                </div>
              </>
            )}
            {content.survey_link && (
              <>
                <div className="field-pair">
                  <span className="field-label">{language} survey link:</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">{content.survey_link}</span>
                </div>
              </>
            )}
            {content.terms_and_conditions && (
              <>
                <div className="field-pair">
                  <span className="field-label">
                    {language} terms and conditions link:
                  </span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">
                    <a href={content.terms_and_conditions} target="_blank">
                      {content.terms_and_conditions}
                    </a>
                  </span>
                </div>
              </>
            )}
            <div className="field-pair">
              <span className="field-label">{language} Image:</span>
            </div>
            <div className="field-pair-two-column">
              <img
                src={content.campaign_image_url}
                alt={content.title}
                style={{ width: "120px", borderRadius: "8px" }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LocalizationList;
