import { Toggle } from "pepsico-ds";
import "./toggleStyles.scss";

export default function SwitchField(props) {
  const { value, updateValue, label, schema, uiSchema, disabled } = props;

  return (
    <Toggle
      className="toggleStyles"
      label={label}
      onUpdate={updateValue}
      size={uiSchema.size || "medium"}
      checked={value ?? schema.default}
      disabled={disabled}
    />
  );
}
