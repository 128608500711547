import {
  composePaths,
  createDefaultValue,
  isObjectArrayWithNesting,
  rankWith,
} from "@jsonforms/core";
import { withJsonFormsArrayLayoutProps } from "@jsonforms/react";
import _isEqual from "lodash/isEqual";
import React, { useCallback } from "react";
import CardRenderer from "./CardRenderer";
import { Stack, Typography } from "@mui/material";
import { Button } from "pepsico-ds";

export const ArrayLayoutRenderer = (props) => {
  const {
    uischema,
    schema,
    label,
    renderers,
    cells,
    data,
    path,
    addItem,
    removeItems,
  } = props;
  const addItemCb = useCallback((p, value) => addItem(p, value), [addItem]);

  const removeItemsCb = useCallback(
    (path, toDelete) => (removeItems ? removeItems(path, toDelete) : () => {}),
    [removeItems]
  );

  const toRender = Array(data)
    .fill(0)
    .map((_, i) => (
      <CardRenderer
        key={i}
        index={i}
        schema={schema}
        uischema={uischema}
        path={composePaths(path, `${i}`)}
        renderers={renderers}
        cells={cells}
        onRemove={removeItemsCb(path, [i])}
        label={label}
      />
    ));
  return (
    <div>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mt={2}
      >
        <Typography color="black" fontWeight="medium" fontSize="12px">
          {label}
        </Typography>
        <Button size="small" onClick={addItemCb(path, createDefaultValue(schema))}>
          Add {label}
        </Button>
      </Stack>
      {data > 0 ? toRender : <Typography>No {label} added</Typography>}
    </div>
  );
};

export const arrayLayoutTester = rankWith(5, isObjectArrayWithNesting);
export default React.memo(
  withJsonFormsArrayLayoutProps(ArrayLayoutRenderer),
  (prevProps, props) => _isEqual(prevProps, props)
);
