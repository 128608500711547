import { ButtonGroup } from "pepsico-ds";

const ButtonGroupDynamic = ({ buttons, selectedIndex, size, spacing, variant }) => {
  return (
    <ButtonGroup
      items={buttons?.map((button) => ({
        label: button?.label,
        icon: button?.icon,
        onClick: button?.onClick,
      }))}
      selectedIndex={selectedIndex}
      size={size}
      spacing={spacing}
      variant={variant}
    />
  );
};

export default ButtonGroupDynamic;
