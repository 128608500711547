import { CircularProgress } from "@mui/material";
import { ButtonGroup, InputItem } from "pepsico-ds";
import { useCallback, useEffect, useState } from "react";
import JsonFormsWrapper from "../../components/jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import { useSegments } from "../../hooks/useSegments";
import {
  segmentationFilterJsonSchema,
  segmentationFilterUISchema,
} from "../../pages/organizations/mocks";
import { debounceFn } from "../../utils/commons";
import RangeSliderControl from "../jsonForms/rangeSliderMUI/RangeSliderControl";
import SegmentCard from "./SegmentCard/SegmentCard";
import SegmentListTable from "./SegmentListTable/SegmentListTable";
import "./segmentation.scss";

const SegmentsListTab = () => {
  const [viewType, setViewType] = useState("grid");
  const [mainContentY, setMainContentY] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [filters, setFilters] = useState({
    populationFrom: null,
    populationTo: null,
    status: null,
    keyword: null,
  });
  const filter = {
    status: filters.status || null,
    keyword: filters.keyword || null,
    population:
      filters.populationFrom !== null && filters.populationTo !== null
        ? {
            from: filters.populationFrom,
            to: filters.populationTo,
          }
        : null,
  };

  const { segments, loading, error } = useSegments(filter);

  const handleResize = () => {
    setMainContentY(
      Math.ceil(
        document.getElementsByClassName("main-content")[0].getBoundingClientRect()
          .top
      )
    );
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const debouncedPopulationChange = useCallback(
    debounceFn((newPopulation) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        populationFrom: newPopulation.from,
        populationTo: newPopulation.to,
      }));
    }, 500),
    []
  );

  const debouncedSearchChange = useCallback(
    debounceFn((value) => {
      setFilters((prevFilters) => ({
        ...prevFilters,
        keyword: value,
      }));
    }, 1500),
    []
  );

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchInput(value);
    debouncedSearchChange(value);
  };

  const handleFilterChange = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
    }));
  };

  const handlePopulationChange = (newPopulation) => {
    if (
      newPopulation.from === filters.populationFrom &&
      newPopulation.to === filters.populationTo
    ) {
      // Don't update filters if the value hasn't changed
      return;
    }
    debouncedPopulationChange(newPopulation);
  };

  const handleClearFilters = () => {
    setFilters({
      populationFrom: null,
      populationTo: null,
      status: null,
      keyword: null,
    });
    setSearchInput("");
  };
  return (
    <div className="page-content">
      <div className="view-filter">
        <div className="segment-filters-container">
          <InputItem
            placeholder="Search by segment name"
            trailingIcon="search"
            size="medium"
            className="input-search"
            onChange={handleSearchChange}
            value={searchInput}
          />

          <JsonFormsWrapper
            jsonschema={segmentationFilterJsonSchema}
            uischema={segmentationFilterUISchema}
            data={filters}
            onChange={handleFilterChange}
          />

          <RangeSliderControl
            data={{ from: filters.populationFrom, to: filters.populationTo }}
            handleChange={handlePopulationChange}
          />

          <p className="clear-all-button" onClick={handleClearFilters}>
            Clear Filter
          </p>
        </div>
        <ButtonGroup
          items={[
            {
              icon: "view_module",
              onClick: () => {
                setViewType("grid");
              },
            },
            {
              icon: "view_list",
              onClick: () => {
                setViewType("list");
              },
            },
          ]}
          variant="toggle"
          selectedIndex={viewType === "grid" ? 0 : 1}
        />
      </div>
      <div
        className="main-content"
        style={{
          flex: 20,
          overflowY: "auto",
          maxHeight: `calc(100vh - ${mainContentY}px)`,
        }}
      >
        {loading ? (
          <div className="segment-loading">
            <CircularProgress />
          </div>
        ) : segments.length === 0 || error ? (
          <div className="no-record-found">No Records Found.</div>
        ) : viewType === "grid" ? (
          <div className="grid-container" style={{ flex: 20, overflowY: "auto" }}>
            {segments.map((segment, index) => (
              <div className="grid-item grid-item-3 grid-item-4" key={index}>
                <SegmentCard key={index} segment={segment} />
              </div>
            ))}
          </div>
        ) : (
          <SegmentListTable segmentData={segments} />
        )}
      </div>
    </div>
  );
};

export default SegmentsListTab;
