import { gql } from "@apollo/client";

export const LoadProgram = gql`
  query LoadProgram {
    loadProgram {
      id
      name
      description
      slug
      logo
      defaultValues
      tags {
        campaign
        content
      }
      localization {
        programTimezone
        countries {
          key
          value {
            languages
            currencies
            addressSchema
          }
        }
      }
      campaigns {
        key
        value {
          enabled
          label
          image
          categories
          requirement {
            requiredOutcomes
            requiredActivities
            requiredRules
            possibleOutcomes {
              name
              description
              inputSchema
              uiSchema
              requiredRules
              slug
              rules {
                id
                name
                hidden
                inputSchema
                ruleFunction
                maxAllowed
                slug
                uiSchema
              }
            }
            possibleActivities {
              id
              name
              description
              inputSchema
              uiSchema
              requiredRules
              requiredOutcomes
              slug
              maxAllowed
              rules {
                id
                name
                hidden
                inputSchema
                ruleFunction
                maxAllowed
                slug
                uiSchema
              }
              outcomes {
                id
                name
                slug
                description
                inputSchema
                uiSchema
                requiredRules
                rules {
                  id
                  name
                  hidden
                  inputSchema
                  ruleFunction
                  maxAllowed
                  slug
                  uiSchema
                }
              }
            }
            possibleRules {
              id
              name
              hidden
              inputSchema
              ruleFunction
              maxAllowed
              slug
              uiSchema
            }
          }
          campaignSetup {
            uiSchema
            inputSchema
          }
          contentSteps {
            id
            name
            slug
            version
            description
            active
            inputSchema
            uiSchema
          }
          steps {
            label
            description
            shortTile
            slug
            values
          }
        }
      }
      widgets {
        id
        embedCode
        displayInfo
        widgetDefinitionSlug
        title
        isActive
        widgetData
        selectedCampaignsSize
        createdAt
        updatedAt
      }
      widgetDefinitions {
        id
        title
        version
        slug
        inputSchema
        uiSchema
      }
    }
  }
`;
