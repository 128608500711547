import { LoginCallback } from "@okta/okta-react";
import { useRoutes } from "react-router-dom";
import { config } from "./config";
import SecuredRoutes from "./secure";

export default function Router() {
  return useRoutes([
    {
      path: window.REACT_APP_OKTA_REDIRECT_URI,
      element: <LoginCallback />,
    },
    {
      element: <SecuredRoutes />,
      children: [...config],
    },
  ]);
}
