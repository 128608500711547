import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { listSegments } from "../../../graphql/queries/listSegments";

const SegmentViewComponent = ({ segmentIds }) => {
  const { data: segmentsData } = useQuery(listSegments);
  const [selectedSegments, setSelectedSegments] = useState([]);

  useEffect(() => {
    if (segmentsData) {
      const initialSegments = segmentIds.map(
        (item) =>
          segmentsData.listSegments.find((segment) => segment.id === item) || {}
      );
      setSelectedSegments(initialSegments);
    }
  }, [segmentsData]);

  return (
    <>
      {selectedSegments && (
        <div className="additional-fields-container">
          <div className="field-pair">
            <span className="field-label">Segments</span>
          </div>
          <div className="field-pair-two-column">
            <span className="field-value">
              {selectedSegments?.map((segment) => segment.name).join(", ")}
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default SegmentViewComponent;
