import { gql } from '@apollo/client';

export const getContentList = gql`
    query list_content($filter: ContentFilter!) {
        listContent(filter: $filter) {
            id
            title
            slug
            segments
            description
            status
            paused
            activeStartDate
            activeEndDate
            contentData
            tags
            category
            createdAt
            updatedAt
            deletedAt
            contentDefinition {
                id
                name
                slug
                version
                description
                active
                inputSchema
                uiSchema
            }
        }
    }
`;

