import { Icon, TextInput, Tooltip } from "pepsico-ds";
import PropTypes from "prop-types";
import * as React from "react";
import "./text-input.scss";

export default function TextInputField(props) {
  const {
    value,
    updateValue,
    label,
    errors,
    schema,
    uiSchema,
    required,
    disabled,
    visible,
  } = props;

  const getUpdatedValue = (schema, val) => {
    if (schema.type === "number" || schema.type === "integer") {
      return val;
    }
    return val === "" ? "" : val;
  };

  const [isFocused, setIsFocused] = React.useState(false);
  const errMsg =
    schema.message && errors.indexOf("must match pattern") > -1
      ? schema.message
      : errors;
  return visible ? (
    <>
      <TextInput
        required={required}
        disabled={disabled || uiSchema.readOnly}
        maxLength={uiSchema.maxLength ?? ""}
        /* max={uiSchema.maxLength} */
        type={schema?.type === "integer" ? "number" : schema?.type || "text"}
        error={isFocused && errMsg ? errMsg : undefined}
        label={label}
        info={uiSchema?.info || ""}
        onUpdate={(val) => {
          if (schema.type === "number" || schema.type === "integer") {
            if (uiSchema.maxLength && val.length > uiSchema.maxLength) {
              return;
            }
            val = parseFloat(val);
            if (val < 0) {
              return;
            }
            if (Number.isNaN(val)) {
              val = "";
            }
          }
          const updatedValue = getUpdatedValue(schema, val);
          updateValue(updatedValue);
        }}
        onBlur={() => {
          const trimmedValue = typeof value === "string" ? value.trim() : value;
          updateValue(trimmedValue);
          !isFocused && setIsFocused(true);
        }}
        value={value === 0 ? value : value || ""}
        placeholderText={uiSchema.placeholder ?? ""}
        className="mb-2"
      />
      {uiSchema.helpText && (
        <div className="input-help-text">
          <Tooltip text={uiSchema.helpText} className="help-tooltip">
            <Icon icon="info" size="small" />
          </Tooltip>
          Help
        </div>
      )}
    </>
  ) : null;
}

TextInputField.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateValue: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  errors: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  schema: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string, // Fixed 'schema.message' validation
  }).isRequired,
  uiSchema: PropTypes.shape({
    readOnly: PropTypes.bool,
    maxLength: PropTypes.number,
    info: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
};

TextInputField.defaultProps = {
  value: "",
  errors: "",
  required: false,
  disabled: false,
  uiSchema: {},
};
