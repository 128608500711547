import { gql } from "@apollo/client";

export const listSegments = gql`
  query ListSegments($filter: SegmentFilter) {
    listSegments(filter: $filter) {
      id
      name
      population
      attributes
      attributeValues
      type
      rules
      status
    }
  }
`;
