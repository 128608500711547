import { useQuery } from "@apollo/client";
import Chip from "@mui/material/Chip";
import { Button, InputItem } from "pepsico-ds";
import React, { useEffect, useState } from "react";
import { listSegments } from "../../../graphql/queries/listSegments";
import { searchHandler } from "../../../utils/functions";
import BannerSegmentsTable from "./BannerSegmentsTable";

const BannerSegmentsSelect = ({ selectedIds, setSelectedIds, required }) => {
  const [segments, setSegments] = useState([]);
  const [selectedSegmentsList, setSelectedSegmentsList] = useState([]);
  const [segmentsList, setSegmentsList] = useState([]);
  const { data, loading } = useQuery(listSegments, {
    variables: {
      filter: {
        status: "ENABLED",
      },
    },
    fetchPolicy: "cache-first",
  });
  useEffect(() => {
    if (data?.listSegments.length) {
      setSegmentsList(data.listSegments);
      setSegments(data.listSegments); // Set initial segments list for searching
    }
  }, [data]);

  useEffect(() => {
    const selected = segmentsList.filter((segment) =>
      selectedIds.includes(segment.id)
    );
    setSelectedSegmentsList(selected);
  }, [segmentsList, selectedIds]);

  const onSelectSegment = (segment) => {
    console.log("Selecting segment:", segment.id);
    let selectedRows = [...selectedSegmentsList];
    const index = selectedRows.findIndex((item) => item.id === segment.id);
    if (index > -1) {
      selectedRows = selectedRows.filter((item) => item.id !== segment.id);
    } else {
      selectedRows.push(segment);
    }
    setSelectedSegmentsList(selectedRows);
    console.log(
      "Selected IDs:",
      selectedRows.map((row) => row.id)
    );
    setSelectedIds(selectedRows.map((row) => row.id));
  };

  const onRemoveSegment = (segment) => {
    const updatedList = selectedSegmentsList.filter(
      (item) => item.id !== segment.id
    );
    setSelectedSegmentsList(updatedList);
    setSelectedIds(updatedList.map((row) => row.id));
  };

  const handleSearch = (e) => {
    const filteredList = searchHandler(
      e.target.value,
      ["name", "attributes", "attributeValues"],
      segmentsList
    );
    setSegments(filteredList);
  };

  return (
    <div className="display-flex">
      <div style={{ width: "75%" }}>
        {/* Search Input */}
        <div className="segment-filters">
          <div className="segment-filters-header">
            <InputItem
              placeholder="Search by keyword"
              trailingIcon="search"
              size="medium"
              className="input-search"
              onChange={handleSearch}
            />
          </div>
        </div>

        {/* Segments Table Section */}
        <BannerSegmentsTable
          segmentsListData={segments}
          selectedSegmentsList={selectedSegmentsList}
          loading={loading}
          onSelectSegment={onSelectSegment}
        />
      </div>
      <div
        style={{
          width: "25%",
          padding: "0.5rem",
          backgroundColor: "#F3F7FD",
          marginLeft: "1%",
        }}
      >
        {/* Selected Segments Section */}
        <div className="display-flex justify-content-space-between align-items-center">
          <span style={{ fontWeight: "700" }}>
            {selectedSegmentsList?.length || 0} selected
          </span>
          <Button
            variant="borderless"
            onClick={() => {
              setSelectedSegmentsList([]);
              setSelectedIds([]);
            }}
            style={{ padding: "0px" }}
          >
            Remove all
          </Button>
        </div>
        <div className="display-flex gap-2 mt-16" style={{ flexWrap: "wrap" }}>
          {selectedSegmentsList?.map((segment, index) => (
            <Chip
              key={index}
              id={`segment-${segment?.name}`}
              label={segment.name}
              onDelete={() => onRemoveSegment(segment)}
              style={{
                borderRadius: "8px",
                backgroundColor: "#B3DACB",
                cursor: "pointer",
                marginBottom: "8px",
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
export default BannerSegmentsSelect;
