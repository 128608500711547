import { JsonFormsDispatch, withJsonFormsLayoutProps } from "@jsonforms/react";
import PropTypes from "prop-types";
import "./conditional-section-layout.scss";

const ConditionalSectionLayout = ({
  data,
  schema,
  uischema,
  path,
  renderers,
  cells,
  enabled,
  visible,
}) => {
  const controlField = uischema.headerControl.split("/").pop();
  const controlValue = data[controlField];
  const isNoFieldsValueSelected = uischema.noFieldsValues.includes(controlValue);
  const noFields = !controlValue || isNoFieldsValueSelected;

  const controlElement = uischema.elements.find(
    (element) => element.scope === uischema.headerControl
  );
  const conditionalSections = uischema.elements.filter(
    (element) => element.scope !== uischema.headerControl
  );

  return (
    <>
      {visible && (
        <div className="conditional-section">
          <div className="condition-control-header">
            <JsonFormsDispatch
              schema={schema}
              uischema={controlElement}
              path={path}
              enabled={enabled}
              renderers={renderers}
              cells={cells}
            />
            <div className="divider"></div>
          </div>
          <div
            className={`conditional-section-content ${noFields ? "no-fields" : ""}`}
          >
            {controlValue &&
              !isNoFieldsValueSelected &&
              conditionalSections.map((section, index) => (
                <JsonFormsDispatch
                  schema={schema}
                  uischema={section}
                  path={path}
                  enabled={enabled}
                  renderers={renderers}
                  cells={cells}
                  key={index}
                />
              ))}
            {noFields && (
              <span className="no-fields-label">
                {isNoFieldsValueSelected
                  ? uischema.noFieldsLabel
                  : uischema.noSelectionLabel}
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};

ConditionalSectionLayout.propTypes = {
  data: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  uischema: PropTypes.shape({
    headerControl: PropTypes.string.isRequired,
    noFieldsValues: PropTypes.array.isRequired,
    noFieldsLabel: PropTypes.string,
    noSelectionLabel: PropTypes.string,
    elements: PropTypes.arrayOf(
      PropTypes.shape({
        scope: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
  renderers: PropTypes.array,
  cells: PropTypes.array,
  enabled: PropTypes.bool,
  visible: PropTypes.bool,
};

export default withJsonFormsLayoutProps(ConditionalSectionLayout);
