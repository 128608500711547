import { withJsonFormsControlProps } from "@jsonforms/react";
import { getContentList } from "../../../graphql/queries/contentQueries";
import { ListingComponentProvider } from "../../common/listingComponents/ListingComponentContext";
import PromoProductSelector from "./PromoProductSelector";

const PromoProductSelectorControl = ({ data, path, handleChange, required }) => {
  return (
    <ListingComponentProvider
      query={getContentList}
      initialFilterData={{
        contentDefinitionSlug: "promotional-product-content",
      }}
      fetchPolicy="cache-first"
      queryKey="listContent"
    >
      <PromoProductSelector
        selectedProductIds={data}
        setSelectedProductIds={(newValue) => handleChange(path, newValue)}
        required={required}
      />
    </ListingComponentProvider>
  );
};

export default withJsonFormsControlProps(PromoProductSelectorControl);
