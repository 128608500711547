import { withJsonFormsControlProps } from "@jsonforms/react";
import SingleInputFieldArray from "./SingleInputFieldArray";

const SingleInputArrayControl = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
    enabled,
  } = props;
  return (
    <SingleInputFieldArray
      value={data || []}
      label={label}
      updateValue={(newVal) => handleChange(path, newVal)}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
      disabled={!enabled}
    />
  );
};

export default withJsonFormsControlProps(SingleInputArrayControl);
