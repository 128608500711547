import { TextField } from "@mui/material";
import { useState } from "react";
import "./timePicker.scss";

const CustomTimePicker = ({
  styles,
  label,
  value,
  setValue,
  minValue = "00",
  maxValue = "24",
  disabled,
  placeholder,
}) => {
  const [error, setError] = useState(false);
  const handleChange = (event) => {
    const intergerValue = parseInt(event.target.value);
    if (intergerValue < parseInt(minValue) || intergerValue > parseInt(maxValue)) {
      setError(true);
    } else {
      setError(false);
    }
    setValue(event.target.value);
  };

  const onBlur = (event) => {
    if (event.target.value == "") {
      setValue(maxValue);
    } else if (event.target.value.length === 1) {
      setValue(`0${event.target.value}`);
    }
  };
  return (
    <div
      className="p-2"
      style={{
        border: "1px solid #C0C0C0",
        borderRadius: "8px",
        ...styles,
      }}
    >
      <span style={{ fontSize: "12px", color: "#616161" }} className="font-md">
        {label}
      </span>
      <div className="display-flex mt-2 align-items-center">
        <TextField
          sx={{
            "& .MuiInputBase-root input": { padding: "8px !important" },
            width: "25%",
          }}
          error={error}
          value={value}
          onBlur={onBlur}
          placeholder={placeholder}
          onChange={handleChange}
          disabled={disabled}
        />
        <span style={{ marginLeft: "8px" }}>hours</span>
      </div>
    </div>
  );
};

export default CustomTimePicker;
