import { gql } from "@apollo/client";

export const listKeywordGroups = gql`
  query ListKeywordGroups($type: String) {
    listKeywordGroups(type: $type) {
      id
      name
      type
      slug
    }
  }
`;

export const matchKeywordGroupsList = gql`
  query ListKeywordGroups($type: String) {
    listKeywordGroups(type: $type) {
      id
      name
      type
      slug
      keywords
    }
  }
`;
