import { CircularProgress } from "@mui/material";
import { useContext } from "react";
import LoaderContext from "../../context/LoaderContext";

const LoaderNew = () => {
  const { isLoading } = useContext(LoaderContext);
  const loaderStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
    visibility: isLoading ? "visible" : "hidden",
  };

  const overlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 9998,
    visibility: isLoading ? "visible" : "hidden",
  };

  return (
    <div className="loader-wrapper">
      <div style={overlayStyle} />
      <div style={loaderStyle}>
        <CircularProgress />
      </div>
    </div>
  );
};

export default LoaderNew;
