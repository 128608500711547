import { ButtonGroup, InputItem, LoadingSpinner } from "pepsico-ds";
import { useState } from "react";
// ../components/common/CodeGroupGridCar
import SystemSettingCard from "../../components/management/SystemSettingCard/SystemSettingCard";
import SystemSettingTable from "../../components/management/SystemSettingTable/SystemSettingTable";
import CreateRewardsModal from "../../components/rewards/CreateRewardsModal/CreateRewardsModal";
import "./../../styles/common.scss";
import "./ManagementPage.scss";

export const LIST_VIEW_TYPES = [
  {
    icon: "view_module",
    viewType: "grid",
  },
  {
    icon: "view_list",
    viewType: "list",
  },
];

const rewardData = [
  {
    name: "Widgets on Home Screen",
    description: "Widgets on Home Screen",
  },
  {
    name: "Terms and Conditions",
    description: "Terms and Conditions",
  },
];
export default function SystemSettingPage() {
  const [createRewardModalOpen, setCreateRewardModalOpen] = useState(false);
  const [viewType, setViewType] = useState("grid");
  // FIXME: inputSearchText never used
  const [, setInputSearchText] = useState("");

  const onClickEdit = () => {
    console.log("🚀 ~ onClickEdit ~ onClickEdit:");
  };
  const onClickView = () => {
    console.log("🚀 ~ onClickView ~ onClickView:");
  };

  const toggleModalNewReward = () => {
    setCreateRewardModalOpen(!createRewardModalOpen);
  };

  const handleInputSearchText = (event) => {
    const { value } = event.target;
    setInputSearchText(value);
  };

  return (
    <div className="container">
      <div className="wrap">
        {createRewardModalOpen && (
          <CreateRewardsModal
            createRewardModalOpen={createRewardModalOpen}
            toggleModalNewReward={toggleModalNewReward}
          />
        )}
        <div className="heading-wrap">
          <h2>System setting</h2>
        </div>

        <div className="container filter-container">
          <div className="filter-container-search">
            <InputItem
              placeholder="Type a search keyword..."
              trailingIcon="search"
              size="medium"
              className="input-search"
              onChange={handleInputSearchText}
            />
          </div>
          <div className="filter-button-group">
            <ButtonGroup
              items={LIST_VIEW_TYPES.map((item) => ({
                ...item,
                onClick: () => setViewType(item.viewType),
              }))}
              size="medium"
              spacing="2px"
              variant="toggle"
              selectedIndex={viewType === "grid" ? 0 : 1}
            />
          </div>
        </div>
        <div className="card-list">
          {rewardData.length ? (
            viewType === "grid" ? (
              <div className="grid-container">
                {rewardData.map((item, index) => (
                  <div className="grid-item grid-item-3 grid-item-4" key={index}>
                    <SystemSettingCard
                      {...item}
                      onClickView={onClickView}
                      onClickEdit={onClickEdit}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <SystemSettingTable rewardData={rewardData} />
            )
          ) : (
            <LoadingSpinner
              size={{
                height: "30px",
                width: "30px",
              }}
              style={{ margin: "auto" }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
