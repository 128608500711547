import { Menu, MenuItem } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import moment from "moment-timezone";
import { Icon, Logo } from "pepsico-ds";
import { useState } from "react";

export default function Header({ programConfig }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const { oktaAuth } = useOktaAuth();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () =>
    oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}/pepsico/signin`,
    });

  moment.locale("en");

  return (
    <header className="header">
      <nav className="navbar $bg-black $color-text-white $spacing-p04">
        <Logo
          colorStyle="color"
          isDark
          logoType="pepsicoFullLogo"
          className="pepsicoFullLogo"
        />

        <div className="display-flex align-items-center">
          <img src="/assets/images/projectHeaderLogo.svg" alt="" className="mr-1" />
          <h1 className="$font-body-bold">{programConfig?.name}</h1>
        </div>

        <div className="display-flex">
          <Icon
            alt="notifications"
            className="$color-icon-white"
            icon="notifications"
            size="medium"
            variant="filled"
          />
          <span className="$spacing-05"></span>
          <div
            className="display-flex align-items-center cursor-pointer"
            onClick={handleMenu}
          >
            <Icon
              alt="account"
              className="$color-icon-white"
              icon="account_circle"
              size="medium"
              variant="filled"
            />
            <Icon
              alt="arrow-drop-down"
              className="$color-icon-white"
              icon="arrow_drop_down"
              size="medium"
              variant="filled"
            />
          </div>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              className="$color-link-hover $spacing-m02"
              onClick={() => {
                logout();
                handleClose();
              }}
              sx={{ minWidth: "80px" }}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </nav>
    </header>
  );
}
