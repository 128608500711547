export const paths = {
  // common
  default: "/",
  any: "*",
  create: "create",
  view: "view",
  edit: "edit",

  // campaigns
  campaigns: "campaigns",
  campaign_list: "campaign-list",
  campaign_summary_view: "campaign-summary-view",
  campaign_calendar: "campaign-calendar",
  generic_setup: "generic-setup",
  segment_specific_setup: "segment-specific-setup",
  acitivity_level_setup: "activity-level-setup",
  acitivity_level_setup_new: "activity-level-setup-new",
  campaign_level_setup: "campaign-level-setup",
  summary: "summary",

  // widgets
  widgets: "widgets",
  widget_list: "widget-list",
  create_member_history: "create-member-history",
  edit_member_history: "edit-member-history",
  create_member_information: "create-member-information",
  edit_member_information: "edit-member-information",
  create_list_of_campaigns: "create-list-of-campaigns",
  edit_list_of_campaigns: "edit-list-of-campaigns",
  create_list_of_sweepstakes: "create-list-of-sweepstakes",
  edit_list_of_sweepstakes: "edit-list-of-sweepstakes",

  //sweepstakes:
  sweepstakes: "sweepstakes",
  entrants_winners: "winners-management",
  pick_winners: "pick-winners",
  sweepstakes_archiving: "archiving",

  // partner & partner code group
  partners: "partners",

  //segmentation
  segmentation: "segmentation",
  segment_list: "segments-list",
  segment_summary_view: "segment-summary-view",

  // content
  content: "content",
  content_pages: "content-pages",
  survey_list: "survey-list",
  banners: "banners",
  content_list: "content-list",
  details: "details",

  // rewards

  rewards: "rewards",
  products: "products",

  //code groups
  code_groups: "code-groups",
  // member relations
  member_list: "member-list",
  member: "member",

  //management
  system_setting: "system-setting",
  term_and_condition: "term-and-condition",
  term_and_condition_view: "term-and-condition-view",
  term_and_condition_edit: "term-and-condition-edit",
  // test route
  all_components: "all-components",
};
