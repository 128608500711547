// filterJsonSchema.js

export const codeGroupFilterJsonSchema = {
  type: "object",
  properties: {
    status: {
      label: "Status",
      items: {
        type: "string",
        options: [
          { id: "active", displayText: "Active" },
          { id: "inactive", displayText: "Inactive" },
        ],
      },
      enum: ["ACTIVE", "INACTIVE"],
    },
    producerCompany: {
      label: "Producer Company",
      items: {
        type: "string",
        options: [
          { id: "companyA", displayText: "COMPANY A" },
          { id: "companyB", displayText: "COMPANY B" },
        ],
      },
      enum: ["Company A", "Company B"],
    },
    activeStartDate: {
      type: "string",
      format: "date",
      title: "Active Start Date",
    },
    activeEndDate: {
      type: "string",
      format: "date",
      title: "Active End Date",
    },
    // codeCountRange: {
    //   type: "object",
    //   properties: {
    //     from: {
    //       type: "number",
    //       title: "Code Count From",
    //     },
    //     to: {
    //       type: "number",
    //       title: "Code Count To",
    //     },
    //   },
    // },
    // usedCodeCountRange: {
    //   type: "object",
    //   properties: {
    //     from: {
    //       type: "number",
    //       title: "Used Code Count From",
    //     },
    //     to: {
    //       type: "number",
    //       title: "Used Code Count To",
    //     },
    //   },
    // },
  },
};

// filterUiSchema.js

export const codeGroupFilterUiSchema = {
  type: "HorizontalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/status",
      component: "muiSelect",
      placeholder: "Select Status",
    },
    {
      type: "Control",
      scope: "#/properties/producerCompany",
      component: "muiSelect",
      placeholder: "Select Producer Company",
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/activeStartDate",
          component: "dateTimePicker",
          label: "Active Start Date",
        },
        {
          type: "Control",
          scope: "#/properties/activeEndDate",
          component: "dateTimePicker",
          label: "Active End Date",
        },
      ],
    },
    // {
    //   type: "Group",
    //   label: "Code Count Range",
    //   elements: [
    //     {
    //       type: "Control",
    //       scope: "#/properties/codeCountRange/properties/from",
    //       component: "numberInput",
    //       label: "From",
    //     },
    //     {
    //       type: "Control",
    //       scope: "#/properties/codeCountRange/properties/to",
    //       component: "numberInput",
    //       label: "To",
    //     },
    //   ],
    // },
    // {
    //   type: "Group",
    //   label: "Used Code Count Range",
    //   elements: [
    //     {
    //       type: "Control",
    //       scope: "#/properties/usedCodeCountRange/properties/from",
    //       component: "numberInput",
    //       label: "From",
    //     },
    //     {
    //       type: "Control",
    //       scope: "#/properties/usedCodeCountRange/properties/to",
    //       component: "numberInput",
    //       label: "To",
    //     },
    //   ],
    // },
  ],
};

export const debouceCodeGroupFilterJsonSchema = {
  type: "object",
  properties: {
    searchText: {
      type: "string",
      title: "Search",
    },
  },
};

export const debounceCodeGroupFilterUiSchema = {
  type: "Control",
  scope: "#/properties/searchText",
  component: "searchInput",
  placeholder: "Search by code group name",
};
