import { withJsonFormsLayoutProps } from "@jsonforms/react";
import CustomLayoutRenderer from "../CustomLayoutRenderer/CustomLayoutRenderer";

// import "./styles.scss";

const InputRow = ({
  schema,
  uischema,
  path,
  renderers,
  cells,
  enabled,
  visible,
  label,
}) => {
  const layoutProps = {
    elements: uischema.elements,
    schema: schema,
    path: path,
    direction: "row",
    gap: "simple",
    visible: visible,
    uischema: uischema,
    renderers: renderers,
    label: label,
    cells: cells,
    enabled: enabled,
  };
  return (
    <>
      <CustomLayoutRenderer {...layoutProps} />
    </>
  );
};

export default withJsonFormsLayoutProps(InputRow);
