import { withJsonFormsControlProps } from "@jsonforms/react";
import MUISelectField from "./MUISelectField";

const CustomMUISelectControl = (props) => {
  const { data, handleChange, path, errors, schema, uischema, required, enabled } =
    props;

  return (
    <MUISelectField
      value={data}
      updateValue={(newVal) => handleChange(path, newVal)}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
      disabled={!enabled}
    />
  );
};

export default withJsonFormsControlProps(CustomMUISelectControl);
