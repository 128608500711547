import { Icon } from "pepsico-ds";
import { useState } from "react";
import PreviewPlaceholderDark from "../../../../assets/widget/dark_sweepstakes.png";
import PreviewPlaceholder from "../../../../assets/widget/placeholder_sweepstakes.png";
import "./PreviewCardsCarouselSweepstakes.scss";

const PreviewCardsCarouselSweepstakes = (props) => {
  const { styles } = props;
  const [isHovered, setIsHovered] = useState(false);
  const dynamicStyle = {
    backgroundColor: isHovered
      ? styles.data.buttonHoverColor
      : styles.data.buttonColor,
    color: isHovered
      ? styles.data.buttonTextHoverColor
      : styles.data.buttonTextColor,
  };

  return (
    <div className="container preview-cards-carousel-container p-0">
      {styles.data.format === "GRID" && (
        <div className="preview-grid">
          <div
            className="preview-card-item"
            style={{ backgroundColor: styles.data.cardBgColor }}
          >
            <div className="image">
              <img src={PreviewPlaceholder} alt="Preview" />
            </div>
            <div className="text">
              <div className="title" style={{ color: styles.data.titleColor }}>
                Title as configured in the sweepstakes
              </div>
              <div
                className="secondary-text"
                style={{ color: styles.data.textColor }}
              >
                Ends in 5 days!
              </div>
              <div
                className="btn-preview"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <p style={dynamicStyle}>Enter now</p>
              </div>
            </div>
          </div>
          <div
            className="preview-card-item"
            style={{ backgroundColor: styles.data.cardBgColor }}
          >
            <div className="image">
              <img src={PreviewPlaceholder} alt="Preview" />
            </div>
            <div className="text">
              <div className="title" style={{ color: styles.data.titleColor }}>
                Title as configured in the sweepstakes
              </div>
              <div
                className="secondary-text"
                style={{ color: styles.data.textColor }}
              >
                Ends in 5 days!
              </div>
              <div
                className="warning-text"
                style={{ color: styles.data.warningColor }}
              >
                You are 1 Entry away from this sweepstakes!
              </div>
              <div className="btn-preview disabled">
                <p>Learn More</p>
              </div>
            </div>
          </div>
          <div
            className="preview-card-item"
            style={{ backgroundColor: styles.data.cardBgColor }}
          >
            <div className="image">
              <img src={PreviewPlaceholder} alt="Preview" />
            </div>
            <div className="text">
              <div className="title" style={{ color: styles.data.titleColor }}>
                Title as configured in the sweepstakes
              </div>
              <div
                className="secondary-text"
                style={{ color: styles.data.textColor }}
              >
                Starts in 5 days!
              </div>
              <div
                className="coming-soon"
                style={{ color: styles.data.comingSoonColor }}
              >
                Coming Soon!
              </div>
            </div>
          </div>
          <div
            className="preview-card-item"
            style={{ backgroundColor: styles.data.cardBgColor }}
          >
            <div className="image">
              <img src={PreviewPlaceholder} alt="Preview" />
            </div>
            <div className="text">
              <div className="title" style={{ color: styles.data.titleColor }}>
                Title as configured in the sweepstakes
              </div>
              <div
                className="secondary-text"
                style={{ color: styles.data.textColor }}
              >
                Ends in 5 days!
              </div>
              <div
                className="btn-preview"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <p style={dynamicStyle}>Enter now</p>
              </div>
            </div>
          </div>
          <div
            className="preview-card-item"
            style={{ backgroundColor: styles.data.cardBgColor }}
          >
            <div className="image">
              <img src={PreviewPlaceholder} alt="Preview" />
            </div>
            <div className="text">
              <div className="title" style={{ color: styles.data.titleColor }}>
                Title as configured in the sweepstakes
              </div>
              <div
                className="secondary-text"
                style={{ color: styles.data.textColor }}
              >
                Ends in 5 days!
              </div>
              <div
                className="btn-preview"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <p style={dynamicStyle}>Enter now</p>
              </div>
            </div>
          </div>
          <div
            className="preview-card-item"
            style={{ backgroundColor: styles.data.cardBgColor }}
          >
            <div className="image">
              <img src={PreviewPlaceholderDark} alt="Preview" />
            </div>
            <div className="text">
              <div className="title disabled">
                Title as configured in the sweepstakes
              </div>
              <div
                className="btn-preview ended"
                style={{ color: styles.data.textColor }}
              >
                <p>The sweeps have ended</p>
              </div>
            </div>
          </div>
        </div>
      )}
      {styles.data.format === "CAROUSEL" && (
        <div className="preview-carousel">
          <div
            className="arrow"
            style={{
              backgroundColor: styles.data.buttonColor,
              color: styles.data.buttonTextColor,
            }}
          >
            <Icon
              disabled
              icon="keyboard_arrow_left"
              variant="filled"
              style={{ color: styles.data.buttonTextColor }}
            />
          </div>
          <div className="preview-carousel-items">
            <div
              className="preview-card-item"
              style={{ backgroundColor: styles.data.cardBgColor }}
            >
              <div className="image">
                <img src={PreviewPlaceholder} alt="Preview" />
              </div>
              <div className="text">
                <div className="title" style={{ color: styles.data.titleColor }}>
                  Title as configured in the sweepstakes
                </div>
                <div
                  className="secondary-text"
                  style={{ color: styles.data.textColor }}
                >
                  Ends in 5 days!
                </div>
                <div
                  className="btn-preview"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <p style={dynamicStyle}>Enter now</p>
                </div>
              </div>
            </div>
            <div
              className="preview-card-item"
              style={{ backgroundColor: styles.data.cardBgColor }}
            >
              <div className="image">
                <img src={PreviewPlaceholder} alt="Preview" />
              </div>
              <div className="text">
                <div className="title" style={{ color: styles.data.titleColor }}>
                  Title as configured in the sweepstakes
                </div>
                <div
                  className="secondary-text"
                  style={{ color: styles.data.textColor }}
                >
                  Ends in 5 days
                </div>
                <div
                  className="warning-text"
                  style={{ color: styles.data.warningColor }}
                >
                  You are 1 entry away for this sweepstake
                </div>
                <div className="btn-preview disabled">
                  <p>Learn More</p>
                </div>
              </div>
            </div>
            <div
              className="preview-card-item"
              style={{ backgroundColor: styles.data.cardBgColor }}
            >
              <div className="image">
                <img src={PreviewPlaceholder} alt="Preview" />
              </div>
              <div className="text">
                <div className="title" style={{ color: styles.data.titleColor }}>
                  Title as configured in the sweepstakes
                </div>
                <div
                  className="secondary-text"
                  style={{ color: styles.data.textColor }}
                >
                  Starts in 5 days!
                </div>
                <div
                  className="coming-soon"
                  style={{ color: styles.data.comingSoonColor }}
                >
                  Coming Soon!
                </div>
              </div>
            </div>
          </div>
          <div
            className="arrow"
            style={{
              backgroundColor: styles.data.buttonColor,
              color: styles.data.buttonTextColor,
            }}
          >
            <Icon
              disabled
              icon="keyboard_arrow_right"
              variant="filled"
              style={{ color: styles.data.buttonTextColor }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviewCardsCarouselSweepstakes;
