import { withJsonFormsControlProps } from "@jsonforms/react";
import SliderField from "./SliderFeild";

const CustomSliderControl = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
  } = props;
  return (
    <SliderField
      value={data}
      label={label}
      updateValue={(newVal) => handleChange(path, newVal)}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
    />
  );
};

export default withJsonFormsControlProps(CustomSliderControl);