import { useMemo } from "react";
import { JsonForms } from "@jsonforms/react";
import { materialCells, materialRenderers } from "@jsonforms/material-renderers";
import { LayoutTester } from "./testers";
// controllers
import CustomGroupLayout from "../layouts/groupLayout/CustomGroupLayout";
import VerticalLayout from "../layouts/verticalLayout/verticalLayout";
import HorizontalLayout from "../layouts/horizontalLayout/horizontalLayout";
import SimpleGroup from "../layouts/simpleGroup/simpleGroup";
import simpleGroupPreviewCards from "../layouts/simpleGroupPreviewCards/simpleGroupPreviewCards";
import { isDeepEqual } from "../../../utils/commons";
import inputRow from "../layouts/inputRow/inputRow";
import { customerRenderesHelper, getScopesofAllElements } from "./jsonFormsHelper";
import { createAjv } from "@jsonforms/core";
import ConditionalSectionLayout from "../layouts/conditionalSectionLayout/ConditionalSectionLayout";
import LayerLayout from "../layouts/layerLayout/LayerLayout";

export default function JsonFormsWrapper({
  jsonschema,
  uischema,
  data = {},
  onChange = () => null,
  readonly = false,
}) {
  const scopeAndCompsList = useMemo(
    () => getScopesofAllElements(uischema),
    [uischema]
  );

  const customRenderers = useMemo(
    () => customerRenderesHelper(scopeAndCompsList, data),
    [scopeAndCompsList, data]
  );

  const onJsonFormsDataChange = (values, errors) => {
    const isSameContent =
      Object.keys(data).length > 0 ? isDeepEqual(values, data) : false;
    if (typeof values === "object" && Object.keys(values).length && !isSameContent) {
      onChange(values, errors);
    }
    // onChange(values, errors);
  };

  const renderers = useMemo(
    () => [
      ...materialRenderers,
      ...customRenderers,
      {
        tester: LayoutTester("VerticalLayout"),
        renderer: VerticalLayout,
      },
      {
        tester: LayoutTester("HorizontalLayout"),
        renderer: HorizontalLayout,
      },
      {
        tester: LayoutTester("SimpleGroup"),
        renderer: SimpleGroup,
      },
      {
        tester: LayoutTester("simpleGroupPreviewCards"),
        renderer: simpleGroupPreviewCards,
      },
      {
        tester: LayoutTester("CustomGroup"),
        renderer: CustomGroupLayout,
      },
      {
        tester: LayoutTester("InputRow"),
        renderer: inputRow,
      },
      {
        tester: LayoutTester("ConditionalSectionLayout"),
        renderer: ConditionalSectionLayout,
      },
      {
        tester: LayoutTester("LayerLayout"),
        renderer: LayerLayout,
      },
    ],
    [customRenderers]
  );
  const handleDefaultsAjv = createAjv({ useDefaults: true });
  return (
    <JsonForms
      readonly={readonly}
      schema={jsonschema}
      uischema={uischema}
      data={data}
      renderers={renderers}
      cells={materialCells}
      onChange={({ errors, data }) => onJsonFormsDataChange(data, errors)}
      ajv={handleDefaultsAjv}
    />
  );
}
