import { JsonFormsDispatch, withJsonFormsLayoutProps } from "@jsonforms/react";
import "./simpleGroupPreviewCards.scss";
import PreviewCardsCarouselSweepstakes from "../../previewWidget/PreviewCardsCarouselSweepstakes/PreviewCardsCarouselSweepstakes";
import PreviewCardsCarouselCampaigns from "../../previewWidget/PreviewCardsCarouselCampaigns/PreviewCardsCarouselCampaigns";

const simpleGroupPreviewCards = (props) => {
  const { uischema, schema, path, enabled, renderers, cells, visible } = props;
  return (
    <>
      {visible && (
        <div className={`simple-form-group ${uischema.className ?? ""}`}>
          {uischema.label && <h6 className="font-lg font-bold">{uischema.label}</h6>}
          <div className='simple-form-group-wrapper'>
            {uischema.elements.map((child, index) => (
              <JsonFormsDispatch
                schema={schema}
                uischema={child}
                path={path}
                enabled={enabled}
                renderers={renderers}
                cells={cells}
                key={index}
              />
            ))}
          </div>
          {
            uischema.page === 'Sweepstakes' ? (
              <PreviewCardsCarouselSweepstakes styles={props} />
            ) : (
              <PreviewCardsCarouselCampaigns styles={props} />
            )
          }
        </div>
      )}
    </>
  );
};

export default withJsonFormsLayoutProps(simpleGroupPreviewCards);
