export const typesOptions = [
  { id: "4-play", displayText: "4-Play" },
  { id: "raffles", displayText: "Çekiliş Hakkı" },
  { id: "hopi", displayText: "Hopi" },
  { id: "internet-package", displayText: "Internet Paketi" },
  { id: "partner", displayText: "Partner" },
  { id: "sodexo", displayText: "Sodexo" },
  { id: "zynga", displayText: "Zynga" },
];

export const subtypesOptions = {
  zynga: [
    { id: "tavla-plus", displayText: "Tavla Plus" },
    { id: "okey-plus", displayText: "Okey Plus" },
    { id: "canak-okey", displayText: "Canak Okey" },
    { id: "okey-101-plus", displayText: "Okey 101 Plus" },
  ],
  "internet-package": [
    { id: "internet-package", displayText: "Internet Paketi" },
  ],
  "4-play": [
    { id: "kazankazan", displayText: "Kazankazan" },
    { id: "survivor", displayText: "Survivor" },
  ],
  hopi: [{ id: "hopi", displayText: "Hopi" }],
  sodexo: [
    { id: "sodexo-yemek", displayText: "Sodexo Yemek" },
    { id: "sodexo-flexogift", displayText: "Sodexo FlexoGift" },
    {
      id: "sodexo-flexogift-tek-marka",
      displayText: "Sodexo FlexoGift Tek Marka",
    },
  ],
  raffles: [{ id: "raffles", displayText: "Çekiliş Hakkı" }],
  partner: [{ id: "pubg", displayText: "PubG" }],
};

export const categoryOptions = [
  { id: "internet", displayText: "İnternet" },
  { id: "entertainment", displayText: "Eğlence" },
  { id: "game", displayText: "Oyun" },
  { id: "shopping", displayText: "Alışveriş" },
  { id: "food", displayText: "Yiyecek" },
];
