import { useMutation, useQuery } from "@apollo/client";
import { createContext, useEffect, useState } from "react";
import createSurveyContent from "../components/survey/graphQL/mutations/createSurveyContent";
import getListContent from "../components/survey/graphQL/queries/getListContent";

// Create the SurveyContext
const SurveyContext = createContext();

// Create the SurveyProvider component
const SurveyProvider = ({ children }) => {
  // Define the initial state
  const initialState = {
    id: null,
    title: "",
    slug: "",
    description: "",
    activeStartDate: "",
    activeEndDate: "",
    segments: [],
    tags: [],
    category: "",
    paused: false,
    contentDefinitionSlug: "survey-quiz-content",
    contentData: {
      position: "",
      image_url: "",
      type: "",
      short_title: "",
      source_url: "",
      show_in_widget: false,
      show_in_wallet: false,
      show_heidyeli_badge: false,
      widget_name: "",
      wallet_button_text: "",
    },
  };

  const initialFilter = {
    filter: {
      contentDefinitionSlug: "survey-quiz-content",
    },
  };

  const surveyFilterJsonSchema = {
    title: "Survey Filter",
    type: "object",
    properties: {
      contentData: {
        type: "object",
        properties: {
          type: {
            label: "Survey or Test",
            type: "string",
            enum: ["SURVEY", "QUIZ"],
          },
        },
      },
      status: {
        label: "Status",
        type: "string",
        enum: ["ACTIVE", "INACTIVE", "COMPLETED", "PUBLISHED"],
      },
      segment: {
        label: "Segment-specific",
        type: "string",
        enum: ["Yes", "No"],
      },
      activeStartDate: {
        type: "string",
      },
      activeEndDate: {
        type: "string",
      },
    },
  };

  const surveyFilterUiSchema = {
    type: "VerticalLayout",
    elements: [
      {
        type: "Control",
        scope: "#/properties/contentData/properties/type",
        component: "filterSelect",
      },
      {
        type: "Control",
        scope: "#/properties/status",
        component: "filterSelect",
      },
      {
        type: "Control",
        scope: "#/properties/segment",
        component: "filterSelect",
      },
      {
        type: "SimpleGroup",
        label: "Active Date",
        labelStyles: { display: "none" },
        elements: [
          {
            type: "VerticalLayout",
            elements: [
              {
                type: "Control",
                scope: "#/properties/activeStartDate",
                component: "dateTimePicker",
                label: "Start date",
                minimum: "01/01/2024",
              },
              {
                type: "Control",
                scope: "#/properties/activeEndDate",
                component: "dateTimePicker",
                label: "End Date",
                minimum: "01/01/2024",
              },
            ],
          },
        ],
      },
    ],
  };

  // Create the state using useState
  const [surveyState, setSurveyState] = useState(initialState);
  const [surveyList, setSurveyList] = useState([]);
  const [filterVariable, setFilterVariables] = useState(null);
  const [variables, setVariables] = useState(initialFilter);
  const [saveSurvey] = useMutation(createSurveyContent);
  const [load, setLoad] = useState(false);
  const [successTitle, setSuccessTitle] = useState({});
  const [errMsg, setErrMsg] = useState([]);

  const { data, loading, refetch } = useQuery(getListContent, {
    variables: variables,
    onCompleted: () => {
      setLoad(false);
    },
    onError: () => {
      setLoad(false);
    },
  });

  const isValidURL = (string) => {
    try {
      const newUrl = new URL(string);
      return ["http:", "https:"].includes(newUrl.protocol);
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const validateTitle = (data) => {
    if (!data?.title || data?.title.length === 0) {
      return "Title is required!";
    }
    return null;
  };

  const validateDescription = (data) => {
    if (!data?.description || data?.description.length === 0) {
      return "Description is required!";
    }
    return null;
  };

  const validateDates = (data) => {
    const startDate = new Date(data?.activeStartDate);
    const endDate = new Date(data?.activeEndDate);
    if (startDate >= endDate) {
      return "Active End Date should be greater than Active Start Date!";
    }
    return null;
  };

  const validateImageUrl = (data) => {
    if (!isValidURL(data?.contentData?.image_url)) {
      return "Please provide a valid image URL!";
    }
    return null;
  };

  const validateSourceUrl = (data) => {
    if (
      data?.contentData?.source_url &&
      !isValidURL(data?.contentData?.source_url)
    ) {
      return "Please provide a valid source URL!";
    }
    return null;
  };

  const validateWidgetName = (data) => {
    if (data?.contentData?.show_in_widget && !data?.contentData?.widget_name) {
      return "Widget name is required when 'Show in Widget' is enabled!";
    }
    return null;
  };

  const validateWalletButtonText = (data) => {
    if (
      data?.contentData?.show_in_wallet &&
      !data?.contentData?.wallet_button_text
    ) {
      return "Wallet button text is required when 'Show in Wallet' is enabled!";
    }
    return null;
  };

  const validateAllFields = (data) => {
    const tempErrors = [];

    // Validate each field individually and push errors
    const titleError = validateTitle(data);
    if (titleError) tempErrors.push(titleError);

    const descriptionError = validateDescription(data);
    if (descriptionError) tempErrors.push(descriptionError);

    const dateError = validateDates(data);
    if (dateError) tempErrors.push(dateError);

    const imageUrlError = validateImageUrl(data);
    if (imageUrlError) tempErrors.push(imageUrlError);

    const sourceUrlError = validateSourceUrl(data);
    if (sourceUrlError) tempErrors.push(sourceUrlError);

    const widgetNameError = validateWidgetName(data);
    if (widgetNameError) tempErrors.push(widgetNameError);

    const walletButtonTextError = validateWalletButtonText(data);
    if (walletButtonTextError) tempErrors.push(walletButtonTextError);

    setErrMsg(tempErrors);
    return tempErrors.length === 0;
  };

  console.log("data", surveyState);
  const handleSaveSurvey = async () => {
    setLoad(true);
    const tempSurveyState = { ...surveyState };
    tempSurveyState["slug"] =
      surveyState?.title + "-" + Math.floor(Math.random() * 40000);

    const isValid = validateAllFields(tempSurveyState);
    if (!isValid) {
      setLoad(false);
      return { success: false, message: errMsg.join(", ") };
    }

    const params = {
      variables: {
        content: tempSurveyState,
      },
    };

    try {
      const save = await saveSurvey(params);
      if (save?.data) {
        setSuccessTitle({
          title: tempSurveyState?.title,
          id: tempSurveyState?.id,
        });
        setSurveyState(initialState);
        refetch();
        return { success: true };
      }
    } catch (err) {
      const tempErr = [];
      const errorMessage =
        err?.graphQLErrors?.[0]?.message ||
        "Failed to save the survey. Please try again later.";
      tempErr.push(errorMessage);
      setErrMsg(tempErr);
    } finally {
      setLoad(false);
    }

    return { success: false, message: "An unexpected error occurred." };
  };

  const handleFilter = (data) => {
    setFilterVariables(data);
    if (Object.keys(data)?.length === 0) {
      setVariables(initialFilter);
    }
  };

  useEffect(() => {
    setLoad(true);
    refetch();
  }, [variables]);

  useEffect(() => {
    const tempVar = { ...variables };
    const tempFilter = tempVar["filter"];
    const finalFilter = { ...tempFilter, ...filterVariable };
    setVariables({ filter: finalFilter });
  }, [filterVariable]);

  useEffect(() => {
    if (data && data?.listContent) {
      setSurveyList(data?.listContent);
    }
    setLoad(false);
  }, [data]);

  useEffect(() => {
    if (loading === false && load === true) {
      setLoad(false);
    }
  }, [loading, load]);

  const contextValue = {
    initialState,
    setSurveyList,
    surveyList,
    surveyState,
    errMsg,
    load,
    surveyFilterJsonSchema,
    surveyFilterUiSchema,
    successTitle,
    setSuccessTitle,
    setLoad,
    setSurveyState,
    handleSaveSurvey,
    setFilterVariables,
    filterVariable,
    handleFilter,
    refetch,
  };

  return (
    <SurveyContext.Provider value={contextValue}>{children}</SurveyContext.Provider>
  );
};

export { SurveyContext, SurveyProvider };
