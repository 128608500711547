import { withJsonFormsControlProps } from "@jsonforms/react";
import { Button, InputItem, LoadingSpinner, Modal, Tag } from "pepsico-ds";
import "./quiz-select.scss";
import { useContext, useEffect, useState } from "react";
import { getContentList } from "../../../graphql/queries/contentQueries";
import { useQuery } from "@apollo/client";
import { Radio } from "@mui/material";
import { GlobalConfigContext } from "../../../context/GlobalConfigContext";
import { convertDateToLocaleTz } from "../../../utils/commons";
import StatusTag from "../../common/statusTag/StatusTag";
import { searchHandler } from "../../../utils/functions";
import Table from "../../common/table/Table";

const QuizSelect = ({ data, path, handleChange, visible }) => {
  const [quizData, setQuizData] = useState([]);
  const [quizList, setQuizList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentSelectedQuiz, setCurrentSelectedQuiz] = useState();
  const [selectedQuiz, setSelectedQuiz] = useState();
  const { programTimezone } = useContext(GlobalConfigContext);
  const { data: contentData, loading } = useQuery(getContentList, {
    variables: {
      filter: {
        contentDefinitionSlug: "survey-quiz-content",
        contentData: { type: "QUIZ" },
      },
    },
    fetchPolicy: "cache-first",
  });

  useEffect(() => {
    if (data && quizData) {
      const dataQuiz = quizData.find((quiz) => quiz.id === data);
      setSelectedQuiz(dataQuiz);
      setCurrentSelectedQuiz(dataQuiz);
    }
  }, [data, quizData]);

  useEffect(() => {
    if (contentData?.listContent) {
      setQuizData(contentData.listContent);
      setQuizList(contentData.listContent);
    }
  }, [contentData]);

  const toggleModal = () => setOpenModal(!openModal);

  const handleConfirmSelection = () => {
    handleChange(path, selectedQuiz?.id);
    setCurrentSelectedQuiz(selectedQuiz);
    toggleModal();
  };

  const handleSearch = (e) => {
    const filteredList = searchHandler(
      e.target.value,
      ["title", "status"],
      quizData || []
    );
    setQuizList(filteredList);
  };

  const getActiveDate = (activeStartDate, activeEndDate) => {
    const startDate = activeStartDate ? activeStartDate.replace(/ .*/, "") : "";
    const endDate = activeEndDate ? activeEndDate.replace(/ .*/, "") : "";
    return `${startDate} - ${endDate}`;
  };

  const transformRowData = (data) => {
    return data.map((quiz) => {
      return {
        selection: (
          <Radio
            size="small"
            checked={selectedQuiz?.id === quiz.id}
            onChange={() => setSelectedQuiz(quiz)}
          />
        ),
        quiz: quiz?.title || "-",
        activeDate:
          quiz?.activeStartDate && quiz?.activeEndDate
            ? getActiveDate(
                convertDateToLocaleTz(quiz?.activeStartDate, programTimezone),
                convertDateToLocaleTz(quiz?.activeEndDate, programTimezone)
              )
            : "-",
        status: <StatusTag status={quiz.status} />,
      };
    });
  };

  const headerData = [
    { columnKey: "selection", header: "" },
    { columnKey: "quiz", header: "Quiz" },
    { columnKey: "activeDate", header: "Active Date" },
    { columnKey: "status", header: "Status" },
  ];

  const rowData = transformRowData(quizList);

  return (
    visible && (
      <div className="quiz-select">
        <div className="quiz-info">
          <span className="quiz-label">
            {currentSelectedQuiz?.title || "-"}
          </span>
          <Tag
            text={data ? "Quiz Selected" : "Incomplete"}
            color={data ? "Green-150" : "Yellow-150"}
            type="filled"
            size="small"
            isCopyable={false}
          />
        </div>
        <Button
          text={data ? "Relesect" : "Select a quiz"}
          variant={data ? "secondary" : "primary"}
          size="small"
          onClick={toggleModal}
        />
        <Modal
          className="quiz-select-modal"
          showModal={openModal}
          title="Select a Quiz"
          text=""
          showTertiaryButton={false}
          showLink={false}
          showSecondaryButton={false}
          primaryButtonProps={{
            text: "Confirm Setup",
            size: "medium",
            onClick: handleConfirmSelection,
          }}
          onCloseModal={toggleModal}
        >
          <div className="quiz-select-body">
            <div className="body-header">
              <InputItem
                placeholder="Search by keyword"
                trailingIcon="search"
                size="medium"
                className="input-search"
                onChange={handleSearch}
              />
              {selectedQuiz && (
                <span className="selected-quiz">
                  <span className="font-bold">{selectedQuiz.title || ""}</span>{" "}
                  Selected
                </span>
              )}
            </div>
            {loading ? (
              <div className="loading-container">
                <LoadingSpinner
                  size={{
                    height: "2rem",
                    width: "2rem",
                  }}
                />
              </div>
            ) : (
              <div className="quiz-list">
                <Table headerData={headerData} rowData={rowData} />
              </div>
            )}
          </div>
        </Modal>
      </div>
    )
  );
};

export default withJsonFormsControlProps(QuizSelect);
