import { gql } from '@apollo/client';

const createAsset = gql`
    mutation($Asset: Asset!) {
        createAsset(asset: $Asset) {
            url
        }
    }
`;

export default createAsset;
