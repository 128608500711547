import styled from "styled-components";

const CommonCard = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  minheight: 72px;
  padding: 16px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 -2px 0 0 rgba(215, 229, 248, 1) inset;
  ${(props) => props.styles}
`;

const MainCard = ({ children, styles }) => {
  return <CommonCard style={styles}>{children}</CommonCard>;
};

export default MainCard;
