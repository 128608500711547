import { withJsonFormsControlProps } from "@jsonforms/react";
import BannerSegmentsSelect from "../../banners/bannerSegments/BannerSegmentsSelect";

const BannerSegmentsController = (props) => {
  const { handleChange, data, path, errors, uischema, required } = props;

  return (
    <BannerSegmentsSelect
      selectedIds={data}
      setSelectedIds={(newVal) => handleChange(path, newVal)}
      required={required}
      label={uischema.label}
      errors={errors}
    />
  );
};

export default withJsonFormsControlProps(BannerSegmentsController);
