import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";

export const useGetUserClaimsOkta = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (authState === null || authState?.isAuthenticated === false) {
      // When the user isn't authenticated, forget any user info
      setUserInfo(null);
    } else if (userInfo === null) {
      oktaAuth
        .getUser()
        .then((info) => {
          setUserInfo({
            ...info,
            accessToken: `Bearer ${authState?.accessToken?.accessToken || ""}`,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [oktaAuth, authState, userInfo]);

  return userInfo;
};
