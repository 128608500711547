import { JsonFormsDispatch, withJsonFormsLayoutProps } from "@jsonforms/react";
import { Icon, Tooltip } from "pepsico-ds";
import "./styles.scss";

const SimpleGroup = ({
  schema,
  uischema,
  path,
  renderers,
  cells,
  enabled,
  visible,
}) => {
  return (
    <>
      {visible && (
        <div className={`simple-form-group ${uischema.className ?? ""}`}>
          <div className="title-wrapper">
            {uischema.label && <h6 className="font-lg font-bold">{uischema.label}</h6>}
            {uischema.tooltipText && (
              <div className="tooltip-wrapper">
                <Tooltip
                  direction={uischema.tooltipDirection ?? "right"}
                  text={uischema.tooltipText}
                >
                  <Icon
                    alt=""
                    className="$color-icon-default"
                    icon={uischema.tooltipIcon ?? "info"}
                    size="medium"
                    variant="outlined"
                  />
                </Tooltip>
              </div>
            )}
          </div>
          <div className='simple-form-group-wrapper'>
            {uischema.elements.map((child, index) => (
              <JsonFormsDispatch
                schema={schema}
                uischema={child}
                path={path}
                enabled={enabled}
                renderers={renderers}
                cells={cells}
                key={index}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default withJsonFormsLayoutProps(SimpleGroup);
