import { Icon } from "pepsico-ds";
import { useEffect } from "react";
import styled, { css } from "styled-components";

const Panel = styled.div`
  background: white;
  transition: transform 0.3s ease-out;
  overflow: auto;

  ${(props) =>
    (props.direction === "left" || props.direction === "right") &&
    css`
      width: auto;
      height: 100vh;
      ${props.direction === "left"
        ? "left: 0; box-shadow: 2px 0 8px rgba(0, 0, 0, 0.2);"
        : "right: 0; box-shadow: -2px 0 8px rgba(0, 0, 0, 0.2);"}
      transform: translateX(${props.direction === "left" ? "-100%" : "100%"});
    `}

  ${(props) =>
    (props.direction === "top" || props.direction === "bottom") &&
    css`
      width: 100%;
      height: fit-content;
      ${props.direction === "top"
        ? "top: 0; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);"
        : "bottom: 0; box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.2);"}
      transform: translateY(${props.direction === "top" ? "-100%" : "100%"});
    `}

  ${() => css`
    transform: translateX(0);
    transform: translateY(0);
  `}
`;

const SidePanel = ({
  children,
  direction = "right",
  isPanelOpen,
  hideClose = true,
  setIsPanelOpen,
}) => {
  useEffect(() => {
    document.body.classList.toggle("no-scroll", isPanelOpen);
  }, [isPanelOpen]);

  return (
    <div>
      <Panel direction={direction}>
        {isPanelOpen && !hideClose && (
          <div className="display-flex justify-content-end px-3  mb-1">
            <Icon key={1002} icon="close" onClick={() => setIsPanelOpen(false)} />
          </div>
        )}
        {children}
      </Panel>
    </div>
  );
};

export default SidePanel;
