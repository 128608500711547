// Check if a specific field is required and missing in the data
const isFieldRequired = (requiredFields, data, field) => {
  return requiredFields.includes(field) && !data[field];
};

// Validate directly required fields in the schema
const validateDirectlyRequiredFields = (schema, data) => {
  const requiredFields = schema?.required || [];
  // Ensure all required fields are present in the data
  return requiredFields.every((field) => !isFieldRequired(requiredFields, data, field));
};

// Recursively validate nested objects in the schema
const validateNestedObjects = (schema, data) => {
  for (const field in schema.properties) {
    if (schema.properties[field].type === "object") {
      // Recursively validate nested fields
      if (!validateNestedFields(schema.properties[field], data[field])) {
        return false;
      }
    }
  }
  return true;
};

// Check if a dependency condition is met based on the current data
const isDependencyConditionMet = (data, depField, condition) => {
  const properties = condition.properties || {};
  return data[depField] && properties[depField]?.const === data[depField];
};

// Validate fields that are conditionally required based on dependencies
const validateConditionalDependencies = (schema, data) => {
  const dependencies = schema?.dependencies || {};
  for (const depField in dependencies) {
    const depConditions = dependencies[depField].oneOf || [];
    for (const condition of depConditions) {
      // Check if the dependency condition is satisfied
      if (isDependencyConditionMet(data, depField, condition)) {
        const requiredFields = condition.required || [];
        // Validate the fields required by this condition
        if (!validateDirectlyRequiredFields({ required: requiredFields }, data)) {
          return false;
        }
      }
    }
  }
  return true;
};

// Main function to validate the schema and data, combining all checks
const validateNestedFields = (schema, data) => {
  return (
    validateDirectlyRequiredFields(schema, data) &&
    validateNestedObjects(schema, data) &&
    validateConditionalDependencies(schema, data)
  );
};

// Wrapper to validate a single set of JSON form data
export const validateSingleJsonFormsData = ({
  inputSchema = {},
  data = {},
}) => {
  return validateNestedFields(inputSchema, data);
};

// Wrapper to validate an array of JSON form data
export const validateArrayOfJsonFormsData = (allRules = []) => {
  return allRules.every((rule) => validateSingleJsonFormsData(rule));
};
