import { gql } from "@apollo/client";

const loadContent = gql`
  query loadContent($id: ID!) {
    loadContent(id: $id) {
      id
      title
      slug
      paused
      segments
      description
      status
      activeStartDate
      activeEndDate
      contentData
      tags
      category
      endless
      createdAt
      updatedAt
      deletedAt
      version
    }
  }
`;

export default loadContent;
