import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { matchKeywordGroupsList } from "../../../graphql/queries/listKeywordGroups";

const MatchKeywordsViewComponent = ({ data, inputSchema }) => {
  const filter_by = inputSchema?.properties?.ids?.filter_by || "BRAND";
  const { data: keywordGroupsList } = useQuery(matchKeywordGroupsList, {
    variables: { type: filter_by },
    fetchPolicy: "cache-first",
  });

  const formattedGroups = useMemo(() => {
    const formattedSelectedGroups = [];
    const selectedIds = data.ids || [];
    if (selectedIds?.length && keywordGroupsList?.listKeywordGroups?.length) {
      selectedIds.forEach((id) => {
        const formattedGroup = keywordGroupsList?.listKeywordGroups?.find(
          (keywordGroup) => keywordGroup.id === id
        );
        if (formattedGroup) {
          formattedSelectedGroups.push(formattedGroup);
        }
      });
      return formattedSelectedGroups;
    }
  }, [data, keywordGroupsList]);

  return (
    <div className="additional-fields-container">
      <div className="field-pair" style={{ flexBasis: "31% !important" }}>
        <span className="field-label">
          {filter_by === "BRAND"
            ? "Selected Brands"
            : filter_by === "PRODUCT"
              ? "Selected Products"
              : "Selected Retailers"}{" "}
          :
        </span>
      </div>
      <div className="field-pair-two-column">
        <span className="field-value">
          {formattedGroups?.length
            ? formattedGroups?.map((brand) => brand.name).join(", ")
            : "-"}
        </span>
      </div>
    </div>
  );
};

export default MatchKeywordsViewComponent;
