import { Grid } from "@mui/material";
import { Tag } from "pepsico-ds";
import { SingleBorderCard } from "../../common/SingleBorderCard";

const CardView = ({
  promoProductList,
  selectedPromoProducts,
  loading,
  onSelectPromoProduct,
}) => {
  return (
    <Grid container spacing={2} columns={12}>
      {!loading &&
        promoProductList?.length > 0 &&
        promoProductList?.map((promoProduct) => (
          <Grid
            item
            xs={6}
            md={3}
            lg={4}
            xl={4}
            className="cursor-pointer"
            key={promoProduct.id}
            sx={{ width: "100%" }}
            onClick={() => onSelectPromoProduct(promoProduct)}
          >
            <SingleBorderCard
              width="100%"
              border={
                selectedPromoProducts?.find((item) => {
                  return item.id === promoProduct.id;
                })
                  ? "2px solid green"
                  : "none"
              }
              boxShadow="0px 0px 24px 0px #005CBC14"
            >
              <img
                src={
                  promoProduct.contentData?.image_url ||
                  "/assets/images/placeholder.jpg"
                }
                height={200}
                style={{
                  borderRadius: "6px",
                  objectFit: "cover",
                  width: "100%",
                  background: `${promoProduct?.contentData?.image_url} || /assets/images/placeholder.jpg`,
                  backgroundSize: "contain",
                  maxHeight: "150px",
                }}
              />
              <Tag
                color="Orange-150"
                size="small"
                isCopyable={false}
                className="font-xxs"
                text={promoProduct?.contentData?.category}
                type="filled"
              />
              <h4
                className="font-sm text-wrap text-ellipsis-2"
                style={{ minHeight: "35px" }}
                title={promoProduct.contentData?.name}
              >
                {promoProduct.contentData?.name}
              </h4>
              {!!selectedPromoProducts?.find((item) => {
                return item.id === promoProduct.id;
              }) && (
                <img
                  src={"/assets/images/checkmark.png"}
                  height={24}
                  width={24}
                  style={{ position: "absolute", right: 10, top: 10 }}
                />
              )}
            </SingleBorderCard>
          </Grid>
        ))}
    </Grid>
  );
};

export default CardView;
