const hopiRewardJsonSchema = {
  type: "object",
  properties: {
    rewardImage: {
      type: "string",
      title: "Reward Image",
      format: "uri",
    },
    wheelImage: {
      type: "string",
      title: "Wheel Image",
      format: "uri",
    },
    rewardQuantity: {
      type: "number",
      title: "Reward Quantity",
    },
    unit: {
      type: "array",
      title: "Unit",
      options: [
        { id: "pieces", displayText: "Adet" },
        { id: "mb", displayText: "MB" },
        { id: "tl", displayText: "TL" },
      ],
    },
    rewardName: {
      type: "string",
      title: "Reward Name",
      maxLength: 40,
    },
    rewardDescription: {
      type: "string",
      title: "Reward Description",
      maxLength: 250,
    },
    hopiCampaignCode: {
      type: "string",
      title: "Hopi Campaign Code",
    },
  },
  required: [
    "rewardImage",
    "rewardQuantity",
    "unit",
    "rewardName",
    "hopiCampaignCode",
  ],
};

const hopiRewardUiSchema = {
  type: "SimpleGroup",
  label: "Basic Setup",
  elements: [
    {
      type: "HorizontalLayout",
      className: "basic-setup-columns",
      elements: [
        {
          type: "VerticalLayout",
          className: "reward-image-fields",
          elements: [
            {
              type: "Control",
              scope: "#/properties/rewardImage",
              component: "singleImageUpload",
              label: "Reward Image",
            },
            {
              type: "Control",
              scope: "#/properties/wheelImage",
              component: "singleImageUpload",
              label: "Wheel Image",
            },
          ],
        },
        {
          type: "VerticalLayout",
          className: "reward-fields",
          elements: [
            {
              type: "HorizontalLayout",
              className: "quantity-row",
              gap: "simple",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/rewardQuantity",
                  label: "Reward Quantity",
                  placeholder: "Insert Number",
                },
                {
                  type: "Control",
                  scope: "#/properties/unit",
                  label: "Unit",
                  component: "select",
                  placeholder: "Select",
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/rewardName",
              label: "Reward Name",
              placeholder: "Type here",
              maxLength: 40,
            },
            {
              type: "Control",
              scope: "#/properties/rewardDescription",
              label: "Reward Description",
              component: "textarea",
              placeholder: "Type here",
              maxLength: 250,
            },
            {
              type: "SimpleGroup",
              label: "Specifications",
              className: "simple-reward-specifications",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/hopiCampaignCode",
                  label: "Hopi Campaign Code",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const sodexoRewardJsonSchema = {
  type: "object",
  properties: {
    rewardImage: {
      type: "string",
      title: "Reward Image",
      format: "uri",
    },
    wheelImage: {
      type: "string",
      title: "Wheel Image",
      format: "uri",
    },
    rewardQuantity: {
      type: "number",
      title: "Reward Quantity",
    },
    unit: {
      type: "array",
      title: "Unit",
      options: [
        { id: "pieces", displayText: "Adet" },
        { id: "mb", displayText: "MB" },
        { id: "tl", displayText: "TL" },
      ],
    },
    rewardName: {
      type: "string",
      title: "Reward Name",
      maxLength: 40,
    },
    rewardDescription: {
      type: "string",
      title: "Reward Description",
      maxLength: 250,
    },
    imageOnGreatChoiceScreen: {
      type: "string",
      title: "Image on Great Choice Screen",
      format: "uri",
    },
    greatChoiceScreenDescription: {
      type: "string",
      title: "Great Choice Screen Description",
    },
  },
  required: [
    "rewardImage",
    "rewardQuantity",
    "unit",
    "rewardName",
    "imageOnGreatChoiceScreen",
    "greatChoiceScreenDescription",
  ],
};

const sodexoRewardUiSchema = {
  type: "SimpleGroup",
  label: "Basic Setup",
  elements: [
    {
      type: "HorizontalLayout",
      className: "basic-setup-columns",
      elements: [
        {
          type: "VerticalLayout",
          className: "reward-image-fields",
          elements: [
            {
              type: "Control",
              scope: "#/properties/rewardImage",
              component: "singleImageUpload",
              label: "Reward Image",
            },
            {
              type: "Control",
              scope: "#/properties/wheelImage",
              component: "singleImageUpload",
              label: "Wheel Image",
            },
          ],
        },
        {
          type: "VerticalLayout",
          className: "reward-fields",
          elements: [
            {
              type: "HorizontalLayout",
              className: "quantity-row",
              gap: "simple",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/rewardQuantity",
                  label: "Reward Quantity",
                  placeholder: "Insert Number",
                },
                {
                  type: "Control",
                  scope: "#/properties/unit",
                  label: "Unit",
                  component: "select",
                  placeholder: "Select",
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/rewardName",
              label: "Reward Name",
              placeholder: "Type here",
              maxLength: 40,
            },
            {
              type: "Control",
              scope: "#/properties/rewardDescription",
              label: "Reward Description",
              component: "textarea",
              placeholder: "Type here",
              maxLength: 250,
            },
            {
              type: "SimpleGroup",
              label: "Specifications",
              className: "reward-specifications",
              elements: [
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/imageOnGreatChoiceScreen",
                      label: "Image on Great Choice Screen",
                      component: "singleImageUpload",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/greatChoiceScreenDescription",
                      label: "Great Choice Screen Description",
                      component: "textarea",
                      placeholder: "Type here",
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const internetPackageRewardJsonSchema = {
  type: "object",
  properties: {
    rewardImage: {
      type: "string",
      title: "Reward Image",
      format: "uri",
    },
    wheelImage: {
      type: "string",
      title: "Wheel Image",
      format: "uri",
    },
    rewardQuantity: {
      type: "number",
      title: "Reward Quantity",
    },
    unit: {
      type: "array",
      title: "Unit",
      options: [
        { id: "pieces", displayText: "Adet" },
        { id: "mb", displayText: "MB" },
        { id: "tl", displayText: "TL" },
      ],
    },
    rewardName: {
      type: "string",
      title: "Reward Name",
      maxLength: 40,
    },
    rewardDescription: {
      type: "string",
      title: "Reward Description",
      maxLength: 250,
    },
    externalId: {
      type: "string",
      title: "External ID",
    },
  },
  required: [
    "rewardImage",
    "rewardQuantity",
    "unit",
    "rewardName",
    "externalId",
  ],
};

const internetPackageRewardUiSchema = {
  type: "SimpleGroup",
  label: "Basic Setup",
  elements: [
    {
      type: "HorizontalLayout",
      className: "basic-setup-columns",
      elements: [
        {
          type: "VerticalLayout",
          className: "reward-image-fields",
          elements: [
            {
              type: "Control",
              scope: "#/properties/rewardImage",
              component: "singleImageUpload",
              label: "Reward Image",
            },
            {
              type: "Control",
              scope: "#/properties/wheelImage",
              component: "singleImageUpload",
              label: "Wheel Image",
            },
          ],
        },
        {
          type: "VerticalLayout",
          className: "reward-fields",
          elements: [
            {
              type: "HorizontalLayout",
              className: "quantity-row",
              gap: "simple",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/rewardQuantity",
                  label: "Reward Quantity",
                  placeholder: "Insert Number",
                },
                {
                  type: "Control",
                  scope: "#/properties/unit",
                  label: "Unit",
                  component: "select",
                  placeholder: "Select",
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/rewardName",
              label: "Reward Name",
              placeholder: "Type here",
              maxLength: 40,
            },
            {
              type: "Control",
              scope: "#/properties/rewardDescription",
              label: "Reward Description",
              component: "textarea",
              placeholder: "Type here",
              maxLength: 250,
            },
            {
              type: "SimpleGroup",
              label: "Specifications",
              className: "simple-reward-specifications",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/externalId",
                  label: "External ID",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const defaultRewardJsonSchema = {
  type: "object",
  properties: {
    rewardImage: {
      type: "string",
      title: "Reward Image",
      format: "uri",
    },
    wheelImage: {
      type: "string",
      title: "Wheel Image",
      format: "uri",
    },
    rewardQuantity: {
      type: "number",
      title: "Reward Quantity",
    },
    unit: {
      type: "array",
      title: "Unit",
      options: [
        { id: "pieces", displayText: "Adet" },
        { id: "mb", displayText: "MB" },
        { id: "tl", displayText: "TL" },
      ],
    },
    rewardName: {
      type: "string",
      title: "Reward Name",
      maxLength: 40,
    },
    rewardDescription: {
      type: "string",
      title: "Reward Description",
      maxLength: 250,
    },
  },
  required: ["rewardImage", "rewardQuantity", "unit", "rewardName"],
};

const defaultRewardUiSchema = {
  type: "SimpleGroup",
  label: "Basic Setup",
  elements: [
    {
      type: "HorizontalLayout",
      className: "basic-setup-columns",
      elements: [
        {
          type: "VerticalLayout",
          className: "reward-image-fields",
          elements: [
            {
              type: "Control",
              scope: "#/properties/rewardImage",
              component: "singleImageUpload",
              label: "Reward Image",
            },
            {
              type: "Control",
              scope: "#/properties/wheelImage",
              component: "singleImageUpload",
              label: "Wheel Image",
            },
          ],
        },
        {
          type: "VerticalLayout",
          className: "reward-fields",
          elements: [
            {
              type: "HorizontalLayout",
              className: "quantity-row",
              gap: "simple",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/rewardQuantity",
                  label: "Reward Quantity",
                  placeholder: "Insert Number",
                },
                {
                  type: "Control",
                  scope: "#/properties/unit",
                  label: "Unit",
                  component: "select",
                  placeholder: "Select",
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/rewardName",
              label: "Reward Name",
              placeholder: "Type here",
              maxLength: 40,
            },
            {
              type: "Control",
              scope: "#/properties/rewardDescription",
              label: "Reward Description",
              component: "textarea",
              placeholder: "Type here",
              maxLength: 250,
            },
          ],
        },
      ],
    },
  ],
};

const zyngaRewardJsonSchema = {
  type: "object",
  properties: {
    rewardImage: {
      type: "string",
      title: "Reward Image",
      format: "uri",
    },
    wheelImage: {
      type: "string",
      title: "Wheel Image",
      format: "uri",
    },
    rewardQuantity: {
      type: "number",
      title: "Reward Quantity",
    },
    unit: {
      type: "array",
      title: "Unit",
      options: [
        { id: "pieces", displayText: "Adet" },
        { id: "mb", displayText: "MB" },
        { id: "tl", displayText: "TL" },
      ],
    },
    rewardName: {
      type: "string",
      title: "Reward Name",
      maxLength: 40,
    },
    rewardDescription: {
      type: "string",
      title: "Reward Description",
      maxLength: 250,
    },
    digitalRewardName: {
      type: "string",
      title: "Digital Reward Name",
    },
    schema: {
      type: "string",
      title: "Schema",
    },
    host: {
      type: "string",
      title: "Host",
    },
    rewardParameters: {
      type: "array",
      minItems: 1,
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
            title: "Key",
          },
          value: {
            type: "string",
            title: "Value",
          },
          operatingSystem: {
            type: "array",
            title: "Operating System",
            options: [
              { id: "ios", displayText: "iOS" },
              { id: "android", displayText: "Android" },
            ],
          },
        },
        required: ["key", "value", "operatingSystem"],
      },
    },
  },
  required: [
    "rewardImage",
    "rewardQuantity",
    "unit",
    "rewardName",
    "digitalRewardName",
    "schema",
    "host",
    "rewardParameters",
  ],
};

const zyngaRewardUiSchema = {
  type: "SimpleGroup",
  label: "Basic Setup",
  elements: [
    {
      type: "HorizontalLayout",
      className: "basic-setup-columns",
      elements: [
        {
          type: "VerticalLayout",
          className: "reward-image-fields",
          elements: [
            {
              type: "Control",
              scope: "#/properties/rewardImage",
              component: "singleImageUpload",
              label: "Reward Image",
            },
            {
              type: "Control",
              scope: "#/properties/wheelImage",
              component: "singleImageUpload",
              label: "Wheel Image",
            },
          ],
        },
        {
          type: "VerticalLayout",
          className: "reward-fields",
          elements: [
            {
              type: "HorizontalLayout",
              className: "quantity-row",
              gap: "simple",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/rewardQuantity",
                  label: "Reward Quantity",
                  placeholder: "Insert Number",
                },
                {
                  type: "Control",
                  scope: "#/properties/unit",
                  label: "Unit",
                  component: "select",
                  placeholder: "Select",
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/rewardName",
              label: "Reward Name",
              placeholder: "Type here",
              maxLength: 40,
            },
            {
              type: "Control",
              scope: "#/properties/rewardDescription",
              label: "Reward Description",
              component: "textarea",
              placeholder: "Type here",
              maxLength: 250,
            },
            {
              type: "SimpleGroup",
              label: "Specifications - Digital Reward",
              elements: [
                {
                  type: "HorizontalLayout",
                  elements: [
                    {
                      type: "Control",
                      scope: "#/properties/digitalRewardName",
                      label: "Digital Reward Name",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/schema",
                      label: "Schema",
                    },
                    {
                      type: "Control",
                      scope: "#/properties/host",
                      label: "Host",
                    },
                  ],
                },
              ],
            },
            {
              type: "SimpleGroup",
              label: "Specifications - Reward Parameters",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/rewardParameters",
                  label: "Add Parameter",
                  component: "arrayOfTexts",
                  options: {
                    detail: {
                      type: "HorizontalLayout",
                      elements: [
                        {
                          type: "Control",
                          scope: "#/properties/key",
                          label: "Key",
                        },
                        {
                          type: "Control",
                          scope: "#/properties/value",
                          label: "Value",
                        },
                        {
                          type: "Control",
                          scope: "#/properties/operatingSystem",
                          label: "Operating System",
                          component: "select",
                        },
                      ],
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const partnerRewardJsonSchema = {
  $schema: "http://json-schema.org/draft-07/schema#",
  type: "object",
  properties: {
    rewardImage: {
      type: "string",
      title: "Reward Image",
      format: "uri",
    },
    wheelImage: {
      type: "string",
      title: "Wheel Image",
      format: "uri",
    },
    rewardQuantity: {
      type: "number",
      title: "Reward Quantity",
    },
    unit: {
      type: "array",
      title: "Unit",
      options: [
        { id: "pieces", displayText: "Adet" },
        { id: "mb", displayText: "MB" },
        { id: "tl", displayText: "TL" },
      ],
    },
    rewardName: {
      type: "string",
      title: "Reward Name",
      maxLength: 40,
    },
    rewardDescription: {
      type: "string",
      title: "Reward Description",
      maxLength: 250,
    },
    linkType: {
      title: "Select a Link Type",
      enum: ["sms", "deep-link", "web-url", "yonlendirme-yapma"],
    },
    partner: {
      type: "array",
      title: "Partner",
      options: [
        { id: "pubg", displayText: "PubG" },
        { id: "hopi", displayText: "Hopi" },
        { id: "sodexo", displayText: "Sodexo" },
        { id: "zynga", displayText: "Zynga" },
        { id: "partner01", displayText: "Partner 01" },
      ],
    },
    partnerCodeGroup: {
      type: "array",
      title: "Partner Code Group",
      options: [
        { id: "pubg-code-group", displayText: "PubG Code Group" },
        { id: "hopi-code-group", displayText: "Hopi Code Group" },
        { id: "sodexo", displayText: "Sodexo" },
        { id: "zynga", displayText: "Zynga" },
        { id: "partner01", displayText: "Partner 01" },
      ],
    },
    imageOnGreatChoiceScreen: {
      type: "string",
      title: "Image on Great Choice Screen",
      format: "uri",
    },
    greatChoiceScreenDescription: {
      type: "string",
      title: "Great Choice Screen Description",
    },
  },
  allOf: [
    {
      if: {
        properties: {
          linkType: { const: "sms" },
        },
      },
      then: {
        properties: {
          buttonText: {
            type: "string",
            title: "Button Text",
          },
          smsNumber: {
            type: "string",
            title: "SMS Number",
          },
          smsTextMessage: {
            type: "string",
            title: "SMS Text Message",
          },
        },
        required: ["buttonText", "smsNumber", "smsTextMessage"],
      },
    },
    {
      if: {
        properties: {
          linkType: { const: "deep-link" },
        },
      },
      then: {
        properties: {
          buttonText: {
            type: "string",
            title: "Button Text",
          },
          androidAppId: {
            type: "string",
            title: "Android App ID",
          },
          iosAppId: {
            type: "string",
            title: "iOS App ID",
          },
          iosDeepLink: {
            type: "string",
            title: "iOS Deep Link",
          },
        },
        required: ["buttonText", "androidAppId", "iosAppId", "iosDeepLink"],
      },
    },
    {
      if: {
        properties: {
          linkType: { const: "web-url" },
        },
      },
      then: {
        properties: {
          buttonText: {
            type: "string",
            title: "Button Text",
          },
          webUrl: {
            type: "string",
            title: "Web URL",
            format: "uri",
          },
        },
        required: ["buttonText", "webUrl"],
      },
    },
  ],
  required: [
    "rewardImage",
    "rewardQuantity",
    "unit",
    "rewardName",
    "linkType",
    "partner",
    "partnerCodeGroup",
    "imageOnGreatChoiceScreen",
    "greatChoiceScreenDescription",
  ],
};

const partnerRewardUiSchema = {
  type: "SimpleGroup",
  label: "Basic Setup",
  elements: [
    {
      type: "HorizontalLayout",
      className: "basic-setup-columns",
      elements: [
        {
          type: "VerticalLayout",
          className: "reward-image-fields",
          elements: [
            {
              type: "Control",
              scope: "#/properties/rewardImage",
              component: "singleImageUpload",
              label: "Reward Image",
            },
            {
              type: "Control",
              scope: "#/properties/wheelImage",
              component: "singleImageUpload",
              label: "Wheel Image",
            },
          ],
        },
        {
          type: "VerticalLayout",
          className: "reward-fields",
          elements: [
            {
              type: "HorizontalLayout",
              className: "quantity-row",
              gap: "simple",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/rewardQuantity",
                  label: "Reward Quantity",
                  placeholder: "Insert Number",
                },
                {
                  type: "Control",
                  scope: "#/properties/unit",
                  label: "Unit",
                  component: "select",
                  placeholder: "Select",
                },
              ],
            },
            {
              type: "Control",
              scope: "#/properties/rewardName",
              label: "Reward Name",
              placeholder: "Type here",
              maxLength: 40,
            },
            {
              type: "Control",
              scope: "#/properties/rewardDescription",
              label: "Reward Description",
              component: "textarea",
              placeholder: "Type here",
              maxLength: 250,
            },
            {
              type: "ConditionalSectionLayout",
              headerControl: "#/properties/linkType",
              noFieldsValues: ["yonlendirme-yapma"],
              noSelectionLabel: "Select link type first",
              noFieldsLabel: "Nothing to fill in!",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/linkType",
                  label: "Select a Link Type",
                  component: "radioInput",
                  options: {
                    labelMapping: {
                      sms: "SMS",
                      "deep-link": "Deep Link",
                      "web-url": "Web URL",
                      "yonlendirme-yapma": "Yönlendirme Yapma",
                    },
                  },
                },
                {
                  type: "VerticalLayout",
                  className: "link-type-sections",
                  elements: [
                    {
                      type: "HorizontalLayout",
                      className: "link-type-fields deep-link-fields",
                      elements: [
                        {
                          type: "VerticalLayout",
                          elements: [
                            {
                              type: "Control",
                              scope: "#/allOf/1/then/properties/buttonText",
                              label: "Button Text",
                            },
                            {
                              type: "Control",
                              scope: "#/allOf/1/then/properties/iosAppId",
                              label: "iOS App ID",
                            },
                          ],
                        },
                        {
                          type: "VerticalLayout",
                          elements: [
                            {
                              type: "Control",
                              scope: "#/allOf/1/then/properties/androidAppId",
                              label: "Android App ID",
                            },
                            {
                              type: "Control",
                              scope: "#/allOf/1/then/properties/iosDeepLink",
                              label: "iOS Deep Link",
                            },
                          ],
                        },
                      ],
                      rule: {
                        effect: "SHOW",
                        condition: {
                          scope: "#/properties/linkType",
                          schema: {
                            const: "deep-link",
                          },
                        },
                      },
                    },
                    {
                      type: "HorizontalLayout",
                      className: "link-type-fields web-url-fields",
                      elements: [
                        {
                          type: "Control",
                          scope: "#/allOf/2/then/properties/buttonText",
                          label: "Button Text",
                        },
                        {
                          type: "Control",
                          scope: "#/allOf/2/then/properties/webUrl",
                          label: "Web URL",
                        },
                      ],
                      rule: {
                        effect: "SHOW",
                        condition: {
                          scope: "#/properties/linkType",
                          schema: {
                            const: "web-url",
                          },
                        },
                      },
                    },
                    {
                      type: "VerticalLayout",
                      className: "link-type-fields",
                      elements: [
                        {
                          type: "HorizontalLayout",
                          elements: [
                            {
                              type: "Control",
                              scope: "#/allOf/0/then/properties/buttonText",
                              label: "Button Text",
                            },
                            {
                              type: "Control",
                              scope: "#/allOf/0/then/properties/smsNumber",
                              label: "SMS Number",
                            },
                          ],
                        },
                        {
                          type: "Control",
                          scope: "#/allOf/0/then/properties/smsTextMessage",
                          label: "SMS Text Message",
                          component: "textarea",
                        },
                      ],
                      rule: {
                        effect: "SHOW",
                        condition: {
                          scope: "#/properties/linkType",
                          schema: {
                            const: "sms",
                          },
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: "SimpleGroup",
      label: "Specifications",
      className: "basic-setup-columns",
      elements: [
        {
          type: "HorizontalLayout",
          className: "partner-reward-specifications",
          elements: [
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/partner",
                  label: "Partner",
                  component: "select",
                  placeholder: "Search and Select",
                },
                {
                  type: "Control",
                  scope: "#/properties/imageOnGreatChoiceScreen",
                  label: "Image on Great Choice Screen",
                  component: "singleImageUpload",
                },
              ],
            },
            {
              type: "VerticalLayout",
              elements: [
                {
                  type: "Control",
                  scope: "#/properties/partnerCodeGroup",
                  label: "Partner Code Group",
                  component: "select",
                  placeholder: "Search and Select",
                },
                {
                  type: "Control",
                  scope: "#/properties/greatChoiceScreenDescription",
                  label: "Great Choice Screen Description",
                  component: "textarea",
                  placeholder: "Type here",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const rewardSchemas = {
  hopi: {
    jsonSchema: hopiRewardJsonSchema,
    uiSchema: hopiRewardUiSchema,
  },
  sodexo: {
    jsonSchema: sodexoRewardJsonSchema,
    uiSchema: sodexoRewardUiSchema,
  },
  "internet-package": {
    jsonSchema: internetPackageRewardJsonSchema,
    uiSchema: internetPackageRewardUiSchema,
  },
  raffles: {
    jsonSchema: defaultRewardJsonSchema,
    uiSchema: defaultRewardUiSchema,
  },
  "4-play": {
    jsonSchema: defaultRewardJsonSchema,
    uiSchema: defaultRewardUiSchema,
  },
  zynga: {
    jsonSchema: zyngaRewardJsonSchema,
    uiSchema: zyngaRewardUiSchema,
  },
  partner: {
    jsonSchema: partnerRewardJsonSchema,
    uiSchema: partnerRewardUiSchema,
  },
};
