import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { useState } from "react";

const MultipleSelect = (props) => {
  const { handleChange, label, path, required, schema, uischema, visible, enabled } =
    props;

  const [selected, setSelected] = useState(
    uischema?.selection === "single" ? "" : []
  );
  // useEffect(() => {
  //   // Synchronize internal state with the prop value
  //   if (Array.isArray(data)) {
  //     setSelected(
  //       data.map((item) =>
  //         schema.items.options.find((option) => option.id === item)
  //       )
  //     );
  //   } else {
  //     setSelected([]);
  //   }

  //   // Update the cancel-selection element with the correct count
  //   const cancelSelectionElement = document.getElementById("cancel-selection");
  //   if (cancelSelectionElement) {
  //     const labelElement = cancelSelectionElement.querySelector(".label");
  //     if (labelElement) {
  //       if (data && data.length > 0) {
  //         labelElement.textContent = `+${data.length}`;
  //       } else {
  //         cancelSelectionElement.style.display = "none";
  //       }
  //     }
  //   }
  // }, [data, schema.items.options]);

  const formatData = (data) => (data ? data.map((item) => item.id) : []);

  const handleSelectValue = (val) => {
    setSelected(val);

    handleChange(path, formatData(val));
  };

  return visible ? (
    <div className="multiple-select-wrap">
      <Dropdown
        label={label}
        childList={schema.items.options}
        selection={uischema?.selection === "single" ? "single" : "multiple"}
        isRequired={required}
        disabled={!enabled}
        selectedValue={selected || ""}
        placeholder={uischema.placeholder}
        setSelectedValue={handleSelectValue}
      />
    </div>
  ) : null;
};

export default withJsonFormsMultiEnumProps(MultipleSelect);
