import { Button } from "pepsico-ds";
import { useNavigate, useParams } from "react-router-dom";
import { MainCard } from "../../components/common";
import { paths } from "../../routes/paths";
import SummaryPage from "./setupPages/SummaryPage";

const CampaignSummaryViewPage = ({ campaignId }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  console.log("id", id);
  return (
    <>
      {!campaignId && (
        <MainCard styles={{ justifyContent: "space-between" }}>
          <Button
            onClick={() => navigate(`/${paths.campaigns}/${paths.campaign_list}`)}
            style={{ flex: 1, justifyContent: "flex-start" }}
            iconLeading="arrow_back"
            size="small"
            variant="borderless"
          >
            <span className="font-xs">Back to Campaign Listing</span>
          </Button>
          <h2 style={{ textAlign: "center", flex: 1 }}>Campaign Summary</h2>
        </MainCard>
      )}
      <SummaryPage campaignId={campaignId || id} isOnPage={!campaignId} />
    </>
  );
};

export default CampaignSummaryViewPage;
