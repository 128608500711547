import { Chip } from "@mui/material";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import "./SystemSettingTable.scss";

const columns = ["agreement"];
const getCampaignActionButtons = ({ item, onClickEdit, onClickView }) => {
  return [
    {
      icon: "edit",
      onClick: () => onClickEdit(item),
    },
    {
      icon: "visibility",
      onClick: () => onClickView(item),
    },
  ];
};
const TermAndConditionTable = ({
  termAndConditionData,
  onClickEdit,
  onClickView,
  chip,
}) => {
  if (!termAndConditionData || termAndConditionData.length === 0) {
    return <p>No data available</p>;
  }
  // const columns = Object.keys(termAndConditionData[0]);
  return (
    <div className="list-container">
      <table className="list-table">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column}>
                {column.charAt(0).toUpperCase() + column.slice(1)}
              </th>
            ))}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {termAndConditionData.map((item, index) => {
            const actionButtons = getCampaignActionButtons({
              item,
              onClickEdit,
              onClickView,
            });
            return (
              <tr key={index}>
                <td>
                  {item?.contentData?.title || "-"}
                  {chip && (
                    <span style={{ paddingLeft: "10px" }}>
                      {item?.contentData?.is_required && (
                        <Chip
                          sx={{ backgroundColor: "#AAD2E2", width: "150px" }}
                          size="small"
                          label={
                            item?.contentData?.is_required
                              ? "Required to accept"
                              : ""
                          }
                        />
                      )}
                    </span>
                  )}
                </td>

                <td>
                  <div className="button-group-wrapper">
                    <ButtonGroupDynamic
                      buttons={actionButtons}
                      spacing="0px"
                      size="medium"
                      variant="control"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TermAndConditionTable;
