import PropTypes from "prop-types";
import React from "react";
import ButtonGroupDynamic from "../common/ButtonGroupDynamic";
import { codeGroupActionButton } from "./codeGroupActionButton";
import "./codeGroupTable.scss";

const CodeGroupTable = ({ codeGroupData }) => {
  return (
    <div className="widget-list-container">
      <table className="widget-list-table">
        <thead>
          <tr>
            <th className="column-status">Administration Status</th>
            <th className="column-title">Code Group Name</th>
            <th className="column-description">Producer Company</th>
            <th className="column-total-codes">Code Count</th>
            <th className="column-used-codes">Used Code Count</th>
            <th className="column-active-date">Active Date</th>
            <th className="column-inactive-date">Inactive Date</th>
            <th className="column-code-upload-status">Lifecycle Status</th>
            <th className="column-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {codeGroupData.map((item, index) => {
            // Destructure nested fields with default values
            const basicSetup = item.basicSetup || {};
            const producerPackaging = item.producerPackaging || {};

            const codeGroupName = basicSetup.codeGroupName || "-";
            const codeActiveStartDate = basicSetup.codeActiveStartDate || null;
            const codeActiveEndDate = basicSetup.codeActiveEndDate || null;

            const producerCompany = producerPackaging.producerCompany || "-";
            const codesPrintedCount =
              producerPackaging.codesPrintedCount !== undefined &&
              producerPackaging.codesPrintedCount !== null
                ? producerPackaging.codesPrintedCount
                : "-";

            const codesGeneratedCount =
              item.codesGeneratedCount !== undefined &&
              item.codesGeneratedCount !== null
                ? item.codesGeneratedCount
                : "-";

            const administrationStatus = item.administrationStatus || "-";
            const lifecycleStatus = item.lifecycleStatus || "-";

            // Ensure administrationStatus is a string before calling toLowerCase
            const adminStatusLower =
              typeof administrationStatus === "string"
                ? administrationStatus.toLowerCase()
                : "";

            return (
              <tr key={index}>
                <td className="widget-status-type">
                  <span
                    className={
                      adminStatusLower === "active"
                        ? "status active"
                        : "status inactive"
                    }
                  >
                    {administrationStatus}
                  </span>
                </td>
                <td
                  className={`${/\s/.test(codeGroupName) ? "" : "no-space-title"}`}
                >
                  {codeGroupName}
                </td>
                <td>{producerCompany}</td>
                <td>{codesGeneratedCount}</td>
                <td>{codesPrintedCount}</td>
                <td>
                  {codeActiveStartDate
                    ? new Date(codeActiveStartDate).toLocaleDateString()
                    : "-"}
                </td>
                <td>
                  {codeActiveEndDate
                    ? new Date(codeActiveEndDate).toLocaleDateString()
                    : "-"}
                </td>
                <td>{lifecycleStatus}</td>
                <td>
                  <div className="button-group-wrapper">
                    <ButtonGroupDynamic
                      buttons={codeGroupActionButton(adminStatusLower === "active")}
                      spacing="0px"
                      size="medium"
                      variant="control"
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

CodeGroupTable.propTypes = {
  codeGroupData: PropTypes.arrayOf(
    PropTypes.shape({
      administrationStatus: PropTypes.string,
      lifecycleStatus: PropTypes.string,
      codesGeneratedCount: PropTypes.number,
      basicSetup: PropTypes.shape({
        codeGroupName: PropTypes.string,
        codeActiveStartDate: PropTypes.string,
        codeActiveEndDate: PropTypes.string,
      }),
      producerPackaging: PropTypes.shape({
        producerCompany: PropTypes.string,
        codesPrintedCount: PropTypes.number,
      }),
    })
  ).isRequired,
};

export default CodeGroupTable;
