import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import { partnersActionButtons } from "../partnersActionButton";
import "./PartnerListTable.scss";

const PartnerListTable = ({ partnerData }) => {
  return (
    <div className="widget-list-container">
      <table className="widget-list-table">
        <thead>
          <tr>
            <th className="column-status">Status</th>
            <th className="column-format">Title</th>
            <th className="column-name"> Name</th>
            <th className="column-type">Description</th>
            <th className="column-actions">Actions</th>
          </tr>
        </thead>
        <tbody>
          {partnerData.map((item, index) => (
            <tr key={index}>
              <td className="widget-status-type">
                <span
                  className={item.isActive ? "status active" : "status inactive"}
                >
                  {item.isActive ? "Active" : "Inactive"}
                </span>
              </td>
              <td className={`${/\s/.test(item.title) ? "" : "no-space-title"}`}>
                {item.title || "-"}
              </td>
              <td>{item.name}</td>
              <td>{item.description}</td>

              <td>
                <div className="button-group-wrapper">
                  <ButtonGroupDynamic
                    buttons={partnersActionButtons(partnerData.isActive)}
                    spacing="0px"
                    size="medium"
                    variant="control"
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PartnerListTable;
