import { TextInput } from "pepsico-ds";
import { useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import "./TextInputFieldHexaColor.scss";

export default function TextInputFieldHexaColor(props) {
  const { value, updateValue, label, disabled } = props;

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const pickerRef = useRef(null);

  const addHexadecimal = (hex) => {
    if (typeof hex !== "string") {
      return;
    }
    const isHexColor = /^#?([0-9A-F]{3}){1,2}$/i.test(hex);

    if (!hex.startsWith("#") && isHexColor) {
      return `#${hex}`;
    } else {
      return hex;
    }
  };

  const squadStyle = {
    width: 28,
    height: 28,
    cursor: "pointer",
    backgroundColor: addHexadecimal(value),
  };

  const handleColorChange = (color) => {
    updateValue(color.hex);
  };

  const toggleColorPicker = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setDisplayColorPicker(false);
    }
  };

  useEffect(() => {
    if (displayColorPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [displayColorPicker]);

  return (
    <div className="text-input-hexa-color-wrapper">
      <div
        style={
          props.disabled
            ? { ...squadStyle, backgroundColor: "#D0D0D0", cursor: "default" }
            : squadStyle
        }
        className="input-squad-color"
        onClick={props.disabled ? null : toggleColorPicker}
      ></div>
      <TextInput
        label={label}
        onUpdate={updateValue}
        value={value}
        disabled={disabled}
        placeholderText="#FFFFFF"
        onChange={(e) => updateValue(e.target.value)}
      />
      {displayColorPicker && (
        <div
          style={{ position: "absolute", zIndex: 999, top: "-208px" }}
          ref={pickerRef}
        >
          <ChromePicker color={value} onChange={handleColorChange} disableAlpha />
        </div>
      )}
    </div>
  );
}
