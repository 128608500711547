import styled from "styled-components";

export const SingleBorderCard = styled.div`
  border-radius: 5px;
  box-shadow: ${(props) => props.boxShadow || "none"};
  border: ${(props) => props.border || "1px solid #aad2e2"};
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #fff;
  padding: 1rem;
  width: ${(props) => props.width || "fit-content"};
`;
