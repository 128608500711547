import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Icon } from "pepsico-ds";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";

const uiSchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/quantity",
          component: "textField",
          label: "Qty",
        },
        {
          type: "Control",
          scope: "#/properties/totalUserWinLimit",
          component: "textField",
          label: "Win limit",
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/walletWithdrawalDate",
      component: "dateTimePicker",
      minimum: "currentDateTime",
      label: "Wallet Withdrawal Date",
    },
    {
      type: "Control",
      scope: "#/properties/startDate",
      minimum: "currentDateTime",
      component: "dateTimePicker",
      label: "Display start date",
    },
    {
      type: "Control",
      scope: "#/properties/endDate",
      minimum: "currentDateTime",
      rule: {
        condition: {
          schema: {
            gt: "startDate",
          },
        },
        effect: "VALIDATE",
      },
      component: "dateTimePicker",
      label: "Display end date",
    },
  ],
};

const jsonSchema = {
  title: "Basic & Rules",
  type: "object",
  properties: {
    quantity: {
      type: "integer",
    },
    totalUserWinLimit: {
      type: "integer",
    },
    walletWithdrawalDate: {
      type: "string",
    },
    startDate: {
      type: "string",
    },
    endDate: {
      type: "string",
    },
  },
  required: ["quantity", "totalUserWinLimit"],
};

const uiSchemaChanceToWin = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/quantity",
          component: "textField",
          label: "Qty",
        },
        {
          type: "Control",
          scope: "#/properties/totalUserWinLimit",
          component: "textField",
          label: "Win limit",
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/chanceToWinPercentage",
      component: "textField",
      label: "Chance to win (%)",
    },
    {
      type: "Control",
      scope: "#/properties/walletWithdrawalDate",
      component: "dateTimePicker",
      minimum: "currentDateTime",
      label: "Wallet Withdrawal Date",
    },
    {
      type: "Control",
      scope: "#/properties/startDate",
      component: "dateTimePicker",
      minimum: "currentDateTime",
      label: "Display start date",
    },
    {
      type: "Control",
      scope: "#/properties/endDate",
      minimum: "currentDateTime",
      component: "dateTimePicker",
      rule: {
        condition: {
          schema: {
            gt: "startDate",
          },
        },
        effect: "VALIDATE",
      },
      label: "Display end date",
    },
  ],
};

const jsonSchemaChanceToWin = {
  title: "Basic & Rules",
  type: "object",
  properties: {
    quantity: {
      type: "integer",
    },
    totalUserWinLimit: {
      type: "integer",
    },
    chanceToWinPercentage: {
      type: "integer",
    },
    walletWithdrawalDate: {
      type: "string",
    },
    startDate: {
      type: "string",
    },
    endDate: {
      type: "string",
    },
  },
  required: ["quantity", "totalUserWinLimit", "chanceToWinPercentage"],
};

const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};


const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "#e9ebf0" : "#fff",
  width: "100%",
  position: "relative",
});

const RewardItem = ({ reward, index, listRewards, onRewardSelect, updateRewardData, type }) => {
  const rewardName = listRewards?.find((r) => r.id === reward.rewardId)?.name;

  return (
    <Draggable key={reward.rewardId} draggableId={reward.rewardId} index={index}>
      {(provided) => (
        <Accordion
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
          className="selected-reward-accordion"
        >
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <div className="display-flex align-items-center justify-content-space-between w-100">
              <div className="display-flex align-items-center w-100">
                <Icon icon="drag_indicator" alt="Drag" size="small" variant="filled" className="mr-1" />
                {rewardName}
              </div>
              <Icon
                icon="close"
                alt="Close"
                size="small"
                variant="filled"
                className="mx-1"
                onClick={(e) => {
                  e.stopPropagation();
                  onRewardSelect(reward);
                }}
              />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <JsonFormsWrapper
              uischema={type === "list-rewards" ? uiSchema : uiSchemaChanceToWin}
              jsonschema={type === "list-rewards" ? jsonSchema : jsonSchemaChanceToWin}
              data={reward}
              onChange={updateRewardData}
            />
          </AccordionDetails>
        </Accordion>
      )}
    </Draggable>
  );
};

export default function SelectedRewards({
  value = [],
  onRewardSelect,
  updateRewardData,
  type,
  listRewards,
  handleOnDragEnd,
}) {
  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <StrictModeDroppable droppableId="rewards">
        {(provided, snapshot) => (
          <div
            className="p-3"
            style={{
              backgroundColor: "#F3F7FD",
              borderRadius: "8px",
              maxHeight: "253px",
              overflowY: "scroll",
              ...getListStyle(snapshot.isDraggingOver),
            }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <h5 className="$font-xsmall-bold">{value?.length || 0} selected rewards</h5>
            {value?.map((reward, index) => (
              <RewardItem
                key={reward.rewardId}
                reward={reward}
                index={index}
                listRewards={listRewards}
                onRewardSelect={onRewardSelect}
                updateRewardData={updateRewardData}
                type={type}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
}
