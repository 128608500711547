import { useContext } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { CustomSideBar, Header } from "../components/common";
import config from "../config/global-config.json";
import { GlobalConfigContext } from "../context/GlobalConfigContext";

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  background: #f3f7fd;
`;
const Body = styled.div`
  display: flex;
  height: 91.5vh;
  max-width: 100vw;
`;

const Main = styled.main`
  height: 100%;
  overflow: auto;
  flex: 1;
  width: 100%;
`;

export default function Layout() {
  const { header: headerConfig } = config;

  const { globalState } = useContext(GlobalConfigContext);

  return (
    <StyledLayout>
      <Header
        headerConfig={headerConfig}
        programConfig={globalState?.programConfig}
      />
      <Body>
        <CustomSideBar />
        <Main>
          <Outlet />
        </Main>
      </Body>
    </StyledLayout>
  );
}
