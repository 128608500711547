import { Card, Tooltip } from "pepsico-ds";
import ButtonGroupDynamic from "../../common/ButtonGroupDynamic";
import { segmentActionButtons } from "../segmentActionButtons";
import "./segmentCard.scss";
import { useNavigate } from "react-router-dom";

const SegmentCard = ({ segment, onClick }) => {
  const navigate = useNavigate();
  const { name, population, status, type, rules } = segment;
  console.log();
  return (
    <Card
      className="segment-card-container"
      icon=""
      ellipsis=""
      onClick={onClick}
      style={{
        padding: "0px",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        backgroundColor: "#fff",
      }}
    >
      <div className="segment-card-container">
        <div className="segment-details">
          <div className="segment-status-type">
            <span
              className={`status ${status === "ENABLED" ? "active" : "inactive"}`}
            >
              {status === "ENABLED" ? "Active" : "Inactive"}
            </span>
            <span className="type">{type}</span>
          </div>
          <h3 className="segment-name">
            {name?.length > 40 ? (
              <Tooltip direction="right" text={name}>
                {name.substring(0, 60)}...
              </Tooltip>
            ) : (
              name
            )}
          </h3>
          <div className="segment-meta">
            <div className="meta-item">
              <span className="meta-label">Population</span>
              <span>{population ? population.toLocaleString() : "N/A"}</span>{" "}
              {/* Format population with commas */}
            </div>
            <div className="meta-item">
              <span className="meta-label">Description</span>
              <span>{rules}</span>
            </div>
          </div>

          <div className="button-group-container">
            <ButtonGroupDynamic
              buttons={segmentActionButtons(status, navigate, segment?.id)}
              spacing="1px"
              size="small"
              variant="control"
            />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default SegmentCard;
