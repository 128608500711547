import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { v4 as uuidv4 } from "uuid";
const removeTypenameLink = removeTypenameFromVariables();

export const ApolloWrapperProvider = ({ children }) => {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
    }
  });

  const authLink = new ApolloLink((operation, forward) => {
    const sessionId = uuidv4();

    const token = JSON.parse(localStorage.getItem("okta-token-storage"))?.accessToken
      ?.accessToken;

    if (!localStorage.getItem("sessionId")) {
      localStorage.setItem("sessionId", sessionId);
    }

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        "x-request-id": sessionId,
        "x-session-id": localStorage.getItem("sessionId") || sessionId,
      },
    }));

    return forward(operation);
  });

  const httpLink = createHttpLink({
    uri: window.REACT_APP_HOST_API,
    fetch: (uri, options) => {
      return fetch(uri, {
        ...options,
      });
    },
  });

  const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink, removeTypenameLink]),
    cache: new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Query: {
          fields: {
            items: {
              merge(incoming, existing = []) {
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network", // Use cache and then update with network response
        nextFetchPolicy: "cache-first", // Subsequent queries will use cache first
      },
      query: {
        fetchPolicy: "no-cache", // No cache for individual queries
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
