import { gql } from "@apollo/client";

const filterContentBySegment = gql`
  query ListContent($filter: ContentFilter!) {
    listContent(filter: $filter) {
      id
      title
      slug
      segments
      description
      status
      paused
      contentData
      tags
      category
    }
  }
`;

export default filterContentBySegment;
