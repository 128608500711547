import { gql } from "@apollo/client";

export const loadCampaignById = gql`
  query LoadCampaignById($id: String) {
    loadCampaignById(id: $id) {
      id
      type
      title
      endless
      titleShort
      description
      position
      category
      campaignStatus
      programId
      channel
      mediaCampaignId
      slug
      excludeFromListing
      campaignGroupId
      hasLeaderboard
      hasRewardUnlock
      brands
      publishingStatus
      approvalStatus
      displayStartDate
      displayEndDate
      archivedAt
      activeStartDate
      activeEndDate
      paused
      unlockedCatalogs
      lockedCatalogs
      leaderboardCatalogs
      segments
      groups
      tags
      rewardCategories
      hasShowEntriesCount
      hasShowInHistory
      displayAfterEndDate
      token
      relatedCampaigns
      campaignRules {
        id
        ruleData
        ruleDefinition {
          id
          name
          hidden
          inputSchema
          ruleFunction
          maxAllowed
          slug
          uiSchema
        }
      }
      activities {
        id
        name
        description
        outcomes {
          outcomeDefinition {
            id
            name
            description
            inputSchema
            requiredRules
            slug
            uiSchema
            rules {
              id
              name
              hidden
              inputSchema
              ruleFunction
              maxAllowed
              slug
              uiSchema
            }
          }
          outcomeData
          outcomeRules {
            ruleData
            ruleDefinition {
              id
              name
              hidden
              inputSchema
              ruleFunction
              maxAllowed
              slug
              uiSchema
            }
          }
        }
        activityRules {
          ruleData
          ruleDefinition {
            id
            name
            hidden
            inputSchema
            ruleFunction
            maxAllowed
            slug
            uiSchema
          }
        }
        activityDefinition {
          id
          name
          description
          inputSchema
          requiredRules
          slug
          maxAllowed
          uiSchema
          rules {
            id
            name
            hidden
            inputSchema
            ruleFunction
            maxAllowed
            slug
            uiSchema
          }
          outcomes {
            id
            name
            description
            inputSchema
            requiredRules
            slug
            uiSchema
            rules {
              id
              name
              hidden
              inputSchema
              ruleFunction
              maxAllowed
              slug
              uiSchema
            }
          }
          requiredOutcomes
        }
          activityData
      }
      outcomes {
        outcomeDefinition {
          name
          description
          inputSchema
          requiredRules
          slug
          uiSchema
          rules {
            name
            hidden
            inputSchema
            ruleFunction
            maxAllowed
            slug
            uiSchema
            id
          }
          id
        }
        outcomeData
        outcomeRules {
          ruleData
          ruleDefinition {
            id
            name
            hidden
            inputSchema
            ruleFunction
            maxAllowed
            slug
            uiSchema
          }
        }
      }
      campaignContent {
        id
        title
        slug
        segments
        description
        status
        activeStartDate
        activeEndDate
        contentData
        tags
        category
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;
