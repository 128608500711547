import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import "./_filter-select.scss";

const FilterSelectMultiple = (props) => {
  const { schema, handleChange, path, data } = props;
  const items = schema.items.oneOf || schema.items.enum;

  const [selected, setSelected] = useState(data || []);
  const handleSelectFilter = (_, filters) => {
    setSelected(filters);
    handleChange(path, filters);
  };

  return (
    <div className="filter-select-container">
      <span className="font-sm font-bold">{schema.label}</span>
      <ToggleButtonGroup value={selected} onChange={handleSelectFilter}>
        {items.map((item) => {
          const value = item.const || item;
          const title = item.title || item;
          return (
            <ToggleButton key={value} value={value}>
              {title}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
    </div>
  );
};

export default withJsonFormsMultiEnumProps(FilterSelectMultiple);
