import { useQuery } from "@apollo/client";
import Table from "../../../../components/common/table/Table";
import { listSegments } from "../../../../graphql/queries/listSegments";
import SummaryCard from "./SummaryCard";

const SegmentsLinkedCampaigns = ({
  segmentedData,
  relatedData,
  isViewScreen,
  editSectionHandler,
}) => {
  const { data: segmentsData } = useQuery(listSegments);
  let headerData;
  if (window.REACT_APP_PROGRAM === "ptr") {
    headerData = [
      { columnKey: "campaignName", header: "Campaign Name" },
      { columnKey: "segments", header: "Segments" },
      { columnKey: "rewardTypes", header: "" },
      { columnKey: "numberOfRewards", header: "" },
    ];
  } else {
    headerData = [
      { columnKey: "campaignName", header: "Campaign Name" },
      { columnKey: "segments", header: "Segments" },
      { columnKey: "rewardTypes", header: "Reward Types" },
      { columnKey: "numberOfRewards", header: "# of Rewards" },
    ];
  }
  const rewardTypeNames = {
    CHANCE_TO_WIN: "Chance to Win",
    LIST: "List",
  };

  const rowData = relatedData.map((campaign) => ({
    campaignName: campaign?.title || "-",
    segments:
      campaign.segments
        ?.map(
          (segmentId) =>
            segmentsData?.listSegments?.find((segment) => segment?.id === segmentId)
              ?.name
        )
        .join(", ") || "-",
    rewardTypes:
      [
        ...new Set(
          campaign.rewardGroups?.map((group) => rewardTypeNames[group.type])
        ),
      ].join(", ") || "-",
    numberOfRewards:
      campaign.rewardGroups?.reduce((sum, group) => sum + group.amount, 0) || "-",
  }));
  return (
    <SummaryCard
      title="Segments/Linked Campaigns"
      isViewScreen={isViewScreen}
      handleEditSection={editSectionHandler}
    >
      {segmentedData.length > 0 && (
        <div className="segmented-contents">
          <h4 className="fs-14 mb-16">Segments Content</h4>
          {segmentedData.map((content) => (
            <div key={content?.segmentName} className="segment-item mb-16">
              {content.contentData?.campaign_image_url && (
                <img
                  src={content?.contentData?.campaign_image_url}
                  alt={`Segment ${content.segmentName}`}
                />
              )}
              <div className="segment-name">
                <span>{content.segmentName}</span>
              </div>
            </div>
          ))}
        </div>
      )}
      {relatedData.length > 0 && (
        <div className="related-campaigns">
          <h4 className="fs-14 mb-16">Related Campaigns</h4>
          <div className="campaign-list">
            <Table headerData={headerData} rowData={rowData} />
          </div>
        </div>
      )}
    </SummaryCard>
  );
};
export default SegmentsLinkedCampaigns;
