import { Card } from "pepsico-ds";
import { getDateWithFormat } from "../../utils/commons";
import ButtonGroupDynamic from "../common/ButtonGroupDynamic";
import "./survey.scss";

const cardStyles = {
  padding: "16px",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
  backgroundColor: "#fff",
};

const deletableStatuses = ["draft", "scheduled", "completed"];

const SurveyCard = ({ surveyData, actionButtons, currentProgramTimezone }) => {
  const { title, status, segments, activeStartDate, activeEndDate, contentData } =
    surveyData;

  const isDeletable = deletableStatuses.includes(status);
  const hasValidSegments = segments?.filter(Boolean).length > 0;
  return (
    <Card icon="" ellipsis="" style={cardStyles}>
      <div className="banner-image-wrapper">
        <img className="banner-image" src={contentData?.image_url} alt={"title"} />
      </div>
      <div className="card-header-survey">
        <div className={`status ${status.toLowerCase()} font-xxs`}>{status}</div>
        <div className="font-bold font-small title">{title}</div>
      </div>
      <div className="card-info font-xxs">
        {contentData.type && (
          <div className="meta-item">
            <span className="meta-label">Type</span>
            <span>{contentData.type}</span>
          </div>
        )}
        <div className="meta-item">
          <span className="meta-label">Segmentation</span>
          <span>{hasValidSegments ? "Yes" : "No"}</span>
        </div>
      </div>
      <div className="card-info font-xxs no-gap">
        {activeStartDate && (
          <div className="meta-item no-margin">
            <span className="meta-label"> Start Date</span>
            <span>{getDateWithFormat(activeStartDate, currentProgramTimezone)}</span>
          </div>
        )}
        {activeEndDate && (
          <div className="meta-item no-margin">
            <span className="meta-label">End Date</span>
            <span>{getDateWithFormat(activeEndDate, currentProgramTimezone)}</span>
          </div>
        )}
      </div>
      <div className={`button-group-container ${isDeletable ? "deletable" : ""}`}>
        <ButtonGroupDynamic
          buttons={actionButtons}
          spacing="0px"
          size="small"
          variant="control"
        />
      </div>
    </Card>
  );
};

export default SurveyCard;
