import { Tab, TabsNavigation } from "pepsico-ds";
import { useEffect, useState } from "react";
import SegmentDataTab from "../../../components/segmentation/SegmentsDataTab";
import SegmentsListTab from "../../../components/segmentation/SegmentsListTab";
import "./segmentListPage.scss";

export default function SegmentListPage() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [containerCardY, setContainerCardY] = useState(0);

  const handleResize = () => {
    setContainerCardY(
      Math.ceil(
        document
          .getElementsByClassName("container-card")[0]
          .getBoundingClientRect().top
      )
    );
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="px-3 pt-3">
      <div
        className="container-card"
        style={{ height: `calc(100vh - ${containerCardY}px)` }}
      >
        <div className="page-header">
          <h6 className="font-bold font-lg">Segments</h6>
        </div>
        <TabsNavigation
          className="tabs-wrap"
          style={{ borderBottom: "2px solid #D7E5F8" }}
          onUpdate={(index) => setSelectedTab(index)}
          selectedIndex={0}
        >
          <Tab label="Segment Data" />
          <Tab label="Segment List" />
        </TabsNavigation>
        {selectedTab === 0 && <SegmentDataTab />}
        {selectedTab === 1 && <SegmentsListTab />}
      </div>
    </div>
  );
}
