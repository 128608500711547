import { withJsonFormsMultiEnumProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";
import { useEffect, useState } from "react";
import { listKeywordGroups } from "../../../graphql/queries/listKeywordGroups";
import { useQuery } from "@apollo/client";

const BrandSelect = (props) => {
  const { handleChange, label, path, required, visible, enabled, data } = props;

  const [brandOptions, setBrandOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [hasLoadedData, setHasLoadedData] = useState(false);
  const { data: brandData } = useQuery(listKeywordGroups, {
    variables: { type: "BRAND" },
  });

  useEffect(() => {
    if (brandData) {
      setBrandOptions(brandData.listKeywordGroups);
      if (data?.length && !hasLoadedData) {
        setSelected(
          formatOptions(
            data.map(
              (item) =>
                brandData.listKeywordGroups.find(
                  (option) => option.id === item
                ) || {}
            )
          )
        );
        setHasLoadedData(true);
      }
    }
  }, [brandData, data, hasLoadedData]);

  const formatOptions = (options) =>
    options.map((option) => ({
      id: option.id,
      displayText: option.name,
    }));
  const formatData = (data) => (data ? data.map((item) => item.id) : []);

  const handleSelectValue = (val) => {
    setSelected(val);
    handleChange(path, formatData(val));
  };

  return visible ? (
    <div className="multiple-select-wrap">
      <Dropdown
        label={label}
        childList={formatOptions(brandOptions)}
        selection="multiple"
        isRequired={required}
        disabled={!enabled}
        selectedValue={selected}
        setSelectedValue={(val) => handleSelectValue(val)}
      />
    </div>
  ) : null;
};

export default withJsonFormsMultiEnumProps(BrandSelect);
