export const getSurveyStatusActionButtons = (
  survey,
  handleActionButtons,
  handleOpenConfirmationModal
) => {
  const actionButtons = [
    {
      icon: "visibility",
      onClick: () => {
        handleActionButtons("view", survey);
      },
    },
    // {
    //   icon: "content_copy",
    //   onClick: () => console.log("Copy"),
    // },
  ];

  const editableStatuses = ["inactive", "published", "active"];
  const deletableStatuses = ["completed", "inactive"];

  editableStatuses.includes(survey?.status?.toLowerCase()) &&
    actionButtons.push({
      icon: "edit",
      onClick: () => {
        handleActionButtons("edit", survey);
      },
    });
  deletableStatuses.includes(survey?.status?.toLowerCase()) &&
    actionButtons.push({
      icon: "delete",
      onClick: () => handleOpenConfirmationModal(survey),
    });
  return actionButtons;
};
