import { LoadingSpinner, Button as PepsicoButton } from "pepsico-ds";

const Button = ({
  variant,
  size,
  disabled,
  isLoading = false,
  loadingText,
  iconTrailing,
  iconLeading,
  children,
  ...props
}) => {
  const spinnerDimension = size === "small" ? "1rem" : "1.5rem";

  return (
    <PepsicoButton
      variant={isLoading ? "secondary" : variant}
      size={size}
      disabled={disabled || isLoading}
      iconTrailing={isLoading ? undefined : iconTrailing}
      iconLeading={isLoading ? undefined : iconLeading}
      {...props}
    >
      {isLoading && (
        <>
          <LoadingSpinner
            data-testid="loading-spinner"
            size={{
              height: spinnerDimension,
              width: spinnerDimension,
              marginRight: "0.5rem",
            }}
          />
          <span>{loadingText || "Loading..."}</span>
        </>
      )}
      {!isLoading && children}
    </PepsicoButton>
  );
};

export default Button;
