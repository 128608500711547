import { gql } from "@apollo/client";

export const listRewards = gql`
  query ListRewards($filter: RewardFilterInput) {
    listRewards(filter: $filter) {
      id
      name
      description
      partnerId
      programId
      partnerCodeType
      rewardCategoryId
      imageUrl
      wheelImageUrl
      isActive
    }
  }
`;
