import { withJsonFormsControlProps } from "@jsonforms/react";
import TextAreaField from "./TextAreaField";

const CustomTextAreaControl = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
    enabled,
    visible,
  } = props;
  return (
    visible && (
      <TextAreaField
        value={data ?? schema.default}
        label={label}
        updateValue={(newVal) => handleChange(path, newVal)}
        errors={errors}
        schema={schema}
        uiSchema={uischema}
        placeholder={uischema.placeholder}
        required={required}
        showToolBar={uischema.showToolBar}
        disabled={!enabled}
      />
    )
  );
};

export default withJsonFormsControlProps(CustomTextAreaControl);
