import { withJsonFormsControlProps } from "@jsonforms/react";
import MatchKeywordsComponent from "../../campaign/ActivitySetup/keywordsGroup/MatchKeywordsComponent";

const MatchKeywordController = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
  } = props;
  return (
    <MatchKeywordsComponent
      selectedIds={data}
      setSelectedIds={(newVal) => handleChange(path, newVal)}
      label={label}
      errors={errors}
      schema={schema}
      filterBy={schema.filter_by}
      uiSchema={uischema}
      required={required}
    />
  );
};

export default withJsonFormsControlProps(MatchKeywordController);
