import { Button } from "pepsico-ds";
import { useContext } from "react";
import CodeGroupsCard from "../../components/codeGroups/CodeGroupCard";
import CodeGroupTable from "../../components/codeGroups/CodeGroupTable";
import {
  ListingComponentContext,
  ListingComponentProvider,
} from "../../components/common/listingComponents/ListingComponentContext";
import ListingComponentWrapper from "../../components/common/listingComponents/ListingComponentWrapper";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import { listCodeGroups } from "../../graphql/queries/listCodeGroups";
import "./codeGroups.scss";
import {
  codeGroupFilterJsonSchema,
  codeGroupFilterUiSchema,
  debouceCodeGroupFilterJsonSchema,
  debounceCodeGroupFilterUiSchema,
} from "./mocks";

const CodeGroupsListPage = () => {
  return (
    <ListingComponentProvider
      query={listCodeGroups}
      fetchPolicy="cache-and-network"
      queryKey="listCodeGroups"
      initialFilterData={{}}
      noPagination={false} // Set to true if you don't want pagination
    >
      <CodeGroupsListContent />
    </ListingComponentProvider>
  );
};

const CodeGroupsListContent = () => {
  const { viewableRecords, viewType } = useContext(ListingComponentContext);
  const { programTimezone } = useContext(GlobalConfigContext);

  return (
    <div className="px-3 pt-3">
      <div className="container-card">
        <div className="codegroups-page-content">
          <div className="codegroups-header">
            <h2>Code Groups</h2>
            <Button size="medium" iconTrailing="add">
              Create Code Groups
            </Button>
          </div>
          <ListingComponentWrapper
            customStyles={{ flex: 1 }}
            filterClasses="codegroups-filter-styles"
            filterConfig={{
              withDebounce: {
                jsonSchema: debouceCodeGroupFilterJsonSchema,
                uiSchema: debounceCodeGroupFilterUiSchema,
              },
              withoutDebounce: {
                jsonSchema: codeGroupFilterJsonSchema,
                uiSchema: codeGroupFilterUiSchema,
              },
            }}
          >
            {viewType === "grid" ? (
              <div className="codegroups-grid-container">
                {viewableRecords.map(
                  (group, index) => (
                    console.log("view", group),
                    (
                      <div className="codegroups-grid-item" key={index}>
                        <CodeGroupsCard
                          key={index}
                          group={group}
                          currentProgramTimezone={programTimezone}
                        />
                      </div>
                    )
                  )
                )}
              </div>
            ) : (
              <CodeGroupTable codeGroupData={viewableRecords} />
            )}
          </ListingComponentWrapper>
        </div>
      </div>
    </div>
  );
};

export default CodeGroupsListPage;
