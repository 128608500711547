import { DatePickerInput } from "pepsico-ds";
import * as React from "react";

export default function DatePickerField(props) {
  const { value, updateValue, label, uiSchema } = props;
  const ref = React.useRef();

  React.useEffect(() => {
    const lastEle = ref.current?.lastChild?.lastChild;
    const config = { attributes: true, childList: true, subtree: true };
    const callback = (mutationList) => {
      for (const mutation of mutationList) {
        // if (mutation.type === "childList") {
        if (mutation.type === "attributes") {
          if (lastEle.style.opacity === "0") {
            lastEle.style.display = "none";
          } else {
            lastEle.style.display = "block";
          }
        }
      }
    };
    const observer = new MutationObserver(callback);
    observer.observe(lastEle, config);

    return () => observer.disconnect();
  }, [ref]);

  // const formatedDate = React.useMemo(() => {
  //   if (!value) {
  //     return "";
  //   }
  //   console.log(value);
  //   const dt = new Date(value);
  //   console.log(dt);
  //   const month =
  //     dt.getMonth() + 1 < 10 ? `0${dt.getMonth() + 1}` : dt.getMonth() + 1;
  //   const date = dt.getDate();
  //   const year = dt.getFullYear();
  //   return `${month}/${date}/${year}`;
  // }, [value]);
  // console.log(formatedDate);

  return (
    <div ref={ref}>
      <DatePickerInput
        // helperText="month/date/year"
        label={label}
        placeholder={uiSchema.placeholder || "mm/dd/yyyy"}
        // required
        selected={value}
        // selected={formatedDate}
        setSelected={(val) => {
          // console.log(val);
          // console.log(moment.tz(val));
          updateValue(val);
          // updateValue(moment.tz(val).toISOString());
        }}
      />
    </div>
  );
}
