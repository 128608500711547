import { withJsonFormsControlProps } from "@jsonforms/react";
import RadioInputField from "./RadioInputField";

const RadioInputControl = (props) => {
  const { handleChange, label, data, path, errors, schema, uischema, required, enabled } = props;

  const isNumber = schema.type === "number";

  return (
    <RadioInputField
      path={path}
      value={data}
      label={label}
      disabled={!enabled}
      updateValue={(newVal) => {
        const val = isNumber ? Number(newVal) : newVal;
        handleChange(path, val);
      }}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
    />
  );
};

export default withJsonFormsControlProps(RadioInputControl);
