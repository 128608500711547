import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Snackbar,
} from "@mui/material";
import { Button, Toggle } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import LoaderContext from "../../context/LoaderContext";
import { SurveyContext } from "../../context/SurveyContext";
import { useSurveyHandlerHook } from "../../hooks/useSurveyHandlerHook";
import JsonFormsWrapper from "../jsonForms/jsonFormsWrapper/JsonFormsWrapper";
import "./survey.scss";
import {
  createSurveyJsonSchema,
  createSurveyUiSchema,
} from "./surveyComponentMocks";

function CreateSurveyModal({
  createSurveyModalOpen,
  toggleCreateSurveyModal,
  actionStatus,
}) {
  const {
    surveyState,
    setSurveyState,
    initialState,
    load,
    handleSaveSurvey,
    errMsg,
  } = useContext(SurveyContext);
  const { handleSurvey } = useSurveyHandlerHook();
  const { setIsLoading } = useContext(LoaderContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const isEditing = actionStatus === "edit";
  const isActive =
    surveyState?.status === "ACTIVE" || surveyState?.status === "INACTIVE";

  const handleCreate = async () => {
    setIsLoading(true);
    setFlag(true);
    const result = await handleSaveSurvey();
    setIsLoading(false);
    setFlag(false);

    if (result && !result.success) {
      setSnackbarMessage(result.message || "An error occurred while saving.");
      setSnackbarOpen(true);
      return;
    }

    toggleCreateSurveyModal();
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleIsActive = (e) => {
    const tempState = { ...surveyState };
    tempState["paused"] = !e;
    handleSurvey(tempState);
  };

  useEffect(() => {
    setIsLoading(load);
  }, [load]);

  useEffect(() => {
    if (errMsg.length > 0) {
      console.log("ERROR", errMsg);
      setSnackbarMessage(errMsg.join(", "));
      setSnackbarOpen(true);
    }
  }, [errMsg]);

  return (
    <>
      {/* Work around using MUI for now to avoid design issues.
    <Modal
      className="survey-modal"
      showModal={createSurveyModalOpen}
      onCloseModal={toggleCreateSurveyModal}
      title={"Create Survey/Test"}
      primaryButtonProps={{
        size: "medium",
        text: "Create",
        variant: "primary",
        onClick: handleCreate,
      }}
      showSecondaryButton={true}
      secondaryButtonProps={{
        size: "medium",
        text: "Preview",
        variant: "secondary",
      }}
      showTertiaryButton={false}
      showLink={false}>
      <div className="survey-mod">
        <JsonFormsWrapper
          jsonschema={createSurveyJsonSchema}
          uischema={createSurveyUiSchema(true)}
          data={formData}
          onChange={handleChange}
        />
      </div>
    </Modal> */}
      <Dialog
        className="survey-modal"
        open={createSurveyModalOpen}
        scroll="paper"
        sx={{ "& .MuiPaper-root": "70%" }}
      >
        <DialogTitle>
          <Grid container justifyContent="space-between" direction="row">
            <Grid item xs={10}>
              <h2 style={{ marginLeft: "18%", textAlign: "center" }}>
                {actionStatus && actionStatus === "edit"
                  ? "Edit Survey/Quiz"
                  : "Create New Survey/Quiz"}
              </h2>
            </Grid>
            <Grid item xs={1} display="flex" justifyContent="flex-end">
              <CloseIcon
                onClick={() => {
                  setSurveyState(initialState);
                  toggleCreateSurveyModal();
                }}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ overflowX: "hidden" }}>
          <div className="survey-mod">
            <h4 style={{ textAlign: "right", width: "100%" }}>*Required fields</h4>
            <JsonFormsWrapper
              jsonschema={createSurveyJsonSchema}
              uischema={createSurveyUiSchema(isEditing, isActive)}
              data={surveyState}
              onChange={(data) => handleSurvey(data)}
            />
          </div>
          <div className="bottom-section">
            {isActive && (
              <Toggle
                label="Active"
                onUpdate={handleIsActive}
                size="medium"
                checked={
                  surveyState?.status?.toLowerCase() === "active" ? true : false
                }
              />
            )}
            <div className="bottom-button">
              <Button onClick={handleCreate} disabled={flag}>
                {actionStatus && actionStatus === "edit" ? "Update" : "Create"}
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: "100%" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default CreateSurveyModal;
