import { Button, Tab, TabsNavigation } from "pepsico-ds";
import { useEffect, useState } from "react";
import CreatePartnerCodeModal from "../../components/partners/CreatePartnerCodeModal/CreatePartnerCodeModal";
import CreatePartnerModel from "../../components/partners/CreatePartnerModel";
import PartnersCodeGroupTab from "../../components/partners/PartnersCodeGroupTab";
import PartnersTab from "../../components/partners/PartnersTab";
import "./partners.scss";

export default function PartnersPage() {
  const [createPartnerCodeModalOpen, setCreatePartnerCodeModalOpen] =
    useState(false);
  const [createPartnerModalOpen, setCreatePartnerModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [containerCardY, setContainerCardY] = useState(0);

  const handleResize = () => {
    setContainerCardY(
      Math.ceil(
        document.getElementsByClassName("container-card")[0].getBoundingClientRect()
          .top
      )
    );
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleCreatePartnerCodeModal = () => {
    setCreatePartnerCodeModalOpen(!createPartnerCodeModalOpen);
  };

  const toggleCreatePartnerModal = () => {
    setCreatePartnerModalOpen(!createPartnerModalOpen);
  };

  return (
    <div className="px-3 pt-3">
      <div
        className="container-card"
        style={{ height: `calc(100vh - ${containerCardY}px)` }}
      >
        <div className="page-header">
          <h6 className="font-bold font-lg">List of Partners/Partner Code Groups</h6>
          <Button
            iconTrailingVariant="outlined"
            size="medium"
            iconTrailing="add"
            disabled={selectedTab === 1}
            variant={selectedTab === 0 ? "primary" : "secondary"}
            onClick={toggleCreatePartnerModal}
          >
            Create Partner
          </Button>
          <Button
            size="medium"
            iconTrailing="add"
            disabled={selectedTab === 0}
            variant={selectedTab === 1 ? "primary" : "secondary"}
            onClick={() =>
              setCreatePartnerCodeModalOpen(!createPartnerCodeModalOpen)
            }
          >
            Create Partner Code Groups
          </Button>
        </div>
        <TabsNavigation
          className="tabs-wrap"
          style={{ borderBottom: "2px solid #D7E5F8" }}
          onUpdate={function Ya(index) {
            setSelectedTab(index);
          }}
          selectedIndex={0}
        >
          <Tab label="Partner" />
          <Tab label="Partners Code Groups" />
        </TabsNavigation>
        {selectedTab === 0 && <PartnersTab />}
        {selectedTab === 1 && <PartnersCodeGroupTab />}
      </div>
      {createPartnerModalOpen && (
        <CreatePartnerModel
          createPartnerModalOpen={createPartnerModalOpen}
          toggleCreatePartnerModal={toggleCreatePartnerModal}
        />
      )}
      {createPartnerCodeModalOpen && (
        <CreatePartnerCodeModal
          createPartnerCodeModalOpen={createPartnerCodeModalOpen}
          toggleCreatePartnerCodeModal={toggleCreatePartnerCodeModal}
        />
      )}
    </div>
  );
}
