export const segmentationFilterJsonSchema = {
  title: "Segmentation Filter",
  type: "object",
  properties: {
    status: {
      type: "string",
      label: "Status",
      items: {
        type: "string",
        options: [
          { id: "ENABLED", displayText: "Active" },
          { id: "DISABLED", displayText: "Inactive" },
        ],
      },
      enum: ["ENABLED", "DISABLED"],
    },
  },
};

export const segmentationFilterUISchema = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/status",
      component: "muiSelect",
      selection: "single",
      placeholder: "Status",
    },
  ],
};
