import { useQuery } from "@apollo/client";
import { createContext, useCallback, useEffect, useRef, useState } from "react";
import { debounceFn } from "../../../utils/commons.js";

const ListingComponentContext = createContext();

const ListingComponentProvider = ({
  children,
  query,
  fetchPolicy,
  queryKey,
  initialFilterData,
  noPagination,
}) => {
  const [filterData, setFilterData] = useState(initialFilterData || {});
  const [debouncedFilterData, setDebouncedFilterData] = useState({}); // separate state for debounced fields
  const [pagination, setPagination] = useState({
    currentPage: 1,
    rowsCount: noPagination ? null : 12,
  });
  const allRecords = useRef([]);
  const [viewableRecords, setViewableRecords] = useState([]);

  const [viewType, setViewType] = useState("grid");

  const [totalPageCount, setTotalPageCount] = useState(1);
  const { data, loading, error, refetch } = useQuery(query, {
    variables: {
      filter: { ...filterData, ...debouncedFilterData },
    },
    fetchPolicy: fetchPolicy || "cache-and-network",
  });
  const filteredRecords = data?.[queryKey] || [];

  useEffect(() => {
    if (!allRecords.current?.length && data?.[queryKey]) {
      allRecords.current = data[queryKey];
    }
  }, [data]);

  useEffect(() => {
    if (data && data[queryKey]) {
      if (noPagination) {
        setViewableRecords(data[queryKey]);
      } else {
        const filteredRecords = data[queryKey];
        const start = (pagination.currentPage - 1) * pagination.rowsCount;
        const end = start + pagination.rowsCount;
        const viewableRecords = filteredRecords.slice(start, end);
        setViewableRecords(viewableRecords);
        setTotalPageCount(Math.ceil(filteredRecords.length / pagination.rowsCount));
      }
    }
    if (error) {
      setViewableRecords([]);
    }
  }, [data, pagination]);

  useEffect(() => {
    if (!noPagination) {
      setPagination({ ...pagination, currentPage: 1 });
    }
    refetch({ filter: { ...filterData, ...debouncedFilterData } });
  }, [filterData]);

  const delayedRefetch = useCallback(
    debounceFn((value) => {
      refetch({ filter: { ...debouncedFilterData, ...filterData, ...value } });
    }, 1500),
    []
  );

  const handleDebouncedFilterChange = (data) => {
    setDebouncedFilterData(data);
    delayedRefetch(data);
    setPagination({ ...pagination, currentPage: 1 });
  };

  const handleClearFilters = () => {
    setFilterData(initialFilterData);
    setDebouncedFilterData({});
  };

  return (
    <ListingComponentContext.Provider
      value={{
        filterData,
        debouncedFilterData,
        setDebouncedFilterData,
        handleDebouncedFilterChange,
        pagination,
        viewType,
        totalPageCount,
        setTotalPageCount,
        handleClearFilters,
        setFilterData,
        setViewType,
        setPagination,
        refetch,
        data,
        allRecords: allRecords.current,
        filteredRecords,
        viewableRecords,
        loading,
        error,
      }}
    >
      {children}
    </ListingComponentContext.Provider>
  );
};

export { ListingComponentContext, ListingComponentProvider };
