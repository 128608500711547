import { Button } from "pepsico-ds";
import { useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <div className="h-100 display-flex flex-direction-column justify-content-center align-items-center">
      <h1 className="mb-2">404</h1>
      <p className="mb-4">Page not found</p>
      <Button onClick={() => navigate("/")}>Go Back</Button>
    </div>
  );
};
