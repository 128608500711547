import { gql } from "@apollo/client";

export const GENERATE_DAM_TOKEN = gql`
  mutation GenerateDamToken {
    generateDamToken {
      expiresAt
      accessToken
    }
  }
`;
