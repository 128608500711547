import { MultiSelectTester, ScopeTester } from "./testers";
// controllers
import BannerSegmentsController from "../ BannerSegmentsController/BannerSegmentsController";
import ArrayLayout, { arrayLayoutTester } from "../array/ArrayLayout";
import ArrayOfFields from "../arrayOfFields/ArrayOfFields";
import BrandSelect from "../brandSelect/BrandSelect";
import CampaignAddRewardControl from "../campaignAddRewardField/CampaignAddRewardControl";
import CampaignSelect from "../campaignSelect/CampaignSelect";
import CampaignSelectedRewardControl from "../campaignSelectedRewardField/CampaignSelectedRewardControl";
import CategorySelect from "../categorySelect/CategorySelect";
import CheckboxControl from "../checkboxField/CheckboxControl";
import CodeGroupSelector from "../codeGroupSelector/CodeGroupSelector";
import DatePickerControl from "../datePicker/DatePickerControl";
import DateTimePickerControl from "../datePicker/DateTimePickerControl";
import DatePickerMaterialUIControl from "../datePickerMaterialUI/DatePickerMaterialUIControl";
import DaysPickerControl from "../daysPicker/DaysPickerControl";
import FileUploadController from "../fileUpload/FileUploadController";
import MultipleDocUploadControl from "../fileUpload/MultipleDocUploadControl";
import SingleImageUploadControl from "../fileUpload/SingleImageUploadControl";
import FilterSelect from "../filterSelect/FilterSelect";
import FilterSelectMultiple from "../filterSelect/FilterSelectMultiple";
import ListDropdownFilterControl from "../listDropdownFilter/ListDropdownFilterControl";
import MatchKeywordController from "../matchKeywordController/MatchKeywordController";
import CustomMUISelectControl from "../muiSelect/MUISelectControl";
import MultiStepSegmentSelect from "../multiStepSegmentSelect/multiStepSegmentSelect";
import MultiplePropertiesControl from "../multiplePropertiesField/MultiplePropertiesControl";
import MultipleIdSelector from "../multipleSelect/MultipleIdSelector";
import MultipleSelect from "../multipleSelect/MultipleSelect";
import NumberInputControl from "../numberInputField/NumberInputControl";
import PreviewCardsCarouselSweepstakes from "../previewWidget/PreviewCardsCarouselSweepstakes/PreviewCardsCarouselSweepstakes";
import PromoProductSelectorControl from "../promoProductsSelector/PromoProductSelectorControl";
import QuizSelect from "../quizSelect/QuizSelect";
import RadioControl from "../radioField/RadioControl";
import RadioInputControl from "../radioInputField/RadioInputControl";
import CustomRichTextEditorControl from "../richTextEditor/CustomRichTextEditorControl";
import SearchInput from "../searchInput/SearchInput";
import CampaignSegmentSelect from "../segmentSelect/CampaignSegmentSelect";
import SelectControl from "../select/SelectControl";
import SingleInputArrayControl from "../singleInputFieldArray/SingleInputArrayControl";
import SliderControl from "../slider/SliderControl";
import StringSelect from "../stringSelect/StringSelect";
import SwitchControl from "../switchField/SwitchControl";
import SwitchWithModalControl from "../switchField/SwitchWithModalControl";
import CampaignTagSelect from "../tagSelect/CampaignTagSelect";
import EmailDomainsComponent from "../textInputField/EmailDomainsComponent";
import TextInputControl from "../textInputField/TextInputControl";
import TextInputHexaColorControl from "../textInputFieldHexaColor/TextInputFieldHexaColorControl";
import TextInputArrayControl from "../textInputFieldsArray/TextInputArrayControl";
import TextAreaControl from "../textarea/TextAreaControl";

export const getScopesofAllElements = (schema) => {
  const listOfScopes = [];

  function traverse(obj) {
    if (obj && typeof obj === "object") {
      if (obj.scope) {
        listOfScopes.push({
          scope: obj.scope.split("#")[1],
          component: obj.component ? obj.component : "",
        });
      }
      if (obj.elements && Array.isArray(obj.elements)) {
        obj.elements.forEach((element) => {
          traverse(element);
        });
      }
      if (obj.options?.detail && Array.isArray(obj.options?.detail?.elements)) {
        obj.options.detail.elements.forEach((element) => {
          traverse(element);
        });
      }
    }
  }
  traverse(schema);
  return listOfScopes;
};

export const customerRenderesHelper = (scopeAndCompsList, data) => {
  return scopeAndCompsList?.map(({ component, scope }) => {
    switch (component) {
      case "textInputArray":
        return {
          tester: ScopeTester(scope),
          renderer: TextInputArrayControl,
        };
      case "textarea":
        return { tester: ScopeTester(scope), renderer: TextAreaControl };
      case "file":
        return {
          tester: ScopeTester(scope),
          renderer: FileUploadController,
        };
      case "singleImageUpload":
        return {
          tester: ScopeTester(scope),
          renderer: SingleImageUploadControl,
        };
      case "multiDocUpload":
        return {
          tester: ScopeTester(scope),
          renderer: MultipleDocUploadControl,
        };
      case "select":
        return { tester: ScopeTester(scope), renderer: SelectControl };
      case "checkbox":
        return { tester: ScopeTester(scope), renderer: CheckboxControl };
      case "radio":
        return { tester: ScopeTester(scope), renderer: RadioControl };
      case "toggle":
        return { tester: ScopeTester(scope), renderer: SwitchControl };
      case "switchWithConfirmation":
        return { tester: ScopeTester(scope), renderer: SwitchWithModalControl };
      case "datepicker":
        return { tester: ScopeTester(scope), renderer: DatePickerControl };
      case "muiSelect":
        return {
          tester: ScopeTester(scope),
          renderer: CustomMUISelectControl,
        };
      case "dateTimePicker":
        return {
          tester: ScopeTester(scope),
          renderer: (props) => <DateTimePickerControl {...props} otherData={data} />,
        };
      case "AddRewards":
        return {
          tester: ScopeTester(scope),
          renderer: (props) => (
            <CampaignAddRewardControl {...props} otherData={data} />
          ),
        };
      case "campaignSelectedReward":
        return {
          tester: ScopeTester(scope),
          renderer: CampaignSelectedRewardControl,
        };
      case "slider":
        return { tester: ScopeTester(scope), renderer: SliderControl };
      case "richTextEditor":
        return {
          tester: ScopeTester(scope),
          renderer: CustomRichTextEditorControl,
        };
      case "filterSelect":
        return {
          tester: ScopeTester(scope),
          renderer: FilterSelect,
        };
      case "multiSelect":
        return {
          tester: MultiSelectTester(scope),
          renderer: MultipleSelect,
        };
      case "multipleIdSelector":
        return {
          tester: ScopeTester(scope),
          renderer: MultipleIdSelector,
        };
      case "multiFilterSelect":
        return {
          tester: MultiSelectTester(scope),
          renderer: FilterSelectMultiple,
        };
      case "daysPicker":
        return {
          tester: ScopeTester(scope),
          renderer: DaysPickerControl,
        };
      case "datePickerMaterialUI":
        return {
          tester: ScopeTester(scope),
          renderer: DatePickerMaterialUIControl,
        };
      case "numberInput":
        return {
          tester: ScopeTester(scope),
          renderer: NumberInputControl,
        };
      case "textInputHexaColor":
        return {
          tester: ScopeTester(scope),
          renderer: TextInputHexaColorControl,
        };
      case "searchInput":
        return {
          tester: ScopeTester(scope),
          renderer: SearchInput,
        };
      case "object":
        return {
          tester: ScopeTester(scope),
          renderer: MultiplePropertiesControl,
        };

      // case 'array':
      //     return { tester: arrayLayoutTester, renderer: ArrayLayout };
      case "previewCardCampaign":
        return {
          tester: ScopeTester(scope),
          renderer: SearchInput,
        };
      case "previewCardSweepstakes":
        return {
          tester: ScopeTester(scope),
          renderer: PreviewCardsCarouselSweepstakes,
        };
      case "campaignTagSelect":
        return {
          tester: ScopeTester(scope),
          renderer: CampaignTagSelect,
        };
      case "radioInput":
        return {
          tester: ScopeTester(scope),
          renderer: RadioInputControl,
        };
      case "brandSelect":
        return {
          tester: ScopeTester(scope),
          renderer: BrandSelect,
        };
      case "categorySelect":
        return {
          tester: ScopeTester(scope),
          renderer: CategorySelect,
        };
      case "Label":
        return {
          tester: ScopeTester(scope),
          renderer: (props) => (
            <span className="$font-xsmall-bold">{props?.uischema?.text}</span>
          ),
        };
      case "segmentSelect":
        return {
          tester: ScopeTester(scope),
          renderer: CampaignSegmentSelect,
        };
      case "array":
        return {
          tester: arrayLayoutTester,
          renderer: ArrayLayout,
        };
      case "singleInputArray":
        return {
          tester: ScopeTester(scope),
          renderer: SingleInputArrayControl,
        };
      case "keywordController":
        return {
          tester: ScopeTester(scope),
          renderer: MatchKeywordController,
        };

      case "bannersegmentselect":
        return {
          tester: ScopeTester(scope),
          renderer: BannerSegmentsController,
        };
      case "arrayOfTexts":
        return {
          tester: ScopeTester(scope),
          renderer: ArrayOfFields,
        };
      case "stringSelect":
        return {
          tester: ScopeTester(scope),
          renderer: StringSelect,
        };
      case "quizSelect":
        return {
          tester: ScopeTester(scope),
          renderer: QuizSelect,
        };
      case "codeGroupSelector":
        return {
          tester: ScopeTester(scope),
          renderer: CodeGroupSelector,
        };
      case "promoProductSelector":
        return {
          tester: ScopeTester(scope),
          renderer: PromoProductSelectorControl,
        };
      case "listDropdownFilter":
        return {
          tester: ScopeTester(scope),
          renderer: ListDropdownFilterControl,
        };
      case "emailDomainsComponent":
        return {
          tester: ScopeTester(scope),
          renderer: EmailDomainsComponent,
        };
      case "multiStepSegmentSelect":
        return {
          tester: ScopeTester(scope),
          renderer: MultiStepSegmentSelect,
        };
      case "campaignSelect":
        return {
          tester: ScopeTester(scope),
          renderer: CampaignSelect,
        };
      default:
        return { tester: ScopeTester(scope), renderer: TextInputControl };
    }
  });
};
