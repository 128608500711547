import { useContext } from "react";
import LoaderContext from "../context/LoaderContext";
import { SurveyContext } from "../context/SurveyContext";

export const useSurveyHandlerHook = () => {
  const { setSurveyState, handleSaveSurvey, refetch, handleFilter } =
    useContext(SurveyContext);
  const { setIsLoading } = useContext(LoaderContext);

  const handleSurvey = (data) => {
    setSurveyState((prev) => ({ ...prev, ...data }));
  };

  const handleSaveMySurvey = async () => {
    setIsLoading(true);
    await handleSaveSurvey();
    await refetchSurveyList();
    setIsLoading(false);
  };

  const refetchSurveyList = async () => {
    setIsLoading(true);
    await refetch();
    setIsLoading(false);
  };

  const handleFilterVariables = (data) => {
    handleFilter(data);
    setIsLoading(false);
  };

  return {
    handleSurvey,
    handleSaveMySurvey,
    refetchSurveyList,
    handleFilterVariables,
  };
};
