import { withJsonFormsControlProps } from "@jsonforms/react";
import DatePickerMaterialUIField from "./DatePickerMaterialUIField";

const DatePickerMaterialUIControl = (props) => {
  const {
    handleChange,
    label,
    data,
    path,
    errors,
    schema,
    uischema,
    required,
    visible,
    enabled
  } = props;
  return (
    <DatePickerMaterialUIField
      value={data}
      label={label}
      updateValue={(newVal) => handleChange(path, newVal)}
      errors={errors}
      schema={schema}
      uiSchema={uischema}
      required={required}
      visible={visible}
      disabled={!enabled}
    />
  );
};

export default withJsonFormsControlProps(DatePickerMaterialUIControl);
