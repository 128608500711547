import { useQuery } from "@apollo/client";
import { JsonFormsDispatch, withJsonFormsArrayControlProps } from "@jsonforms/react";
import { listSegments } from "../../../graphql/queries/listSegments";
import { useCallback, useEffect, useState } from "react";
import { ButtonGroup, Modal } from "pepsico-ds";
import SegmentSelect from "../segmentSelect/SegmentSelect";
import "./multistep-segment-select.scss";
import { composePaths, createAjv } from "@jsonforms/core";

const MultiStepSegmentSelect = (props) => {
  const {
    data,
    addItem,
    removeItems,
    handleChange,
    path,
    schema,
    uischema,
    renderers,
    visible,
  } = props;
  const [openModal, setOpenModal] = useState(false);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [savedSegments, setSavedSegments] = useState([]);
  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
  const [page, setPage] = useState(0);
  const { data: segmentsData } = useQuery(listSegments, {
    variables: { status: "ENABLED" },
  });
  const listSegmentsRes = segmentsData?.listSegments;
  const segments =
    listSegmentsRes?.filter(
      (segment) => !savedSegments?.some((item) => item.id === segment.id)
    ) || [];

  useEffect(() => {
    if (segmentsData) {
      const currentSelectedSegments =
        data?.map((item) => ({
          ...item,
          name: listSegmentsRes.find((segment) => segment.id === item.segments?.[0])
            ?.name,
          id: item.segments?.[0],
        })) || [];
      setSavedSegments(currentSelectedSegments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentsData]);

  const addItemCallback = useCallback(
    (path, value) => addItem(path, value),
    [addItem]
  );
  const removeItemsCallback = useCallback(
    (path, toDelete) => removeItems(path, toDelete),
    [removeItems]
  );

  const handleToggleModal = () => {
    setSelectedSegments([]);
    setOpenModal(!openModal);
  };
  const handleSegmentSelection = (selected) => {
    setSelectedSegments(selected);
  };

  const handleRemoveSaved = (id) => {
    const updatedSavedSegments = savedSegments.filter(
      (segment) => segment.id !== id
    );
    setSavedSegments(updatedSavedSegments);
    handleChange(
      path,
      updatedSavedSegments.map((segment) => ({
        segments: segment.segments?.[0],
        contentData: segment.contentData,
      }))
    );
  };

  const handleStepForward = () => {
    if (page === 0) {
      if (data?.length - savedSegments.length > 0) {
        removeItemsCallback(path, [data.length - 1])();
      }
      const toAddSegments = selectedSegments.filter(
        (segment) => !data?.some((item) => item.segments?.[0] === segment.id)
      );
      toAddSegments.forEach((segment) =>
        addItemCallback(path, { segments: [segment.id] })()
      );
      setPage(page + 1);
      setCurrentSegmentIndex(savedSegments.length);
      return;
    }
    const newSavedSegments =
      data?.map((item) => ({
        ...item,
        name: listSegmentsRes.find((segment) => segment.id === item.segments?.[0])
          ?.name,
        id: item.segments?.[0],
      })) || [];
    setSavedSegments(newSavedSegments);
    handleToggleModal();
    setPage(0);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setPage(0);
    handleChange(
      path,
      savedSegments.map((segment) => ({
        segments: [segment.id],
        contentData: segment.contentData,
      }))
    );
    setSelectedSegments([]);
  };

  const isLastPage = page === 1;
  const currentPageSegmentName = listSegmentsRes?.find(
    (segment) => segment.id === data?.[currentSegmentIndex]?.segments?.[0]
  )?.name;
  const childUiSchema = uischema.options?.detail;
  const childPath = composePaths(path, `${currentSegmentIndex}`);

  const ajv = createAjv();
  const isDataValid = ajv.validate(schema, data?.[currentSegmentIndex] || {});

  return (
    visible && (
      <div className="multistep-segment-select">
        {savedSegments.map((segment, index) => (
          <div
            className={`segment-item display-flex flex-direction-row font-xs font-bold segment-added ${
              segment.status === "DISABLED" ? "segment-disabled" : ""
            }`}
            key={segment.id}
          >
            <div className="added-segment display-flex flex-direction-row align-items-center justify-content-space-between">
              <div className="segment-content display-flex flex-direction-row align-items-center">
                {segment.contentData?.homepage_img_url && (
                  <img
                    className="segment-homepage-image"
                    src={segment.contentData.homepage_img_url}
                    alt={`Homepage for ${segment.name}`}
                  ></img>
                )}
                <span>{segment.name}</span>
              </div>
              <ButtonGroup
                size="small"
                items={[
                  {
                    icon: "edit",
                    onClick: () => {
                      setCurrentSegmentIndex(index);
                      setPage(1);
                      handleToggleModal();
                    },
                  },
                  {
                    icon: "delete",
                    onClick: () => handleRemoveSaved(segment.id),
                  },
                ]}
              />
            </div>
          </div>
        ))}
        <div className="segment-item display-flex flex-direction-row font-xs font-bold">
          <ButtonGroup
            className="add-new"
            size="small"
            items={[
              {
                icon: "add",
                onClick: handleToggleModal,
              },
            ]}
          />
          Add New Segment
        </div>
        <Modal
          className={
            page > 0 ? "segment-select-steps-modal" : "campaign-segment-select-modal"
          }
          showModal={openModal}
          title={
            page === 0
              ? "Choose Segment"
              : `Upload Homepage for ${currentPageSegmentName}`
          }
          text=""
          showTertiaryButton={false}
          showLink={false}
          onCloseModal={handleCloseModal}
          showSecondaryButton={
            page > 0 && currentSegmentIndex === savedSegments.length
          }
          secondaryButtonProps={{
            text: isDataValid ? "Back to Segment" : "Previous",
            variant: "secondary",
            iconLeading: "arrow_back",
            onClick: () => setPage(page - 1),
          }}
          primaryButtonProps={{
            text: isLastPage ? "Save" : "Next",
            ...(!isLastPage && { iconTrailing: "arrow_forward" }),
            disabled:
              (page === 0 && !selectedSegments.length) ||
              (isLastPage && !isDataValid),
            onClick: handleStepForward,
          }}
        >
          {page === 0 ? (
            <SegmentSelect
              segmentsData={segments}
              selected={selectedSegments}
              onChange={handleSegmentSelection}
              selection="single"
            />
          ) : (
            <div className="segment-steps-content-wrapper">
              <span className="required-fields">*Required fields</span>
              <JsonFormsDispatch
                path={childPath}
                renderers={renderers}
                schema={schema}
                uischema={childUiSchema}
              />
            </div>
          )}
        </Modal>
      </div>
    )
  );
};

export default withJsonFormsArrayControlProps(MultiStepSegmentSelect);
