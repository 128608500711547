/* eslint-disable no-unused-vars */
import { TextInput } from "pepsico-ds";
import * as React from "react";
import { Icon, Tooltip } from "pepsico-ds";
import "./NumberInputField.scss";

export default function NumberInputField(props) {
  const {
    value,
    updateValue,
    label,
    errors,
    schema,
    uiSchema,
    required,
    disabled,
  } = props;

  return (
    <>
      <div className="textinput-wrapper">

        {uiSchema.tooltipText && (
          <div className="tooltip-wrapper">
            <span>{label}</span>
            <Tooltip
              direction={uiSchema.tooltipDirection ?? "right"}
              text={uiSchema.tooltipText}
            >
              <Icon
                alt=""
                className="$color-icon-default"
                icon={uiSchema.tooltipIcon ?? "info"}
                size="medium"
                variant="outlined"
              />
            </Tooltip>
          </div>
        )}
        <TextInput
          /* error={!!errors} */
          required={required}
          disabled={disabled}
          type="number"
          maxLength={uiSchema.maxLength ?? ""}
          label={!uiSchema.tooltipText ? label : ""}
          onUpdate={updateValue}
          value={parseInt(value)}
          placeholderText={uiSchema.placeholder ?? ""}
        />
      </div>
    </>
  );
}
