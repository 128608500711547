import Popover from "@mui/material/Popover";
import { Button } from "pepsico-ds";
import "./filterPopoverMaterialUI.scss";

const FilterPopoverMaterialUI = ({
  id,
  open,
  anchorEl,
  widthPopover,
  handleClose,
  cancelButtonText,
  confirmButtonText,
  handleCancelBtnAction,
  handlePrimaryBtnAction,
  children,
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div className="popover-material-ui" style={{ width: widthPopover }}>
        <div className="popover-material-ui-container">{children}</div>
        <div className="popover-material-ui-footer">
          <Button
            size="medium"
            text={cancelButtonText}
            variant="secondary"
            onClick={handleCancelBtnAction}
          />
          <Button
            iconTrailingVariant="outlined"
            iconTrailing="arrow_right_alt"
            size="medium"
            text={confirmButtonText}
            variant="primary"
            onClick={handlePrimaryBtnAction}
          />
        </div>
      </div>
    </Popover>
  );
};

export default FilterPopoverMaterialUI;
