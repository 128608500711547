import { withJsonFormsControlProps } from "@jsonforms/react";
import { Dropdown } from "pepsico-ds";

const StringSelect = ({
  handleChange,
  label,
  data,
  path,
  schema,
  uischema,
  required,
}) => {
  const labelMapping = uischema.options?.labelMapping;
  const childList =
    schema.enum?.map((item) => ({
      id: item,
      displayText: labelMapping?.[item] || item,
    })) || [];

  const formatData = (data) => {
    if (schema.type === "array") {
      return data?.map((item) => ({
        id: item,
        displayText: labelMapping?.[item] || item,
      }));
    }
    return data && [{ id: data, displayText: labelMapping?.[data] || data }];
  };

  const updateValue = (val) => {
    if (schema.type === "array") {
      handleChange(
        path,
        val?.map((item) => item.id)
      );
    } else {
      handleChange(path, val?.[0]?.id);
    }
  };

  return (
    <Dropdown
      isRequired={required}
      childList={childList}
      dropdownHeader={schema.header}
      style={{ minWidth: "0px" }}
      className="zIndexToDropDown"
      label={label}
      selection={uischema.selection || "single"}
      selectedValue={formatData(data)}
      setSelectedValue={updateValue}
      size={uischema.size || "medium"}
      placeholder={uischema.placeholder || ""}
    />
  );
};

export default withJsonFormsControlProps(StringSelect);
