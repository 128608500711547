import { gql } from "@apollo/client";

const filterCampaignsBySegment = gql`
  query ListCampaigns($filter: CampaignFilter!) {
    listCampaigns(filter: $filter) {
      id
      title
      type
    }
  }
`;

export default filterCampaignsBySegment;
