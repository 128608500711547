import { useQuery } from "@apollo/client";
import { Accordion, AccordionItem, Button, Card, Grid } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ViewComponentsRenderer from "../../../components/campaign/viewComponents/ViewComponentsRenderer";
import "../../../components/common/GridCard.scss";
import { CampaignSetupContext } from "../../../context/CampaignSetupContext";
import { getCampaignListing } from "../../../graphql/queries/getCampaignListing";
import { matchKeywordGroupsList } from "../../../graphql/queries/listKeywordGroups";
import { listRewardGroups } from "../../../graphql/queries/listRewardGroups";
import { listSegments } from "../../../graphql/queries/listSegments";
import { loadCampaignById } from "../../../graphql/queries/loadCampaignById";
import { paths } from "../../../routes/paths";
import { getDateWithFormat } from "../../../utils/commons";
import { toCamelCase } from "../../../utils/functions";
import LocalizationList from "./LocalizationList";
import SegmentsLinkedCampaigns from "./SummaryPage/SegmentsLinkedCampaigns";

export default function SummaryPage({ campaignId, isOnPage = true }) {
  const { campaignState, setCampaignState, currentCampaignTypeConfig } =
    useContext(CampaignSetupContext);
  const [staticCampaignState, setStaticCampaignState] = useState({});
  const [campaignRulesData, setCampaignRulesData] = useState([]);
  const [segmentedContentsData, setSegmentedContentsData] = useState([]);
  // FIXME: campaignActivityData never used
  const [, setCampaignActivityData] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [program] = useState(window.REACT_APP_PROGRAM || "kz");
  const navigate = useNavigate();
  const location = useLocation();
  const { data: allCampaignsData, loading: loadingCampaigns } = useQuery(
    getCampaignListing,
    {
      fetchPolicy: "cache-first",
    }
  );
  const { data: segmentsData } = useQuery(listSegments);
  // FIXME: loading: keywordGroupsLoading never used
  const { data: keywordGroupsList } = useQuery(matchKeywordGroupsList, {
    variables: { type: "BRAND" },
  });
  // FIXME: loading: campaignLoading never used
  const { data: campaignData } = useQuery(loadCampaignById, {
    variables: { id: campaignId },
  });
  const campaignStateData = isOnPage ? campaignState : staticCampaignState;

  useEffect(() => {
    if (campaignStateData?.segmentedContents && segmentsData) {
      const updatedSegmentedContents = campaignStateData.segmentedContents.map(
        (segmentedContent) => {
          const segmentId = segmentedContent?.id;
          const segment = segmentsData.listSegments.find(
            (seg) => seg.id === segmentId
          );
          return {
            ...segmentedContent,
            segmentName: segment?.name || "Unknown Segment",
          };
        }
      );
      setSegmentedContentsData(updatedSegmentedContents);
    }

    if (campaignData?.loadCampaignById) {
      isOnPage
        ? setCampaignState(campaignData.loadCampaignById)
        : setStaticCampaignState(campaignData.loadCampaignById);
    }
  }, [
    campaignData,
    setCampaignState,
    isOnPage,
    campaignStateData.segmentedContents,
    segmentsData,
    campaignState.segmentedContents,
  ]);

  useEffect(() => {
    if (
      keywordGroupsList?.listKeywordGroups?.length &&
      campaignStateData?.brands?.length
    ) {
      const initialBrands = campaignStateData.brands.map(
        (item) =>
          keywordGroupsList.listKeywordGroups.find((brand) => brand.id === item) ||
          {}
      );
      setSelectedBrands(initialBrands);
    }
  }, [keywordGroupsList?.listKeywordGroups, campaignStateData?.brands]);

  useEffect(() => {
    if (segmentsData?.listSegments && campaignStateData?.segments?.length) {
      const initialSegments = campaignStateData.segments.map(
        (item) =>
          segmentsData.listSegments.find((segment) => segment.id === item) || {}
      );
      setSelectedSegments(initialSegments);
    }
  }, [segmentsData, campaignStateData?.segments]);

  useEffect(() => {
    const campaignRulesArray =
      campaignStateData?.campaignRules?.map((ruleData) => ({
        [ruleData?.ruleDefinition?.name]: ruleData?.ruleData?.limit,
      })) || [];

    const campaignActivityArray =
      campaignStateData?.activities?.map((activity) => {
        const campaignActivityNameArray = [{ name: activity?.name }];
        activity?.activityRules.forEach((activityRule) => {
          campaignActivityNameArray.push(activityRule?.ruleData);
        });
        return campaignActivityNameArray;
      }) || [];

    setCampaignRulesData(campaignRulesArray);
    setCampaignActivityData(campaignActivityArray);
  }, [campaignStateData]);

  const renderTitleSwitch = (key) => {
    const titleMap = {
      campaign_image_url: "Home Page Image",
      detail_img_url: "Detail Image",
    };
    return titleMap[key] || toCamelCase(key);
  };

  const renderSwitch = (key, value) => {
    const renderMap = {
      campaign_image_url: (
        <img height={98} width={177} src={value} alt="home page img" />
      ),
      detail_img_url: <img height={98} width={177} src={value} alt="detail img" />,
    };
    return renderMap[key] || <span className="field-value">{value.toString()}</span>;
  };

  const isViewScreen =
    location.pathname.includes("campaign-summary-view") || !isOnPage;

  const isProgramPTR = window.REACT_APP_PROGRAM === "ptr";

  const getBasePath = () => {
    const path = location.pathname.split("/");
    return path.slice(0, path.length - 1).join("/");
  };

  const handleBasicEditSection = () => {
    navigate(`${getBasePath()}/generic-setup`);
  };

  const handleActivityEditSection = () => {
    const isNewApproach =
      currentCampaignTypeConfig.steps?.some(
        (step) => step.slug === `${paths.acitivity_level_setup_new}`
      ) || false;

    if (isNewApproach) {
      navigate(`${getBasePath()}/activity-level-setup-new`);
    } else {
      navigate(`${getBasePath()}/activity-level-setup`);
    }
  };

  const relatedCampaignsData =
    allCampaignsData?.listCampaigns?.filter((campaign) =>
      campaignStateData.relatedCampaigns?.includes(campaign.id)
    ) || [];

  return (
    <>
      <Card
        icon=""
        ellipsis=""
        style={{
          padding: "0px",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        }}
      >
        <div className="summery-card-container">
          {/* Basic start */}
          <div className="campaign-details">
            <div className="campaign-header">
              <div>
                <h3 className="campaign-name" style={{ fontSize: "14px" }}>
                  Basic
                </h3>
              </div>
              <div>
                {!isViewScreen && (
                  <Button
                    onClick={() => handleBasicEditSection()}
                    iconLeadingVariant="outlined"
                    iconTrailingVariant="outlined"
                    iconTrailing="edit"
                    size="small"
                    text="Edit Section"
                    variant="borderless"
                  />
                )}
              </div>
            </div>
            <div className="summary-card-details">
              <div className="additional-fields-container">
                <div className="field-pair">
                  <span className="field-label">Campaign Id</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">{campaignStateData.id}</span>
                </div>
              </div>
              {campaignStateData?.displayAfterEndDate && (
                <div className="additional-fields-container">
                  <div className="field-pair">
                    <span className="field-label">
                      {program === "ptr"
                        ? "When activity is completed, show campaign card as"
                        : "Show Campaign completion as..."}
                    </span>
                  </div>
                  <div className="field-pair-two-column">
                    <span className="field-value">
                      {toCamelCase(campaignStateData?.displayAfterEndDate)}
                    </span>
                  </div>
                </div>
              )}
              {campaignStateData?.title && (
                <div className="additional-fields-container">
                  <div className="field-pair">
                    <span className="field-label">Campaign Name</span>
                  </div>
                  <div className="field-pair-two-column">
                    <span className="field-value">{campaignStateData?.title}</span>
                  </div>
                </div>
              )}

              <div className="additional-fields-container">
                <div className="field-pair">
                  <span className="field-label">Campaign Active Date</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">
                    {campaignStateData?.activeStartDate &&
                      getDateWithFormat(
                        campaignStateData?.activeStartDate,
                        null,
                        "MM/DD/YYYY hh:mm a"
                      )}{" "}
                    {" - "}
                    {campaignStateData?.activeEndDate &&
                    campaignStateData?.activeEndDate !== null &&
                    campaignStateData?.activeEndDate !== ""
                      ? getDateWithFormat(
                          campaignStateData?.activeEndDate,
                          null,
                          "MM/DD/YYYY hh:mm a"
                        )
                      : "No end date"}
                  </span>
                </div>
              </div>
              <div className="additional-fields-container">
                <div className="field-pair">
                  <span className="field-label">Always Active</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">
                    {campaignStateData?.endless ? "Yes" : "No"}
                  </span>
                </div>
              </div>
              <div className="additional-fields-container">
                <div className="field-pair">
                  <span className="field-label">Campaign Display Date</span>
                </div>
                <div className="field-pair-two-column">
                  <span className="field-value">
                    {(campaignStateData?.displayStartDate ||
                      campaignStateData?.activeStartDate) &&
                      getDateWithFormat(
                        campaignStateData?.displayStartDate ||
                          campaignStateData?.activeStartDate,
                        null,
                        "MM/DD/YYYY hh:mm a"
                      )}
                    {" - "}
                    {campaignStateData?.displayEndDate ||
                    campaignStateData?.activeEndDate
                      ? getDateWithFormat(
                          campaignStateData?.displayEndDate ||
                            campaignStateData?.activeEndDate,
                          null,
                          "MM/DD/YYYY hh:mm a"
                        )
                      : "No end date"}
                  </span>
                </div>
              </div>

              {selectedBrands && (
                <div className="additional-fields-container">
                  <div className="field-pair">
                    <span className="field-label">Brand</span>
                  </div>
                  <div className="field-pair-two-column">
                    <span className="field-value">
                      {selectedBrands?.map((brand) => brand.name).join(", ")}
                      {/* <MatchKeywordsViewComponent data={campaignState?.brands} inputSchema={campaignState?.activities?.activityDefinition} filterBy="BRAND" /> */}
                    </span>
                  </div>
                </div>
              )}

              <div className="additional-fields-container">
                <div className="field-pair">
                  <span className="field-label">Tags</span>
                </div>
                <div className="field-pair-two-column">
                  {campaignStateData?.tags && (
                    <span className="field-value">
                      {campaignStateData?.tags?.map((tag) => tag).join(", ")}
                    </span>
                  )}
                </div>
              </div>
              {campaignStateData?.type !== "sweepstakes" && (
                <div className="additional-fields-container">
                  <div className="field-pair">
                    <span className="field-label">
                      {program === "ptr"
                        ? "Show number of entries in campaign card"
                        : "Show in Campaign card"}
                    </span>
                  </div>
                  <div className="field-pair-two-column">
                    <span className="field-value">
                      {campaignStateData?.hasShowEntriesCount ? "On" : "Off"}
                    </span>
                  </div>
                </div>
              )}
              {campaignStateData?.type !== "sweepstakes" && (
                <div className="additional-fields-container">
                  <div className="field-pair">
                    <span className="field-label">
                      Show in Member Campaign History
                    </span>
                  </div>
                  <div className="field-pair-two-column">
                    <span className="field-value">
                      {campaignStateData?.hasShowInHistory ? "On" : "Off"}
                    </span>
                  </div>
                </div>
              )}

              {campaignStateData?.campaignContent?.contentData &&
                Object.entries(campaignStateData?.campaignContent?.contentData).map(
                  ([key, index]) => (
                    <>
                      {campaignStateData?.campaignContent?.contentData?.l10n &&
                      key === "l10n" ? (
                        <LocalizationList
                          data={campaignStateData?.campaignContent?.contentData}
                        />
                      ) : (
                        <div className="additional-fields-container">
                          <div className="field-pair">
                            <span className="field-label">
                              {
                                // key?.replace(/_/g, ' ')
                                // toCamelCase(key)
                                renderTitleSwitch(key)
                                // key
                              }
                            </span>
                          </div>
                          <div className="field-pair-two-column">
                            {renderSwitch(key, index)}
                            {/* <span className="field-value">{index}</span> */}
                          </div>
                        </div>
                      )}
                    </>
                  )
                )}
              {/* <hr></hr> */}

              {selectedSegments && (
                <div className="additional-fields-container">
                  <div className="field-pair">
                    <span className="field-label">Segments</span>
                  </div>
                  <div className="field-pair-two-column">
                    <span className="field-value">
                      {selectedSegments?.map((segment) => segment.name).join(", ")}
                    </span>
                  </div>
                </div>
              )}
              {campaignStateData?.category && (
                <div className="additional-fields-container">
                  <div className="field-pair">
                    <span className="field-label">Campaign category</span>
                  </div>
                  <div className="field-pair-two-column">
                    <span className="field-value">
                      {campaignStateData?.category}
                    </span>
                  </div>
                </div>
              )}
              {campaignStateData?.token && (
                <div className="additional-fields-container">
                  <div className="field-pair">
                    <span className="field-label">Campaign Token Id</span>
                  </div>
                  <div className="field-pair-two-column">
                    <span className="field-value">{campaignStateData?.token}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* Basic end */}
        </div>
      </Card>

      {/* Segments/Linked Campaigns Section */}
      {(segmentedContentsData.length > 0 || relatedCampaignsData.length > 0) && (
        <SegmentsLinkedCampaigns
          segmentedData={segmentedContentsData}
          relatedData={relatedCampaignsData}
          isViewScreen={isViewScreen}
          editSectionHandler={handleBasicEditSection}
        />
      )}

      <Card
        icon=""
        ellipsis=""
        style={{
          padding: "0px",
          borderRadius: "10px",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        }}
      >
        <div className="summery-card-container">
          <div className="campaign-details">
            <div className="campaign-header">
              <div>
                <h3 className="campaign-name" style={{ fontSize: "14px" }}>
                  Activity Setup
                </h3>
              </div>
              <div>
                {!isViewScreen && (
                  <Button
                    onClick={() => handleActivityEditSection()}
                    iconLeadingVariant="outlined"
                    iconTrailingVariant="outlined"
                    iconTrailing="edit"
                    size="small"
                    text="Edit Section"
                    variant="borderless"
                  />
                )}
              </div>
            </div>
            <div className="summary-card-details">
              {campaignStateData?.activities?.[0]?.activityRules?.length !== 0 ? (
                <Grid container>
                  <h3 className="campaign-name">Rules</h3>
                  {campaignStateData?.activities?.[0]?.activityRules.map(
                    (rule, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={6}
                        xl={4}
                        mt={1}
                        mb={1}
                        className="display-flex gap-3 two-column"
                      >
                        <ViewComponentsRenderer
                          customComponentName={rule.ruleDefinition?.slug}
                          data={rule.ruleData}
                          inputSchema={rule.ruleDefinition?.inputSchema}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              ) : null}

              <h3 className="campaign-name">Outcomes</h3>
              {campaignStateData?.activities?.[0]?.outcomes
                .reduce(
                  (acc, outcome) => [
                    ...acc,
                    ...outcome.outcomeRules,
                    {
                      ruleData: outcome.outcomeData || {},
                      ruleDefinition: outcome.outcomeDefinition || {},
                    },
                  ],
                  []
                )
                .filter((rule) => rule?.ruleData?.target_id !== null)
                .map((rule, index) => (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    md={6}
                    xl={4}
                    mt={1}
                    mb={1}
                    className="display-flex gap-3 two-column"
                  >
                    {!!Object.keys(rule.ruleData).length && (
                      <ViewComponentsRenderer
                        customComponentName={rule.ruleDefinition?.slug}
                        data={rule.ruleData}
                        inputSchema={rule.ruleDefinition?.inputSchema}
                      />
                    )}
                  </Grid>
                ))}

              {/* {campaignActivityData &&
              campaignActivityData.map((activity, index) => (
                <div
                  className="campaign-details"
                  style={{ backgroundColor: "#EFF5FC" }}
                >
                  {activity.map((activityRule, index) => (
                    <>
                      <h3
                        style={{ fontSize: "14px", fontWeight: 700 }}
                        className="campaign-name"
                      >
                        {activityRule?.name}
                      </h3>
                      {activityRule?.matches && (
                        <div className="additional-fields-container">
                          <div className="field-pair">
                            <span className="field-label">
                              Fixed Campaign Code
                            </span>
                          </div>
                          <div className="field-pair-two-column">
                            <span className="field-value">
                              {activityRule?.matches}
                            </span>
                          </div>
                        </div>
                      )}

                      {activityRule?.day_parts && (
                        <DayPartingViewComponent data={activityRule} />
                      )}
                    </>
                  ))}

                  {activity?.activityRules &&
                    activity?.activityRules.map((rule, index) => (
                      <div className="additional-fields-container">
                        <div className="field-pair">
                          <span className="field-label">
                            {Object.keys(rule?.[index])}
                          </span>
                        </div>
                        <div className="field-pair-two-column">
                          <span className="field-value">
                            {rule?.ruleData[index]}
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              ))} */}
            </div>
          </div>
        </div>
      </Card>
      {campaignStateData?.outcomes?.length > 0 && (
        <Card
          icon=""
          ellipsis=""
          style={{
            padding: "0px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
          }}
        >
          <div className="summery-card-container">
            <div className="campaign-details">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 className="campaign-name" style={{ fontSize: "14px" }}>
                  Campaign Setup - Outcomes
                </h3>
              </div>
              <div style={{ backgroundColor: "#EFF5FC" }}>
                <Accordion
                  alignment="right"
                  onUpdate={function Qa() {}}
                  size="xlarge"
                  state="enable"
                >
                  {campaignStateData?.outcomes.length > 0 &&
                    campaignStateData?.outcomes.map((outcome, index) => (
                      <CampaignOutcomes
                        outcome={outcome}
                        key={outcome?.outcomeData?.reward_group_id || index}
                        campaignState={campaignStateData}
                      />
                    ))}
                </Accordion>
              </div>
            </div>
          </div>
        </Card>
      )}

      {campaignRulesData.length > 0 && (
        <Card
          icon=""
          ellipsis=""
          style={{
            padding: "0px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
          }}
        >
          {!isProgramPTR && (
            <div className="summery-card-container">
              <div className="campaign-details">
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h3 className="campaign-name" style={{ fontSize: "14px" }}>
                    Campaign Setup - Rules
                  </h3>
                  {/* <span style={{ fontSize: "12px" }}>Edit section</span> */}
                </div>
                <div
                  className="campaign-details"
                  style={{ backgroundColor: "#EFF5FC" }}
                >
                  {campaignRulesData?.map((rule, index) => (
                    <div className="additional-fields-container" key={index}>
                      <div className="field-pair">
                        <span className="field-label"> {Object.keys(rule)[0]}</span>
                      </div>
                      <div className="field-pair-two-column">
                        <span className="field-value">{Object.values(rule)[0]}</span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </Card>
      )}
    </>
  );
}

const CampaignOutcomes = ({ outcome }) => {
  // FIXME: loading, error never used
  const { data } = useQuery(listRewardGroups, {
    variables: {
      rewardGroupId: outcome?.outcomeData?.reward_group_id,
    },
    skip: !outcome?.outcomeData?.reward_group_id,
  });
  const rewardGroupData = data?.listRewardGroups?.[0];
  return (
    <AccordionItem
      alignment="right"
      label={toCamelCase(rewardGroupData?.name)}
      style={{
        backgroundColor: "#EFF5FC",
        width: "100%",
      }}
    >
      <div style={{ width: "100%" }}>
        <div className="additional-fields-container">
          <div className="field-pair">
            <span className="field-label">Reward group name</span>
          </div>
          <div className="field-pair-two-column">
            <span className="field-value">{rewardGroupData?.name}</span>
          </div>
        </div>
        <div className="additional-fields-container">
          <div className="field-pair">
            <span className="field-label">Reward group description</span>
          </div>
          <div className="field-pair-two-column">
            <span className="field-value">{rewardGroupData?.description}</span>
          </div>
        </div>

        {rewardGroupData?.image !== "no_image" && (
          <div className="additional-fields-container">
            <div className="field-pair">
              <span className="field-label">Reward group image</span>
            </div>
            <div className="field-pair-two-column">
              <span className="field-value">
                <img
                  height={89}
                  width={113}
                  src={rewardGroupData?.image}
                  alt={"title"}
                />
              </span>
            </div>
          </div>
        )}

        <div className="additional-fields-container">
          <div className="field-pair">
            <span className="field-label">Max winners </span>
          </div>
          <div className="field-pair-two-column">
            <span className="field-value">
              {rewardGroupData?.maxParticipationPerInterval}{" "}
            </span>
          </div>
        </div>

        <hr style={{ marginBottom: "10px" }} color="#DFDFDF" />

        <div className="additional-fields-container">
          <div className="field-pair">
            <span className="field-label">Reward group Rules</span>
          </div>
          <div className="field-pair-two-column">
            <span className="field-value"> </span>
          </div>
        </div>

        <div className="additional-fields-container">
          <div className="field-pair">
            <span className="field-label">Position on gift selection page</span>
          </div>
          <div className="field-pair-two-column">
            <span className="field-value">{rewardGroupData?.position}</span>
          </div>
        </div>

        <div className="additional-fields-container">
          <div className="field-pair">
            <span className="field-value">Max winners </span>
          </div>
          <div className="field-pair-two-column">
            <span className="field-value">
              {rewardGroupData?.numberOfWinnersLimit}
            </span>
          </div>
        </div>

        <hr style={{ marginBottom: "10px" }} color="#DFDFDF" />
        <div style={{ width: "100%", paddingTop: "10px" }}>
          <div className="additional-fields-container">
            <div className="field-pair">
              <span className="field-label">Rewards</span>
            </div>
          </div>
        </div>
        {rewardGroupData?.rewardGroupRewards?.map((reward, index) => (
          <div className="additional-fields-container" key={`k-${index}`}>
            <div className="field-pair-4-col">
              <span className="field-value">Reward name 1 </span>
            </div>
            <div className="field-pair-4-col">
              <span className="field-value">
                Chance to win: {reward?.chanceToWinPercentage}%
              </span>
            </div>
            <div className="field-pair-4-col">
              <span className="field-value">Qty: {reward?.quantity} </span>
            </div>
            <div className="field-pair-4-col">
              <span className="field-value">
                Win limit: {reward?.totalUserWinLimit}
              </span>
            </div>
            <div className="field-pair-4-col">
              <span className="field-value">
                Wallet Withdrawal Date:
                {getDateWithFormat(
                  reward?.walletWithdrawalDate,
                  null,
                  "MM/DD/YYYY hh:mm a"
                )}
              </span>
            </div>
            <div className="field-pair-4-col">
              <span className="field-value">
                Active Date:
                {getDateWithFormat(reward?.startDate, null, "MM/DD/YYYY hh:mm a")} -
                {getDateWithFormat(reward?.endDate, null, "MM/DD/YYYY hh:mm a")}
              </span>
            </div>
          </div>
        ))}
      </div>
    </AccordionItem>
  );
};
