import { Button, Card } from "pepsico-ds";

const SummaryCard = (props) => {
  const { title, children, isViewScreen, handleEditSection } = props;
  return (
    <Card
      icon=""
      ellipsis=""
      style={{
        padding: "0px",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
      }}
    >
      <div className="summery-card-container campaign-summary-page">
        <div className="campaign-details">
          <div className="campaign-header">
            <div>
              <h3 className="campaign-name fs-14">{title}</h3>
            </div>
          </div>
          <div className="summary-card-details">{children}</div>
        </div>
      </div>
    </Card>
  );
};
export default SummaryCard;
