import { Outlet } from "react-router-dom";
import { ListSweepstakesProvider } from "../../context/ListSweepstakesContext";

export default function SweepstakesOutlet() {
  return (
    <ListSweepstakesProvider>
      <Outlet />
    </ListSweepstakesProvider>
  );
}
