import { TextArea } from "pepsico-ds";

const CustomRichTextEditor = (props) => {
  const { value, updateValue, label, required, disabled, placeholder } = props;

  return (
    <div className="display-flex my-1 flex-direction-column gap-2">
      <TextArea
        style={{ maxHeight: "200px", overflowY: "auto" }}
        label={label}
        required={required}
        placeholder={placeholder}
        value={value}
        onUpdate={updateValue}
        disabled={disabled}
        showToolbar={true}
      />
    </div>
  );
};

export default CustomRichTextEditor;
