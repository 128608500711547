import { Icon, SideBar } from "pepsico-ds";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalConfigContext } from "../../context/GlobalConfigContext";
import { useHasAccessPermission } from "../../hooks/useHasAccessPermission";
import "./sidebar.scss";

import kzConfig from "../../config/config-kz.json";
import ptrConfig from "../../config/config-ptr.json";

const CustomSideBar = () => {
  const {
    globalState: { isPanelOpen },
    updateSidebarPanelState,
  } = useContext(GlobalConfigContext);

  const [navigationConfig, setNavigationConfig] = useState({});
  const hasAccess = useHasAccessPermission();

  useEffect(() => {
    if (window != undefined && window.REACT_APP_PROGRAM === "ptr") {
      setNavigationConfig(ptrConfig.navigation);
    } else {
      setNavigationConfig(kzConfig.navigation);
    }
  }, []);

  // const navigationConfigDefault = {
  //   initial: {
  //     hideFromNav: true,
  //     roles: ["all"],
  //     icon: "home",
  //     title: "initial",
  //     navigateTo: "/",
  //     children: [],
  //   },
  //   // activities_bonus: {
  //   //   title: "Activities/Bonus",
  //   //   icon: "campaign",
  //   //   navigateTo: "",
  //   //   roles: ["all"],
  //   //   children: [
  //   //     {
  //   //       title: "Activities",
  //   //       icon: "",
  //   //       navigateTo: "",
  //   //       children: [],
  //   //     },
  //   //   ],
  //   // },
  //   dashboard: {
  //     title: "Dashboard",
  //     icon: "dashboard",
  //     roles: ["customer-service", "third-party"],
  //     navigateTo: "/",
  //     children: [],
  //   },
  //   campaign_list: {
  //     title: "Campaign Studio",
  //     icon: "campaign",
  //     navigateTo: "",
  //     roles: ["customer-service", "third-party"],
  //     children: [
  //       // {
  //       //   title: "Campaign Calendar",
  //       //   icon: "",
  //       //   navigateTo: "/campaigns/campaign-calendar",
  //       //   children: [],
  //       // },
  //       {
  //         title: "Campaigns",
  //         icon: "",
  //         navigateTo: "/campaigns/campaign-list",
  //         children: [],
  //       },
  //     ],
  //   },
  //   sweepstakes: {
  //     title: "Sweepstakes",
  //     icon: "flag",
  //     navigateTo: "/sweepstakes",
  //     roles: ["all"],
  //     children: [
  //       {
  //         title: "Winners Management",
  //         icon: "",
  //         navigateTo: "/sweepstakes/winners-management",
  //       },
  //       {
  //         title: "Archived Sweepstakes",
  //         icon: "",
  //         navigateTo: "/sweepstakes/archiving",
  //       },
  //     ],
  //   },
  //   // content_list: {
  //   //   title: "Content Studio",
  //   //   icon: "subscriptions",
  //   //   navigateTo: "",
  //   //   roles: ["customer-service", "third-party"],
  //   //   children: [
  //   //     {
  //   //       title: "Banners",
  //   //       icon: "",
  //   //       navigateTo: "/content/banners",
  //   //       children: [],
  //   //     },
  //   //     {
  //   //       title: "Surveys & Quizzes",
  //   //       icon: "",
  //   //       navigateTo: "/content/survey-list",
  //   //       children: [],
  //   //     },
  //   //   ],
  //   // },
  //   organizations: {
  //     title: "Organization",
  //     icon: "group",
  //     navigateTo: "",
  //     roles: ["customer-service", "third-party"],
  //     children: [
  //       {
  //         title: "Partners/Partners code group",
  //         icon: "",
  //         navigateTo: "/partners",
  //         children: [],
  //       },
  //     ],
  //   },
  //   rewards_product_keywords: {
  //     title: "Rewards/Products",
  //     icon: "redeem",
  //     navigateTo: "",
  //     roles: ["customer-service", "third-party"],
  //     children: [
  //       {
  //         title: "Rewards",
  //         icon: "",
  //         navigateTo: "/rewards",
  //         children: [],
  //       },
  //     ],
  //   },
  //   widgets: {
  //     title: "Widgets",
  //     icon: "redeem",
  //     navigateTo: "/widgets",
  //     roles: ["all"],
  //     children: [
  //       {
  //         title: "Widget Management",
  //         icon: "",
  //         navigateTo: "/widgets/widget-list",
  //       },
  //     ],
  //   },

  //   member: {
  //     title: "Member Management",
  //     icon: "emoji_emotions",
  //     navigateTo: "/member-list",
  //     roles: ["all"],
  //     children: [
  //       {
  //         title: "Member List",
  //         icon: "",
  //         navigateTo: "/member-list",
  //       },
  //     ],
  //   },
  //   management: {
  //     title: "Management",
  //     icon: "emoji_emotions",
  //     navigateTo: "/management-list",
  //     roles: ["all"],
  //     children: [
  //       {
  //         title: "Management List",
  //         icon: "",
  //         navigateTo: "/management-list",
  //       },
  //     ],
  //   },
  // };

  // const renderSubNav = () => {
  //   if (typeof navigationConfig !== "object" || navigationConfig === null) {
  //     // navigationConfig is not a valid object, return null or some fallback UI
  //     return <span>Invalid Navigation Config</span>;
  //   }

  //   return Object.keys(navigationConfig).map((navItem, index) => {
  //     const navConfigItem = navigationConfig[navItem];
  //     if (!navConfigItem || !navConfigItem.hideFromNav) {
  //       const title = navConfigItem.title || "";
  //       const icon = navConfigItem.icon || "";
  //       const children = navConfigItem.children || [];
  //       const navigateTo = navConfigItem.navigateTo || "";
  //       return (
  //         <SidebarItem
  //           key={index}
  //           icon={icon}
  //           index={index}
  //           label={
  //             <Link
  //               className={isPanelOpen ? "py-3 pl-2 pr-4" : ""}
  //               to={navigateTo === "" ? window.location.pathname : navigateTo}
  //               style={{
  //                 height: "100%",
  //                 textDecoration: "none",
  //                 width: "100%",
  //               }}>
  //               {title}
  //             </Link>
  //           }
  //           className={isPanelOpen ? "" : "py-3"}
  //           style={{ width: "100%" }}>
  //           {children.length > 0 && (
  //             <Sidebar
  //               selectedItemIndex={
  //                 navigationSelected.navIndex === index
  //                   ? navigationSelected.subNavIndex
  //                   : -1
  //               }
  //               onUpdate={(newSubNavIndex) =>
  //                 updateNavigationState({
  //                   subNavIndex: newSubNavIndex,
  //                 })
  //               }
  //               style={{ gap: 0 }}>
  //               {children.map((child, subNavIndex) => {
  //                 if (!child.hideFromNav) {
  //                   const childTitle = child.title || "";
  //                   const childNavigateTo = child.navigateTo || "";
  //                   return (
  //                     <SidebarItem
  //                       key={subNavIndex}
  //                       index={subNavIndex}
  //                       label={
  //                         <Link
  //                           to={childNavigateTo}
  //                           className={isPanelOpen ? "py-3 pr-4 pl-7" : ""}
  //                           style={{
  //                             height: "100%",
  //                             textDecoration: "none",
  //                             width: "100%",
  //                           }}>
  //                           {childTitle}
  //                         </Link>
  //                       }
  //                     />
  //                   );
  //                 } else return <React.Fragment key={12 * subNavIndex} />;
  //               })}
  //             </Sidebar>
  //           )}
  //         </SidebarItem>
  //       );
  //     } else return <React.Fragment key={13 * index} />;
  //   });
  // };

  return (
    <div
      className="display-flex flex-direction-column mr-1 hideScrollBar"
      style={{
        boxShadow: "4px 0px 24px -2px rgba(0, 92, 188, 0.08)",
        maxWidth: "300px",
        overflowY: "auto",
      }}
    >
      {!isPanelOpen && (
        <Icon
          style={{ cursor: "pointer" }}
          onClick={() => updateSidebarPanelState(true)}
          alt="menu"
          className="$spacing-pl05 $spacing-pr04 $spacing-py03"
          icon="menu"
        />
      )}
      {/* <Sidebar
        onUpdate={(newNavIndex) => {
          updateNavigationState({
            navIndex: newNavIndex,
          });
        }}
        showClose={isPanelOpen ? true : false}
        onClose={updateSidebarPanelState}
        selectedItemIndex={navigationSelected.navIndex}
        showOnlyIcons={isPanelOpen ? false : true}
        style={{ height: "100%" }}
        size="large">
        {renderSubNav()}
      </Sidebar> */}
      <SideBar
        className={`sidebarStyles ${isPanelOpen ? "sidebarStyles-open" : "sidebarStyles-close"}`}
        items={Object.entries(navigationConfig)
          .filter(([, value]) => hasAccess(value?.roles) && !value.hideFromNav)
          .map(([, value]) => {
            return {
              label: value.title,
              icon: value.icon,
              url: value.navigateTo,
              sublinks: value.children?.map((child) => {
                return {
                  label: (
                    <Link
                      to={child.navigateTo}
                      style={{
                        height: "100%",
                        textDecoration: "none",
                        width: "100%",
                      }}
                    >
                      {child.title}
                    </Link>
                  ),
                };
              }),
            };
          })}
        // navLinkAction={function Qa() {}}
      />
    </div>
  );
};
export default CustomSideBar;
