import { Filter } from "pepsico-ds";
import "./customFilter.scss";

export const CustomFilter = (props) => {
  return (
    <div className="custom-filter-menu">
      <Filter {...props} />
    </div>
  );
};
