import { Radio } from "pepsico-ds";

export default function RadioField(props) {
  const { value, updateValue, label, schema, disabled } = props;

  return (
    <>
      <Radio
        style={{ display: "flex" }}
        items={schema.options || []}
        label={label}
        onUpdate={(selectedIndex) => updateValue(schema?.options?.[selectedIndex])}
        // selected={value !== undefined ? true : false}
        selectedItemIndex={schema.options?.findIndex((option) => option === value)}
        reference={`radio button ${label}`}
        size="small"
        disabled={disabled}
      />
    </>
  );
}
