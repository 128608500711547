export const searchHandler = (searchText, searchKeys, data) => {
  if (searchText === "") return data;
  return data.filter((item) => {
    return searchKeys.some((key) => {
      return item[key]
        .toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  });
};

export const toCamelCase = (str = "") => {
  const regExp = str?.replace(/_/g, " ");
  const strRegExp = regExp
    .split(" ")
    .map((word, index) =>
      index === 0
        ? word.toLowerCase()
        : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join(" ");
  return strRegExp.charAt(0).toUpperCase() + strRegExp.slice(1);
};

export const isValidStringWithoutSpecialChar = (inputString) => {
  return /^[\p{L}\p{N}_\s-]+$/u.test(inputString);
};

export const exportData = ({ data, fileName, fileType }) => {
  console.log(data, fileName, fileType)
  const blob = new Blob([data], { type: fileType });
  const a = document.createElement('a');
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};